
import { createSelector } from "@reduxjs/toolkit";

import { UCCLiensState } from "../../../../utils/types";
import { RootState } from "../../../store";
import { UCC_LIENS_KEY } from "../UCCLiens.slice";

// Get loan details state
export const getUCCLiensState = (rootState: RootState): UCCLiensState =>
  rootState[UCC_LIENS_KEY];

  export const selectUCCLiens = createSelector(
    getUCCLiensState,
    (uccLiensState) => {
      if (uccLiensState?.data) {
       return uccLiensState.data
      }
    }
  );