import { createSlice } from "@reduxjs/toolkit";

import { getPaymentModes } from "./actions/PaymentModes.thunk";
import * as reducers from "./reducers/PaymentModes.reducers";
import { PaymentModesState } from "../../../utils/types";

const initialState: PaymentModesState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PAYMENT_MODES_KEY = "paymentModes";

const paymentModesSlice = createSlice({
  name: PAYMENT_MODES_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentModes.pending, reducers.pending)
      .addCase(getPaymentModes.fulfilled, reducers.fulfilled)
      .addCase(getPaymentModes.rejected, reducers.rejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const paymentModesReducer = paymentModesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const paymentModesSliceActions = paymentModesSlice.actions;
