import { createSlice } from "@reduxjs/toolkit";

import { patchPromoDetails } from "./actions/promoDetails.thunk";
import * as reducers from "./reducers/promoDetails.reducers";
import { PromoDetailsState } from "../../../utils/types";

export const initialState: PromoDetailsState = {
  patchPromoDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
};

export const PROMO_DETAILS_KEY = "promoDetails";

export const promoDetailsSlice = createSlice({
  name: PROMO_DETAILS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(patchPromoDetails.pending, reducers.patchPromoDetailsPending)
      .addCase(patchPromoDetails.fulfilled, reducers.patchPromoDetailsFulfilled)
      .addCase(patchPromoDetails.rejected, reducers.patchPromoDetailsRejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const promoDetailsReducer = promoDetailsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const promoDetailsSliceActions = promoDetailsSlice.actions;
