// create loan communications Activity selector
import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

import { PLACE_HOLDER } from "../../../../components/shared/constants";
import { CommunicationActivityState } from "../../../../utils/types";
import { RootState } from "../../../store";
import { COMMUNICATIONS_ACTIVITY_KEY } from "../communicationsActivity.slice";

// Get communications Activity state
export const getCommunicationsActivityState = (
  rootState: RootState
): CommunicationActivityState => rootState[COMMUNICATIONS_ACTIVITY_KEY];

// select set note by id default form data
export const selectSetNoteByIdDefaultFormData = createSelector(
  getCommunicationsActivityState,
  (communicationsActivityState) => {
    const defaultFormData =
      communicationsActivityState?.getNoteDetailsById?.data?.response;
    if (defaultFormData) {
      return {
        id: defaultFormData.id,
        title: defaultFormData.title ?? PLACE_HOLDER,
        smsDate: defaultFormData?.smsDate
          ? moment(defaultFormData.smsDate).toDate()
          : null,
        interactionCode: defaultFormData?.interactionCode
          ? {
              label: defaultFormData.interactionCode,
              value: defaultFormData.interactionCode,
            }
          : null,
        adminCureReason: defaultFormData?.adminCureReason
          ? {
              label: defaultFormData.adminCureReason,
              value: defaultFormData.adminCureReason,
            }
          : null,
        noteContent: defaultFormData.noteContent ?? PLACE_HOLDER,
      };
    }
  }
);
