import {
  PatchPromiseToPayResponse,
  PostPromiseToPayResponse,
  PromiseToPayResponse,
  ReqBodyPromiseToPay,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetPromiseToPay = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse<PromiseToPayResponse[]>> => {
  try {
    const { data, status } = await baseUrl.get<PromiseToPayResponse[]>(
      `loans/${loanID}/promises`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiPostPromiseToPay = async ({
  loanID,
  reqBody,
}: {
  loanID: string;
  reqBody: ReqBodyPromiseToPay;
}): Promise<ApiResponse<PostPromiseToPayResponse>> => {
  try {
    const { data, status } = await baseUrl.post<PostPromiseToPayResponse>(
      `loans/${loanID}/promises`,
      reqBody
    );
    return { response: data, status, success: status === 201 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiDeletePromiseToPay = async ({
  loanID,
  promiseID,
}: {
  loanID: string;
  promiseID: string;
}): Promise<ApiResponse> => {
  try {
    const { data, status } = await baseUrl.delete(
      `loans/${loanID}/promises/${promiseID}`
    );
    return { response: data, status, success: status === 200 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiPatchPromiseToPay = async ({
  loanID,
  promiseID,
  reqBody,
}: {
  loanID: string;
  promiseID: string;
  reqBody: ReqBodyPromiseToPay;
}): Promise<ApiResponse<PatchPromiseToPayResponse>> => {
  try {
    const { data, status } = await baseUrl.patch<PatchPromiseToPayResponse>(
      `loans/${loanID}/promises/${promiseID}`,
      reqBody
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiGetPromiseToPayByPromiseID = async ({
  loanID,
  promiseID,
}: {
  loanID: string;
  promiseID: string;
}): Promise<ApiResponse<PromiseToPayResponse>> => {
  try {
    const { data, status } = await baseUrl.get<PromiseToPayResponse>(
      `loans/${loanID}/promises/${promiseID}`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
