import { AxiosError } from "axios";

import { getErrorMessage } from "../components/shared/tools/commonFunctions";
import { LoadingStatus, AsyncState, GeneralState } from "../redux/tools";
import { Types } from "../services";
import { TransactionsPostingResponse } from "../services/modules/loan-adjustments-transactions/types";
import { ResponsePaymentDetails } from "../services/modules/payment-method-services/types";
import { PaymentSpread } from "../services/modules/payment-spreads/types";

export type ApiResponse<T = any | undefined> = {
  success: boolean;
  isLoading?: boolean;
  status?: number;
  statusCode?: number | null;
  errorMessage?: string | null;
  response?: T;
  error?: AxiosError;
  articule?: any;
  data?: any;
};

export const handleSubModuleError = (
  error: AxiosError | any
): HTTPServicesResponse<AxiosError> => {
  return {
    success: false,
    errorMessage: getErrorMessage(error) || error.message,
    statusCode: error?.response?.status,
    payload: error,
  };
};

export type HTTPServicesResponse<T = any> = {
  success: boolean;
  statusCode?: number | null;
  errorMessage?: string | null;
  payload?: T | null;
  error?: AxiosError;
};

export interface UCCLiensState extends AsyncState {
  data?: Types.UCCLiensTypes.UCCLiensResponse | null;
}

export interface BillsPdfState extends AsyncState {
  data?: Types.BillsPdfTypes.BillsPdfResponse | null;
}

export interface LoandDetailsState extends AsyncState {
  data?: Types.LoanDetailsTypes.LoanDetailsResponse | null;
  patchLoanDetailsStatus?: LoadingStatus;
  patchLoanDetailsisSCRAApplicable?: LoadingStatus;
  updatedPrimeRate: GeneralState<Types.ApiResponse<number[]>>;
}

export interface BorrowerDetailsObj extends AsyncState {
  data?: BorrowerDetails | null;
  isUserValidated?:boolean | null;
}

export interface RepaymentDetailsObj extends AsyncState {
  data?: RepaymentDetails | null;
}

export interface PaymentDetailsObj extends AsyncState {
  data?: PaymentDetails | null;
}
export interface OriginalTermsObj extends AsyncState {
  data?: OriginalTerms | null;
}

export interface PartnerDetailsObj extends AsyncState {
  data?: PartnerDetails | null;
}

export interface BannersObj extends AsyncState {
  data?: Banners[] | null;
}
export interface UpdatedPrimeRateObj extends AsyncState {
  data?: number[] | null;
}

export interface RepaymentDetails {
  amountMinimum: string;
  promoAmountMinimum: string;
  frequency: string;
  oldestUnpaidDueDate: string;
  paymentMethod: string;
  autopay: boolean;
}

export interface PaymentDetails {
  amount: string;
  date: string;
  pastDuePayments: string;
}

export interface BorrowerDetails {
  id: string;
  externalId: string;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  ssn: string;
  phone: string;
  email: string;
  address: string;
  authorizedContact: string;
  isPrimary: boolean;
  mailingStreet: string;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
  doNotContactFlag: boolean;
  dateOfBirth: string;
  phoneCallOptIn: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
  unit: string;
  isMilitary: boolean;
  annualIncome: string;
  employmentDetails: EmploymentDetails;
}
export interface EmploymentDetails {
  employerName: string;
  employerPhone: string;
  employmentType: string;
}

export interface PartnerDetails {
  id: string;
  createdById: string;
  createdDate: string;
  deleted: boolean;
  lastModifiedById: string;
  lastModifiedDate: string;
  systemModificationDate: string;
  name: string;
  salesforceId: string;
  type: string;
  source: string;
  partnerSubSource: string;
  partnerSubSourceKey: string;
  partnerType: string;
  merchantPartnerType: string;
  type2: string;
  status: string;
  bureau: string;
  defaultFeeHandling: string;
  industry: string;
  productType: string;
  channel: string;
  overrideEmploymentLengthToOneYear: boolean;
  forceCqIfPhoneAvailable: boolean;
  subsequentOfferNotReturned: boolean;
  validateWorkPhone: boolean;
  assignImmediately: boolean;
  usePromotionFields: boolean;
  mailingStateProvince: string;
  targetSiteFlow: string;
  loanInvestor: null;
  multipleOpportunity: true;
  declineSuppressionInactive: boolean;
  coBrandingName: string;
  useMerchantStateForOfferGeneration: boolean;
  leadProvidedData: string;
  exposeInterestRateInShowOffers: boolean;
  serviceAccount: string;
  consumerLPPhone: string;
  fundingPaymentMode: string;
  pseudoHardPull: boolean;
  smbFlow: boolean;
  offerCodeApplied: null;
  offerCodeValidUpTo: null;
  offerCodeValidity: number;
  externalId: string;
  defaultUseOfFunds: null;
  offerCriteria: null;
  minOfferAmount: number;
  maxOfferAmount: number;
  minTerm: null;
  maxTerm: null;
  excellentMin: null;
  excellentMax: null;
  goodMin: null;
  goodMax: null;
  fairMin: null;
  fairMax: null;
  poorMin: null;
  poorMax: null;
  states: null;
  openContractCount: null;
  maxLoanAmount: null;
  minLoanAmount: null;
  discountFee: null;
  enforceDupSSN: boolean;
  skipIdology: boolean;
  skipClarity: boolean;
  returnGrade: boolean;
  activePartner: boolean;
  allowOffers: boolean;
  contactRestrictedPartner: boolean;
  ficoMax: boolean;
  ficoMin: boolean;
  doNotUseBankCharter: boolean;
  defaultPaymentFrequency: string;
  offerProducts: string;
  isFeb: boolean;
  febEffectiveDate: null;
  febEffectiveDateTime: null;
  displayOnlyEffectiveApr: boolean;
  scfFlow: null;
  accountPhone: number;
  accountWebsite: string;
  accountSite: null;
  accountOwnerId: string;
  parentAccountId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  version: number;
}

export interface OriginalTerms {
  type: string;
  yield: number;
  feeHandling: string;
  termMonths: number;
  annualIncome: number;
  dti: number;
  ear: number;
  fico: number;
  grade: string;
  pti: number;
  channel: string;
  contractDate: string;
  contractSignedDate: string;
  loanInterestCalculationMethod: string;
  loanAmount: number;
  fixedFeeAmount: number;
  fixedFeePercentage: number;
  licensingBank: string;
  applicants: [
    {
      email: string;
      externalId: string;
      isPrimary: boolean;
      id: string;
      firstName: string;
      lastName: string;
    }
  ];
  fundingInTranches: boolean;
  effectiveAPR: number;
  loanAmountNoFee: number;
  maturityDate: string;
  numberOfInstallments: number;
  paymentStartDate: string;
  paymentFrequency: string;
  paymentMethod: string;
  paymentMethods: [
    {
      autoPayOffReason: string;
      isActive: true;
      isAutoPay: false;
      paymentMethod: {
        borrowerId: string;
        paymentMethodData: {
          accountName: string;
          accountNumber: string;
          accountOwner: string;
          accountType: string;
          address: string;
          bankName: string;
          cardName: string;
          cardNumber: string;
          cardType: string;
          city: string;
          expiryDate: string;
          externalId: string;
          firstName: string;
          isActive: string;
          isAutopay: string;
          lastName: string;
          line1Address: string;
          line2Address: string;
          loanAmount: number;
          phoneNumber: string;
          routingNumber: string;
          securityCode: string;
          state: string;
          zipCode: string;
        };
        type: string;
      };
    }
  ];
  partnerAccount: {
    coBrandingName: string;
    partnerAccountId: string;
  };
  expectedDisbursalDate: string;
  productName: string;
  loanApplicationId: string;
  accountOwnerId: string;
  accountOwnerName: string;
  parentAccountId: string;
  parentAccountName: string;
  paymentAmount: number;
  firstPaymentScheduled: boolean;
  refinancePayoffAmount: number;
  contactId: string;
  isFundingInTranches: boolean;
  isFirstPaymentScheduled: boolean;
  isAutopay: boolean;
  isOnPromotion: boolean;
  opportunityName: string;
  firstPaymentDate: string;
  loanAccrualStartDate: string;
  loanLastAccrualDate: string;
  salesRep: string;
  timeCountingMethod: string;
  attachments: [{ attachmentId: string; fileName: string }];
  closeDate: string;
  stage: string;
  loanApplication: LoanApplication;
  lateFee: number;
  totalCostOfAcquisition: number;
  originalLeadSource: string;
  subSource: string;
  useOfFunds: string;
  creditUnionMembership: string;
  directPartnerCost: string;
  incomeStatementGla: string;
  isContractOriginatedFromMultiLoan: boolean;
  lockboxAddress: string;
  industry: string;
  originatedContactName: string;
  disbursedAmount?: string;
  primeRate?: number | undefined;
  baseApr?: number | undefined;
  ofeeAmount?: number | undefined;
  ofeePercentage?: number | undefined;
  merchantDiscountAmount?: number | undefined;
  merchantDiscountPercentage?: number | undefined;
  estimatedInterest?: number | undefined;
  assetSaleLine?:string;
  assetSaleDate?:string;
  assetSaleStatus?:string;
  assetSaleBalance?:number | undefined;
}

export interface LoanApplication {
  id: string;
  name: string;
  closedDate: string;
  stage: string;
  fundingPaymentMode: string;
  type: string;
}

export interface LoanPromotion {
  durationDays: number;
  durationMonths: number;
  endDate: string;
  payoffAmount: number;
  minimumPayment: number;
  merchantDiscountAmount: number;
  merchantDiscountPercentage: number;
  discountAmount: number;
  discountPercentage: number;
  type: string;
  discountBalance: number;
  interestPaid: number;
  principalPaid: number;
  bustDate: string;
}

export interface FeesDetails {
  feesRemaining: number;
  feesPaid: number;
  servicingFee: number;
  averageServicingFee: number;
  cumulativeServicingFee: number;
  currentFixedAmount: number;
  currentFixedPercentage: number;
  fixedPaidAmount: number;
  fixedPaidPercentage: number;
  fixedRemainingPercentage: number;
  reportingFee: number;
  remainingAmount: number;
  totalAmount: number;
  amortisedAmount: number;
  uccLienFee: number;
}

export interface FeesDetailsObj extends AsyncState {
  data?: FeesDetails | null;
}
export interface FilingStatus {
  lienRefNumber: string;
  jurisdiction: string;
  filingOffice: string;
  fileStatus: string;
  fileStatusDescription: string;
  fileType: string;
}

export interface UCCLiens {
  id: string;
  version: Number;
  createdAt: Number;
  lastModifiedAt: Number;
  merchantId: string;
  customerId: string;
  opportunityId: string;
  loanId: string;
  orderRefId: string;
  orderStatus: string;
  orderDataStatus: string;
  description: string;
  uccOriginallyFiledAt: Number;
  uccLastAmendedAt: Number;
  statusLastCheckedAt: Number;
  statusLastChangedAt: Number;
  filingStatus: FilingStatus;
}

export interface UccLiensDetailsObj extends AsyncState {
  data?: UCCLiens | null;
}

export interface PromoDetails {
  type: string;
  payoffAmount: number;
  bustDate: string;
  endDate: string;
  minimumPayment: number;
  durationMonths: number;
  durationDays: number;
  discountAmount: number;
  discountPercentage: number;
  discountBalance: number;
  principalPaid: number;
  interestPaid: number;
  merchantDiscountAmount: number;
  merchantDiscountPercentage: number;
  merchantName: string;
  totalDurationDays: string;
  promoMinimumPayment: string;
  promoPayoffAmount: string;
}

export interface PromoDetailsObj extends AsyncState {
  data?: PromoDetails | null;
}

export interface RelatedLoansDetails {
  data: {
    name: string;
    type: string;
    status: string;
    createdDate: string;
  }[];
  renewedFrom: string;
  refinancedBy: string;
  pagination: {
    count: number;
    top: number;
    skip: number;
  };
  count: number;
}

export interface LoanScheduleDetails {
  dueDate: string;
  balance: number;
  interest: number;
  sequence: number;
  principal: number;
  minimumAmount: number;
}

export interface RelatedLoansDetailsObj extends AsyncState {
  datas?: RelatedLoansDetails | null;
}

export interface LoanScheduleDetailsObj extends AsyncState {
  data?: LoanScheduleDetails[] | null;
}
interface AccountDetails {
  accountOwner: string;
  type: string;
  isAutopay: boolean;
  isActive: boolean;
}

export interface AccountDetailsObj extends AsyncState {
  data?: AccountDetails[] | null;
}

interface AccountAch {
  accountOwner: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  accountType: string;
  bankName: string;
  isAutopay: boolean;
  isActive: boolean;
  routingNumber: number;
  accountNumber: number;
  otAchDebitDate: string;
}

export interface AccountAchObj extends AsyncState {
  data?: AccountAch | null;
}

interface AccountCards {
  accountOwner: string;
  address: string;
  city: string;
  state: string;
  zip: number;
  expirationDate: string;
  cardType: string;
  cardName: string;
  isAutopay: boolean;
  isActive: boolean;
  cardNumber: number;
  securityCode: number;
}

export interface AccountCardsObj extends AsyncState {
  data?: AccountCards | null;
}
//types for loan details history
export interface LoanDetailsHistory {
  dateTime: string;
  username: string;
  fieldName: string;
  previousValue: string;
  newValue: string;
}

export interface LoanDetailsHistoryObj extends AsyncState {
  data?: LoanDetailsHistory[] | null;
}

export interface Documents {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  modifiedBy: string;
  linkedFrom: string;
  type: string;
}

export interface DocumentsObj extends AsyncState {
  data?: Documents[] | null;
  isGenerateDocsLoading?: boolean;
}

export interface UserAccountDetails {
  id: string;
  type: string;
  owner: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
  autoPay: boolean;
  active: boolean;
  bankname: string;
  accountNumber: string;
  routingNumber: string;
  accountCardType: string;
  cardHolder: string;
  cardNumber: string;
  expireDate: string;
  cvv: string;
}

export interface Banners {
  type: string;
  details: BannerDetails;
  severity: string;
  active: boolean;
}

export interface BannerDetails {
  message: string;
}
export interface PaymentMethodsState extends AsyncState {
  data?: ResponsePaymentDetails[] | null;
  PaymentMethodByAssociationId?: ResponsePaymentDetails | null;
  isAddPaymentMethodStatus?: LoadingStatus;
  PaymentDetailsByIdStatus?: LoadingStatus;
  isDeletePaymentMethodStatus?: LoadingStatus;
  isEditPaymentMethodStatus?: LoadingStatus;
  getAutoPayReasonsStatus?: LoadingStatus;
  getAutoPayReasons?: string[];
}
export interface PaymentMethods {
  debitCards?: DebitCard[];
  bankAccounts?: BankAccount[];
}

export interface BankAccount {
  default: boolean;
  bankId: string;
  bankName: string;
  routingNumber: string;
  accountingNumber: string;
  accountType: string;
  detailsType?: string;
  isSelectedForAutopay: boolean;
  ownerName: string;
}

export interface DebitCard {
  accountId: string;
  cardNumber: string;
  expiryDate: string;
  createdDate: Date;
  selectedForAutoPay: boolean;
  cardType: string;
  active: boolean;
  default: boolean;
  detailsType?: string;
  ownerName: string;
}

export interface GridResponse {
  accountId?: string;
  cardNumber?: string;
  expiryDate?: string;
  createdDate?: Date;
  selectedForAutoPay?: boolean;
  cardType?: string;
  active?: boolean;
  default?: boolean;
  bankId?: string;
  bankName?: string;
  routingNumber?: string;
  accountingNumber?: string;
  accountType?: string;
}

/** Repayment Method Types */
export interface AddPaymentRequestBody {
  paymentMethodType?: string;
  isActive?: boolean;
  isAutoPayEnabled?: boolean;
  paymentMethodData?: PaymentMethodData;
}

export interface PaymentMethodData {
  achDetails?: AchDetails;
  cardDetails?: CardDetails;
}

export interface AchDetails {
  city?: string;
  state?: string;
  accountType?: string;
  bankName?: string;
  accountNumber?: string;
  routingNumber?: string;
  zipCode?: string;
  accountOwner?: string;
}

export interface CardDetails {
  cardNumber?: string;
  expiryDate?: string;
  securityCode?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  accountOwner?: string;
  firstName?: string;
  lastName?: string;
  line1Address?: string;
  line2Address?: string;
}

export interface PaymentAccountDetails {
  bankId?: string;
  bankName?: string;
  routingNumber?: string;
  accountType?: string;
  isSelectedForAutopay?: boolean;
  ownerName?: string;
  address?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  isActive?: boolean;
  accountingNumber?: string;
  cardNumber?: string;
  expiryDate?: string;
  cardType?: string;
  cardName?: string;
  accountId?: string;
  securityCode?: string;
}

/********** TYPES FOR CONFIGURATION PAGES ***********/

export interface ProductConfig {
  name: string;
  glCode: string;
  type: string;
  active: boolean;
}

export interface ProductConfigObj extends AsyncState {
  data?: ProductConfig | null;
}

export interface ProductConfigurationReq {
  name?: string;
  glCode?: string;
  type?: string;
  active?: boolean;
  billingFrequencyOptions?: any;
  interestCalculationMethod?: string;
  defaultBillingFrequency?: string;
  defaultPaymentApplicationMode?: string;
  minimumFinancedAmount?: number | string;
  feeSetId?: string;
  maximumInterestRate?: number | string;
  graceDaysLateFee?: number | string;
  writeOffToleranceAmount?: number | string;
  defaultInterestRate?: number | string;
  writeOffDelinquentDays?: number | string;
  interestOnlyPeriod?: number | string;
  timeCountingMethod?: string;
  maximumFinancedAmount?: number | string;
  interestType?: string;
  minimumInterestRate?: number | string;
  graceDaysDelinquent?: number | string;
  delinquencyAgingSetId?: string;
  productLoanControlAccountId?: string;
  cashAccountId?: string;
  inwardCheckClearanceAccountId?: string;
  checksBankAccountId?: string;
  productBankId?: string;
  productExcessAccountId?: string;
  productWriteOffRecoveryAccountId?: string;
  productInterestReceivableAccountId?: string;
  productLoanLossProvisionAccountId?: string;
  productLoanLossReserveAccountId?: string;
  productInterestIncomeAccountId?: string;
  productInterestExpenseAccountId?: string;
  profitAndLossAccountId?: string;
  operationsBankAccountId?: string;
  outwardCheckClearanceAccountId?: string;
}

export interface ProductConfigDetailsObj extends AsyncState {
  data?: ProductConfigurationReq | null;
}

export interface TransactionConfig {
  name: string;
  glCode: string;
  mode: string;
  source: string;
  type: string;
}

export interface TransactionConfigObj extends AsyncState {
  data?: TransactionConfig | null;
}

export interface TransactionFeesSetConfig {
  name: string;
  glCode: string;
  mode: string;
  source: string;
  type: string;
}

export interface TransactionFeesSetConfigObj extends AsyncState {
  data?: TransactionFeesSetConfig | null;
}

//fees set
export interface FeesSet {
  name: string;
  id: string;
}

export interface FeesSetObj extends AsyncState {
  data?: FeesSet | null;
}

//Delinquency Set
export interface DelinquencySet {
  name: string;
  id: string;
}

export interface DelinquencySetObj extends AsyncState {
  data?: DelinquencySet | null;
}

export interface PaymentModes {
  name: string;
  id: string;
}

export interface PaymentModesObj extends AsyncState {
  data?: PaymentModes[] | null;
}
export interface PostDisbursalTransactional {
  disbursedAmount: string;
  modeOfPaymentId: string;
  date: string;
  reference: string;
}

export interface PostDisbursalTransactionalObj extends AsyncState {
  data?: PostDisbursalTransactional | null;
}

export interface ReverseDisbursalTransactional {
  id: number;
  loanId: number;
  name: string;
  originalTraceNumber?: string;
  createdAt: string;
  createdBy?: string;
  amountToCapitalize: 11;
  balanceSheetGla: string;
  capitalizeDisbursal: boolean;
  company?: string;
  currency: string;
  balance: number;
  principal: number;
  interest: number;
  disbursedAmount: number;
  fee?: string;
  type: string;
  lessFee?: string;
  financedAmount?: string;
  incomeStatementGla: string;
  loanInterestRate?: string;
  merchantName?: string;
  modeOfPaymentId: string;
  reference: string;
  refinancePayoffAmount?: string;
  resubmitLoan?: string;
  revenueEndDate?: string;
  revenueStartDate?: string;
  revenueToRecognize: number;
  currentStatus: {
    id: string;
    status: string;
    disbursalTxnId: number;
    createdAt: string;
    createdBy: string;
    achFileName?: string;
    receiptId?: string;
    cardAuthorizationDate?: string;
    cardTransactionFee?: string;
    cardTransactionId?: string;
    traceNumber?: string;
    sentToAchOn?: string;
    errorDescription?: string;
    isLoanCleared?: string;
    isReadyForFFAIntegration?: string;
    isReadyForRevRec?: string;
    isFfaIntegrationComplete?: string;
    isReversed?: string;
    isSentToAch?: string;
    isSentToBank?: string;
  };
  txnDate?: string;
  paymentModeId?: string;
  confirmationNumber?: string;
}

export interface ReverseDisbursalTransactionalObj extends AsyncState {
  data?: ReverseDisbursalTransactional[] | null;
}

export interface PostReversalDisbursalRequestBody {
  disbursalTxnId: string;
  transactionTime?: string;
  summary?: string;
  isArchived?: boolean;
  masterArchiveObject?: string;
  summaryRecordId?: string;
  isInternalAccountingGenerated?: boolean;
  isRejected?: boolean;
  receiptId?: string;
  isCleared?: boolean;
  lastModifiedBy?: string;
  isFfaDisbursementReversalComplete?: boolean;
  createdBy?: string;
  currency?: string;
  reversalReason?: string;
  reasonCode: string;
  paymentModeId?: string;
}

export interface PostReversalDisbursalResponse {
  id: string;
}

export interface PostReversalDisbursalResponseObj extends AsyncState {
  data?: PostReversalDisbursalResponse | null;
}

export interface DisbursalReversal {
  id: string;
  disbursalTxnId: string;
  transactionTime: string;
  summary: string;
  isArchived: boolean;
  masterArchiveObject: string;
  summaryRecordId: string;
  isInternalAccountingGenerated: boolean;
  isRejected: boolean;
  receiptId: string;
  isCleared: boolean;
  lastModifiedBy: string;
  isFfaDisbursementReversalComplete: boolean;
  name: string;
  createdBy: string;
  currency: string;
  status: string;
  createdAt: string;
  paymentModeId: string;
  disbursedAmount: string;
}

export interface DisbursalReversalObj extends AsyncState {
  data?: DisbursalReversal[] | null;
}

//User Roles Response Types
export interface UserRolesState extends AsyncState {
  data?: string[] | null;
}

//Payment Transactions

export interface LoanDuesDetails {
  nextDueDate: string;
  currentPaymentAmount: number;
  balance: number;
  pastDuePayments: number;
  interestRate: number;
}

export interface LoanDuesObj extends AsyncState {
  data?: LoanDuesDetails | null;
}

//Payments APIs
export interface PaymentTransaction {
  id: string;
  loanId: string;
  paymentApplicationMode: string;
  transactionCreationDate: string;
  paymentType: string;
  productCode: string;
  hasRescheduleThresholdCrossed: boolean;
  hasSummary: boolean;
  isClosureTolerancePayment: boolean;
  ioa: number;
  masterArchiveObjectId: string;
  isRebatePayment: boolean;
  isArchived: boolean;
  hasRetried: boolean;
  interestRate: 0;
  payoffBalance: number;
  paymentSpreadId: string;
  balance: number;
  achFilename: string;
  principal: number;
  paymentMode: string;
  transactionTime: string;
  transactionAmount: number;
  totalChargesPrincipal: number;
  totalChargesInterest: number;
  reference: string;
  excess: number;
  duesSnapshot: string;
  clearingDate: string;
  isCleared: boolean;
  loanSnapshot: string;
  interest: number;
  fees: number;
  paymentMethodType: string;
  paymentMethodAccount: string;
  processor: string;
  feeSnapshot: string;
  otherChargesPrincipal: number;
  otherChargesInterest: number;
  automaticResubmission: null;
  currency: string;
  paymentMethodId: string;
  paymentSource: string;
  isOneTimePayment: boolean;
  name: string;
  isCreatedByJob: boolean;
  wiredDate: string;
  currentStatus: {
    id: string;
    paymentTxnId: string;
    status: string;
    createdAt: string;
    createdBy: string;
    achFileName: string;
    cardAuthorizationDate: string;
    cardTransactionFee: 0;
    cardTransactionId: string;
    traceNumber: string;
    isSentToAch: boolean;
    sentToAchOn: string;
    isReversed: boolean;
    reversalReason: string;
    receiptId: string;
    receiptDate: string;
    returnInitiatedBy: string;
    errorDescription: string;
  };
  locDetails?: {
    createdBy?: string | null;
    modifiedBy?: string | null;
    createdAt?: string | null;
    modifiedAt?: string | null;
    accountNumber?: string | null;
    disbursalTxnId?: number | null;
    disbursalTxnInvoiceNumber?: string | null;
    disbursalTxnConfirmationNumber?: string | null;
    paymentTxnConfirmationNumber?: string | null;
  };
  createdAt?: string | null;
  modifiedAt?: string | null;
  principalBalance?: string | number | null;
}

export interface PaymentTransactionObj extends AsyncState {
  data?: PaymentTransaction[] | null;
}

export interface RequestPaymentTransaction {
  paymentApplicationMode?: string;
  productCode?: null;
  hasRescheduleThresholdCrossed?: boolean;
  hasSummary?: boolean;
  isClosureTolerancePayment?: boolean;
  ioa?: number;
  masterArchiveObjectId?: string;
  isRebatePayment?: boolean;
  isArchived?: boolean;
  hasRetried?: boolean;
  interestRate?: number;
  payoffBalance?: number;
  balance?: number;
  achFilename?: string;
  principal?: number;
  paymentMode?: string;
  totalChargesPrincipal?: number;
  totalChargesInterest?: number;
  excess?: number;
  duesSnapshot?: string;
  clearingDate?: string;
  isCleared?: boolean;
  loanSnapshot?: string;
  interest?: number;
  fees?: number;
  feeSnapshot?: string;
  otherChargesPrincipal?: number;
  otherChargesInterest?: number;
  automaticResubmission?: null;
  isOneTimePayment?: boolean;
  currency?: string;
  paymentMethodId: string;
  paymentSource: string;
  paymentSpreadId: string;
  reference: string;
  paymentType: string;
  transactionTime: string;
  transactionAmount: string | number;
  assetSaleLine?: string;
  payoffReason?: string;
  otherPayOffReason?: string;
  returnInitiatedBy?: string;
}
export interface PaymentReversalTransactionRequestBody {
  paymentTxnId: string;
  paymentMode?: string;
  paymentType?: string;
  principal?: number;
  productCode?: string;
  interest?: number;
  isBackdated?: boolean;
  interestRate?: number;
  transactionTime?: string;
  balance?: string;
  payoffBalance?: number;
  receiptId?: string;
  reasonCode: string;
  isCleared?: true;
  reference?: string;
  reversalReason?: string;
  name?: string;
  returnInitiatedBy?: string;
  wiredDate?: string;
}
export interface PaymentReversalPostResponse {
  id: string;
}

export interface PaymentReversalPostResponsenObj extends AsyncState {
  data?: PaymentReversalPostResponse | null;
}

export interface PaymentReversalTransaction
  extends PaymentReversalTransactionRequestBody {
  id: string;
  status: string;
  createdAt: string;
  transactionAmount: number;
}

export interface PaymentReversalTransactionObj extends AsyncState {
  data?: PaymentReversalTransaction[] | null;
}
// Transactions Posting State
export interface TransactionPostingState extends AsyncState {
  data?: TransactionsPostingResponse | null;
}

export interface DisbursalAccountsState extends AsyncState {
  data?: Types.DisbursalAccountsTypes.DisbursalAccounts[] | null;
}

export interface Bills {
  id: number;
  actualToleranceAmount: number;
  isArchived: boolean;
  averageDailyBalance: number;
  balance: number;
  balanceAmount: number;
  billCycleEndDate: string;
  billCycleStartDate: string;
  billSatisfiedDate: string;
  billedFees: number;
  billedInterest: number;
  billedPrincipal: number;
  closingPrincipalBalance: number;
  compoundingInterestBilled: number;
  createdBy: string;
  currency: string;
  dueAmount: number;
  dueDate: string;
  dueType: string;
  dueTypeId: string;
  feesDue: number;
  feesWaivedInCycle: number;
  interestDue: number;
  interestWaivedInCycle: number;
  invoiceId: string;
  isInvoiced: boolean;
  isRescheduled: boolean;
  updatedBy: string;
  isLateChargeApplied: boolean;
  isMasterArchiveObject: boolean;
  nextDueDateWithoutHolidayTreatment: string;
  openingPrincipalBalance: number;
  feesPaid: number;
  interestPaid: number;
  principalPaid: number;
  paymentAmount: number;
  paymentDate: string;
  isPaymentSatisfied: boolean;
  paymentSatisfiedByPaymentTxnId: string;
  payoffAsPreviousBill: string;
  payoffBalance: number;
  previousBillAmount: number;
  isPrimary: boolean;
  regularInterestBilled: number;
  remarks: string;
  isSatisfiedWithTolerance: boolean;
  hasSummary: boolean;
  summaryRecordId: string;
  tdsAmountDue: number;
  tdsPaidAmount: number;
  tdsPercentage: number;
  tolerance: number;
  totalAmountDisbursed: number;
  totalPaymentsReceived: number;
  transactionDate: string;
  isWaiverApplied: boolean;
  name: string;
  reference: string;
  status?: string;
  scheduledPaymentTxnDate: string;
  paymentModeId?: string;
}

export interface BillsObj extends AsyncState {
  data?: Bills[] | null;
}

export interface BillsReversal {
  id: string;
  billId: string;
  transactionTime: string;
  name: string;
  status: string;
  createdAt: string;
  paymentModeId: string;
  dueAmount: number;
}

export interface BillsReversalObj extends AsyncState {
  data?: BillsReversal[] | null;
}

export interface PostBillReversalRequestBody {
  billId: string;
  reasonCode: string;
  reversalReason: string;
  paymentModeId?: string;
}

export interface PostReversalBillResponse {
  billId: string;
}

export interface PostReversalBillResponseObj extends AsyncState {
  data?: PostReversalBillResponse | null;
}
export interface PaymentSpreads extends AsyncState {
  data?: PaymentSpread[] | null;
}

export interface Charge {
  id: string;
  billId: string;
  feeId: string;
  amount: number;
  amountRemaining: number;
  dueDate: string;
  reference: string;
  status: string;
  isPaid: boolean;
  name: string;
  paymentModeId: string;
}

export interface ChargeObj extends AsyncState {
  data?: Charge[] | null;
}

export interface ChargeReversal {
  id: number;
  chargeId: string;
  transactionTime: string;
  name: string;
  status: string;
  createdAt: string;
  paymentModeId: string;
  amount: number;
}

export interface ChargeReversalObj extends AsyncState {
  data?: ChargeReversal[] | null;
}

export interface ChargeReversalRequestBody {
  chargeId: string;
  transactionTime?: string;
  reversalReason: string;
  reasonCode: string;
  paymentModeId?: string;
}

export interface ChargeReversalResponseBody {
  id: number;
}

export interface ChargeReversalResponseBodyObj extends AsyncState {
  data?: ChargeReversalResponseBody | null;
}

export interface LoanFeesTypesState extends AsyncState {
  data?: Types.LoanFeesTypes.GetLoanFeesTypesResponse[] | null;
}
export interface PostAdjustmentReversalResponse {
  id: number;
}

export interface PostAdjustmentReversalRequestBody {
  loanAdjustmentId: string;
  balance?: number;
  principal?: number;
  interest?: number;
  transactionDate?: string;
  reasonCode: string;
  reversalReason?: string;
  paymentModeId?: string;
}

export interface AdjustmentReversal {
  id: string;
  loanAdjustmentId: string;
  balance: string;
  principal: string;
  interest: string;
  interestRate: string;
  transactionDate: string;
  status: string;
  name: string;
  createdAt: string;
  paymentModeId: string;
  amount: string;
}

export interface AdjustmentReversalObj extends AsyncState {
  data?: AdjustmentReversal[] | null;
}

export interface LoanAdjustment {
  id: number;
  amount: number;
  dueDate: string;
  balance: number;
  principal: number;
  interest: number;
  fees: number;
  name: string;
  status: string;
  paymentModeId: string;
  direction: string;
  wiredDate?: string;
  classification?:string;
}

export interface LoanAdjustmentObj extends AsyncState {
  data?: LoanAdjustment[] | null;
}

export interface ReasonCode {
  name: string;
}

export interface ReasonCodeObj extends AsyncState {
  data: ReasonCode[] | null;
}

export interface CreateNotesRequest {
  loanId: string;
  noteContent: string;
  title: string;
}

export interface CreateNotesRequestObj extends AsyncState {
  data: CreateNotesRequest | null;
}

export interface CreateNotesResponse {
  id: string;
}

export interface CreateNotesResponseObj extends AsyncState {
  data: CreateNotesResponse | null;
}

export interface ConsentRequest {
  outsideCollectionAgency?: string;
  authorizedContactName?: string;
  authorizedContactPhoneNumber?: number;
  nextContact?: number;
  attorneyName?: string;
  attorneyPhoneNumber?: string;
  dmc?: string;
  dmcPhoneNumber?: string | number;
  outsideCollectionAgencyDate?: string;
  skipTrackingNumber1?: string;
  skipTrackingNumber2?: string;
  skipTrackingNumber3?: string;
  skipTrackingNumber4?: string;
  contactInstruction?: string;
  doNotContact?: boolean;
  phoneNumberMatch?: boolean;
  arbitrationOptIn?: boolean;
  authorizedContactNumberConsent?: boolean;
  attorneyPhoneNumberConsent?: boolean;
  skipTracingNumberConsent?: boolean;
  dmcPhoneNumberConsent?: boolean;
  employerPhoneNumberConsent?: boolean;
  aon?: boolean;
  nickName?: string;
}

export interface PaymentModesState extends AsyncState {
  data?: Types.PaymentModesTypes.PaymentModesResponse[] | null;
}

export interface PaymentPlanState extends AsyncState {
  data?: Types.PaymentPlanTypes.GetPaymentPlanResponse[] | null;
}

export interface PaymentPlanPaymentState extends AsyncState {
  data?: Types.PaymentPlanPaymentTypes.GetPaymentPlanPaymentResponse[] | null;
}
export interface Deceased {
  borrowerNumber: string;
  accountNum: string;
  clientCode: string;
  ssn: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  deathDate: string;
  birthDate: string;
  state: string;
  zip: string;
  ssaLSPZip: string;
  ssaVerifyCode: string;
  appendedStreetAddress1: string;
  appendedCity1: string;
  appendedState1: string;
  appendedZipcode1: string;
  appendedCounty1: string;
  matchCode: string;
  matchString: string;
  source: string;
  caseNum: string;
  filingDate: string;
  misc: string;
}

export interface DeceasedObject {
  data: Deceased | null;
  createdAt: string;
  createdBy: string;
  loanId: string;
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  name: string;
}

export interface Bankruptcy {
  accountNum: string;
  caseNumber: string;
  chapter: string;
  noticeType: string;
  assets: string;
  filingDate: string;
  dischargeDate: string;
  dismissalDate5: string;
  convertedDate: string;
  closedDate: string;
  reOpenedDate: string;
  vacateDate: string;
  transferredDate: string;
  withdrawalDate: string;
  claimDate: string;
  objectionDate: string;
  planConfirmDate: string;
  section341Date: string;
  section341Time: string;
  section341Location: string;
  bcId: string;
  courtId: string;
  courtdist: string;
  division: string;
  courtaddr1: string;
  courtaddr2: string;
  courtcity: string;
  courtstate: string;
  courtzip: string;
  courtphone: string;
  courtEmail: string;
  judge: string;
  judgeInitials: string;
  accountType: string;
  debtor: string;
  voluntaryOrInvoluntary: string;
  debtorType: string;
  debtorFirstName: string;
  debtorMiddleName: string;
  debtorLastName: string;
  debtorSuffix: string;
  debtorAlias: string;
  debtorAlias2: string;
  debtorAlias3: string;
  debtorAlias4: string;
  debtorSSN: string;
  debtorTaxId: string;
  debtorAddress1: string;
  debtorAddress2: string;
  debtorAddress3: string;
  debtorAddress4: string;
  debtorCity: string;
  debtorState: string;
  debtorZip: string;
  debtorPhone: string;
  debtorEmail: string;
  debtorCounty: string;
  debtorLawfirm: string;
  debtorAttorney: string;
  debtorAttorneyaddress1: string;
  debtorAttorneyaddress2: string;
  debtorAttorneyaddress3: string;
  debtorAttorneyaddress4: string;
  debtorAttorneycity: string;
  debtorAttorneystate: string;
  debtorAttorneyzip: string;
  debtorAttorneyphone: string;
  debtorAttorneyEmail: string;
  trusteeId: string;
  trusteeName: string;
  trusteeFirmAddress1: string;
  trusteeAddress2: string;
  trusteeAddress3: string;
  trusteeAddress4: string;
  trusteeCity: string;
  trusteeState: string;
  trusteeZip: string;
  trusteePhone: string;
  trusteeEmail: string;
  creditorNumber: string;
  creditorName: string;
  creditorAddress1: string;
  creditorAddress2: string;
  creditorAddress3: string;
  creditorAddress4: string;
  creditorCity: string;
  creditorState: string;
  creditorZip: string;
  filler: string;
  matchcode: string;
  sentdate: string;
  clientCode: string;
  matchstring: string;
  misc: string;
}

export interface BankruptcyObject {
  data: Bankruptcy | null;
  createdAt: string;
  createdBy: string;
  loanId: string;
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  name: string;
}

export interface Litigation {
  borrowerNumber: string;
  accountNum: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  socialSecurityNumber: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  client: string;
  accountOpenDate: string;
  status: string;
  future: string;
  misc: string;
  dateOfBirth: string;
  caseTypeShort: string;
  courtId: string;
  caseNum: string;
  dateFiled: string;
  matchCode: string;
  matchString: string;
  plaintiffAttorneyName: string;
  plaintiffAttorneyAddress1: string;
  plaintiffAttorneyAddress2: string;
  plaintiffAttorneyAddress3: string;
  plaintiffAttorneyAddress4: string;
  plaintiffAttorneyCity: string;
  plaintiffAttorneyState: string;
  plaintiffAttorneyZipcode: string;
  plaintiffAttorneyPhone: string;
}

export interface LitigationObject {
  data: Litigation | null;
}

export interface VigilantData {
  id: number;
  loanId: number;
  deceased: DeceasedObject;
  bankruptcy: BankruptcyObject;
  litigation: LitigationObject;
  loanName: string;
}

export interface VigilantDataObject extends AsyncState {
  data?: VigilantData | null;
}

export interface PromiseToPayState {
  getPromiseToPay: GeneralState<
    Types.ApiResponse<Types.PromiseToPayTypes.PromiseToPayResponse[]>
  >;
  postPromiseToPay: GeneralState<
    Types.ApiResponse<Types.PromiseToPayTypes.PostPromiseToPayResponse>
  >;
  deletePromiseToPay: GeneralState<Types.ApiResponse>;
  patchPromiseToPay: GeneralState<
    Types.ApiResponse<Types.PromiseToPayTypes.PatchPromiseToPayResponse>
  >;
  getPromiseToPayByPromiseID: GeneralState<
    Types.ApiResponse<Types.PromiseToPayTypes.PromiseToPayResponse>
  >;
}

// Loan Modifications State
export interface LoanModificationsState {
  modificationsGetLookup: GeneralState<
    Types.ApiResponse<
      Types.LoanModificationsTypes.LoanModificationLookupResponse[]
    >
  >;
  loanModificationsGetDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationsResponse[]>
  >;
  loanModificationsPostDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationPostResponse>
  >;
  loanModificationsPatchDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationPatchResponse>
  >;
  loanModificationsDeleteDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationDeleteResponse>
  >;
  activeLoanModificationsDeleteDetails: GeneralState<Types.ApiResponse>;
  deferralAmountGetDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.DeferralAmountResponse>
  >;
  recoveryScheduleItemsGetDetails: GeneralState<
    Types.ApiResponse<
      Types.LoanModificationsTypes.RecoveryScheduleItemsResponse[]
    >
  >;
  recoveryScheduleItemsPostDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.RecoveryScheduleItemsPostResponse>
  >;
  activeRecoveryScheduleItemsPostDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.ActiveRecoveryScheduleItemsPostResponse>
  >;
  loanAgreementPostDetails: GeneralState<Types.ApiResponse<{ id: string }>>;
  activateLoanAgreementDetails: GeneralState<Types.ApiResponse>;
  loanModificationGetSummary: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationSummaryResponse>
  >;
  modificationDetailsByID: GeneralState;
  estimatedPaymentAmountGetDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.EstimatedPaymentAmountResponse>
  >;
  scraDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.SCRADetails>
  >;
  scraGetDetails: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.SCRAResponse>
  >;
  scraIntRate: GeneralState<
    Types.ApiResponse<Types.LoanModificationsTypes.SCRAInterestRate[]>
  >;
}

export interface GenerateDocumentState {
  generateDocumentPost: GeneralState<Types.ApiResponse>;
}
export interface BillsTransactionsState {
  billsGetDetails: GeneralState<
    Types.ApiResponse<Types.BillsPostingTypes.BillsGetResponse[]>
  >;
  billsPostDetails: GeneralState<
    Types.ApiResponse<Types.BillsPostingTypes.BillsPostResponse>
  >;
}

export interface RightPartyContactState {
  rightPartyContactGetResponse: GeneralState<
    Types.ApiResponse<
      Types.RightPartyContactTypes.RightPartyContactGetPostResponse[]
    >
  >;
  rightPartyContactPostResponse: GeneralState<
    Types.ApiResponse<Types.RightPartyContactTypes.RightPartyContactGetPostResponse>
  >;
}

export interface RecoveryScheduleItemsByLoanIdResponse {
  recoveryScheduleItemsGet: GeneralState<
    Types.ApiResponse<Types.RecoveryScheduleTypes.getResponseRecoverySchedule[]>
  >;
}

export interface RightPartyLookup {
  delinquencyReason: string[];
  incomeSource: string[];
  industryCode: string[];
  termLength: string[];
}

export interface RightPartyLookupObject extends AsyncState {
  data?: RightPartyLookup[] | null;
}

export interface CreditReportingState {
  creditReportingGetDetails: GeneralState<
    Types.ApiResponse<Types.CreditReportingTypes.CreditReportingGetResponse>
  >;
  creditReportingPatchDetails: GeneralState<
    Types.ApiResponse<Types.CreditReportingTypes.CreditReportingPatchResponse>
  >;
}

export interface CommunicationsState {
  patchCommunicationPreferences: GeneralState<
    Types.ApiResponse<Types.CommunicationPreferencesTypes.PatchCommunicationPreferencesResponse>
  >;
}

export interface CommunicationActivityState {
  getDetails: GeneralState<
    Types.ApiResponse<
      Types.CommunicationsActivityTypes.GetCommunicationActivity[]
    >
  >;
  getNoteDetailsById: GeneralState<
    Types.ApiResponse<Types.CommunicationsActivityTypes.GetNoteById>
  >;
  postNoteDetails: GeneralState<Types.ApiResponse>;
  patchNoteDetails: GeneralState<Types.ApiResponse>;
  deleteNoteDetails: GeneralState<Types.ApiResponse>;
}

export interface PayoffQuoteState {
  PayoffQuoteGetResponse: GeneralState<
    Types.ApiResponse<Types.PayoffQuoteTypes.GetPayoffQuoteResponse>
  >;
  PayoffQuotePostResponse: GeneralState<
    Types.ApiResponse<Types.PayoffQuoteTypes.PostPayoffQuoteResponse>
  >;
}

export interface PromoDetailsState {
  patchPromoDetails: GeneralState<
    Types.ApiResponse<Types.PromoDetailsTypes.PatchPromoResponse>
  >;
}

export interface BulkDataState {
  bulkUpdatePostResponse: GeneralState<
    Types.ApiResponse<Types.BulkDataTypes.PostBulkDataResponse>
  >;
  preSignedResponse: GeneralState<
    Types.ApiResponse<Types.BulkDataTypes.PresignedResponse>
  >;
}

export interface SearchResultsState {
  getSearchResults: GeneralState<
    Types.ApiResponse<Types.SearchResultTypes.SearchResults[]>
  >;
}

export type ActionType = { [key: string]: { [key: string]: string } };