import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { RecoveryScheduleItemsByLoanIdResponse } from "../../../../utils/types";


export const recoveryScheduleGetPending = (state: RecoveryScheduleItemsByLoanIdResponse) => {
    state.recoveryScheduleItemsGet.loadingStatus = "loading";
    state.recoveryScheduleItemsGet.data = undefined;
    state.recoveryScheduleItemsGet.error = undefined;
  };
  
  export const recoveryScheduleGetFulfilled = (
    state: RecoveryScheduleItemsByLoanIdResponse,
    action: PayloadAction<
      Types.ApiResponse<Types.RecoveryScheduleTypes.getResponseRecoverySchedule[]>
    >
  ) => {
    state.recoveryScheduleItemsGet.loadingStatus = "loaded";
    state.recoveryScheduleItemsGet.data = action.payload;
  };
  
  export const recoveryScheduleGetRejected = (
    state: RecoveryScheduleItemsByLoanIdResponse,
    action: any
  ) => {
    state.recoveryScheduleItemsGet.loadingStatus = "error";
    state.recoveryScheduleItemsGet.error = action.payload || action.error;
  };
  