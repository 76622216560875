import { createAsyncThunk } from "@reduxjs/toolkit";

import { LoanFeesTypesAPI } from "../../../../services";

export const getLoanFeesTypes = createAsyncThunk(
  `loanFeesTypes/get`,
  async (_, { rejectWithValue }) => {
    const response = await LoanFeesTypesAPI.apiGetLoanFeesTypes();
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
