import { createAction } from '@reduxjs/toolkit';

import types, { AppUpdateInfo } from './types';


const setNetInfo = createAction<{ isConnected: boolean; isInternetReachable: boolean }>(types.NET_INFO.SET);
const setBiometricAuthPermission = createAction<boolean>(types.BIOMETRIC_AUTH.SET);
const isBiometricAuthSupported = createAction<boolean>(types.BIOMETRIC_AUTH.IS_SUPPORTED);
const setModalShown = createAction<boolean>(types.MODAL_SHOWN.SET);
const setBioAuthPromptRejected = createAction<boolean>(types.BIOMETRIC_AUTH_PROMPT.SET);
const setAutoSignoutTimestamp = createAction<number>('autoSignoutTimestamp');
const setForceUpdate = createAction<AppUpdateInfo>(types.FORCE_UPDATE.SET);

export {
	isBiometricAuthSupported,
	setAutoSignoutTimestamp,
	setBioAuthPromptRejected,
	setBiometricAuthPermission,
	setModalShown,
	setNetInfo,
	setForceUpdate
};
