import { createAsyncThunk } from "@reduxjs/toolkit";

import { PayoffQuoteAPI, Types } from "../../../../services";

export const postPayoffQuote = createAsyncThunk(
  `payoffQuote/payoffQuotePost`,
  async (
    {
      loanID,
      payload,
    }: {
      loanID: string;
      payload: Types.PayoffQuoteTypes.PayoffQuotePayload;
    },
    { rejectWithValue }
  ) => {
    const response = await PayoffQuoteAPI.apiPostPayoffQuote({
      loanID,
      payload,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const getPayoffQuote = createAsyncThunk(
    `payoffQuote/payoffQuoteGet`,
  async (
    {
      loanID,
    }: {
      loanID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await PayoffQuoteAPI.apiGetPayoffQuote({
      loanID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
