import { createAsyncThunk } from "@reduxjs/toolkit";

import { RightPartyContactAPI, Types } from "../../../../services";

export const postRightPartyContact = createAsyncThunk(
  `rightParty/rightPartyPost`,
  async (
    {
      loanID,
      payload,
    }: {
      loanID: string;
      payload: Types.RightPartyContactTypes.RightPartyContactPostPayload;
    },
    { rejectWithValue }
  ) => {
    const response = await RightPartyContactAPI.apiPostRightPartyContact({
      loanID,
      payload,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const getRightPartyContact = createAsyncThunk(
  `rightParty/rightPartyGet`,
  async (
    {
      loanID,
    }: {
      loanID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await RightPartyContactAPI.apiGetRightPartyContact({
      loanID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
