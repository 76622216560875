import { createSlice } from "@reduxjs/toolkit";

import {
  getPreSignedURL,
postBulkData
} from "./actions/bulkUpdate.thunk";
import * as reducers from "./reducers/bulkData.reducer";
import { BulkDataState } from "../../../utils/types";


export const initialState: BulkDataState = {
    bulkUpdatePostResponse: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
  preSignedResponse: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  }
};

export const BULK_DATA_UPDATE = "bulkDataUpdate";

export const bulkDataUpdateSlice = createSlice({
  name: BULK_DATA_UPDATE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(postBulkData.pending, reducers.bulkDataUpdatePending)
      .addCase(postBulkData.fulfilled, reducers.bulkDataUpdateFulfilled)
      .addCase(postBulkData.rejected, reducers.bulkDataUpdateRejected)
      .addCase(getPreSignedURL.pending, reducers.PreSignedURLPending)
      .addCase(getPreSignedURL.fulfilled, reducers.PreSignedURLFulfilled)
      .addCase(getPreSignedURL.rejected, reducers.PreSignedURLRejected)
  },
});

/*
 * Export reducer for store configuration.
 */
export const bulkUpdatePostResponseReducer = bulkDataUpdateSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const bulkDataUpdateSliceActions = bulkDataUpdateSlice.actions;
