import { createSlice } from "@reduxjs/toolkit";

import {
  postPaymentPlanPayment,
  getPaymentPlanPayment,
} from "./actions/paymentPlanPayment.thunk";
import * as reducers from "./reducers/paymentPlanPayment.reducers";
import { PaymentPlanPaymentState } from "../../../utils/types";

export const initialState: PaymentPlanPaymentState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PAYMENT_PLAN_PAYMENT_KEY = "paymentPlanPayment";

export const paymentPlanPaymentSlice = createSlice({
  name: PAYMENT_PLAN_PAYMENT_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getPaymentPlanPayment.pending,
        reducers.getPaymentPlanPaymentPending
      )
      .addCase(
        getPaymentPlanPayment.fulfilled,
        reducers.getPaymentPlanPaymentFulfilled
      )
      .addCase(
        getPaymentPlanPayment.rejected,
        reducers.getPaymentPlanPaymentRejected
      )
      .addCase(
        postPaymentPlanPayment.pending,
        reducers.postPaymentPlanPaymentPending
      )
      .addCase(
        postPaymentPlanPayment.fulfilled,
        reducers.postPaymentPlanPaymentFulfilled
      )
      .addCase(
        postPaymentPlanPayment.rejected,
        reducers.postPaymentPlanPaymentRejected
      );
  },
});

/*
 * Export reducer for store configuration.
 */
export const paymentPlanPaymentReducer = paymentPlanPaymentSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const paymentPlanPaymentSliceActions = paymentPlanPaymentSlice.actions;
