import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { constants } from "../../services";
import { getDevData } from "../../services/generalService";
import { BannersObj } from "../../utils/types";

const initialState: BannersObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

// Alert API needs Auth Key
export const getBannersData = createAsyncThunk(
  "loans/getBannersDetails",
  async (url: string, thunkAPI) => {
    try {
      const data = await getDevData(
        `${url}?key=${constants.API_AUTH_KEY}`,
        true
      );

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

const bannersDetailsSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannersData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBannersData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getBannersData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default bannersDetailsSlice.reducer;
