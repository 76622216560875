import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiResponse } from '../../../../utils/types';
import { RemoteConfigState } from '../remoteConfig.reducer';
import { fetchRemoteConfig } from '../../../../services/modules/RemoteConfig';

export const getRemoteConfig = createAsyncThunk(
	'shared/remoteConfig',
	async (): Promise<ApiResponse<Partial<RemoteConfigState>>> => {
		const response = await fetchRemoteConfig();
		if (response && response.success) return response;
		return { success: response.success };
	},
);
