import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { PromoDetailsState } from "../../../../utils/types";

// Patch Promo Details Reducers
export const patchPromoDetailsPending = (state: PromoDetailsState) => {
  state.patchPromoDetails.loadingStatus = "loading";
  state.patchPromoDetails.error = undefined;
  state.patchPromoDetails.data = undefined;
};

export const patchPromoDetailsFulfilled = (
  state: PromoDetailsState,
  action: PayloadAction<
    Types.ApiResponse<Types.PromoDetailsTypes.PatchPromoResponse>
  >
) => {
  state.patchPromoDetails.loadingStatus = "loaded";
  state.patchPromoDetails.data = action.payload;
};

export const patchPromoDetailsRejected = (
  state: PromoDetailsState,
  action: any
) => {
  state.patchPromoDetails.loadingStatus = "error";
  state.patchPromoDetails.error = action.payload || action.error;
};
