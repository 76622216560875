import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { PromiseToPayState } from "../../../../utils/types";

// Get PromiseToPay Reducers
export const getPromiseToPayPending = (state: PromiseToPayState) => {
  state.getPromiseToPay.loadingStatus = "loading";
  state.getPromiseToPay.error = undefined;
  state.getPromiseToPay.data = undefined;
};

export const getPromiseToPayFulfilled = (
  state: PromiseToPayState,
  action: PayloadAction<
    Types.ApiResponse<Types.PromiseToPayTypes.PromiseToPayResponse[]>
  >
) => {
  state.getPromiseToPay.loadingStatus = "loaded";
  state.getPromiseToPay.data = action.payload;
};

export const getPromiseToPayRejected = (
  state: PromiseToPayState,
  action: any
) => {
  state.getPromiseToPay.loadingStatus = "error";
  state.getPromiseToPay.error = action.payload || action.error;
};

// Post PromiseToPay Reducers
export const postPromiseToPayPending = (state: PromiseToPayState) => {
  state.postPromiseToPay.loadingStatus = "loading";
  state.postPromiseToPay.error = undefined;
  state.postPromiseToPay.data = undefined;
};

export const postPromiseToPayFulfilled = (
  state: PromiseToPayState,
  action: PayloadAction<
    Types.ApiResponse<Types.PromiseToPayTypes.PostPromiseToPayResponse>
  >
) => {
  state.postPromiseToPay.loadingStatus = "loaded";
  state.postPromiseToPay.data = action.payload;
};

export const postPromiseToPayRejected = (
  state: PromiseToPayState,
  action: any
) => {
  state.postPromiseToPay.loadingStatus = "error";
  state.postPromiseToPay.error = action.payload || action.error;
};

// Detete PromiseToPay Reducers
export const deletePromiseToPayPending = (state: PromiseToPayState) => {
  state.deletePromiseToPay.loadingStatus = "loading";
  state.deletePromiseToPay.error = undefined;
};

export const deletePromiseToPayFulfilled = (
  state: PromiseToPayState,
  action: PayloadAction<Types.ApiResponse>
) => {
  state.deletePromiseToPay.loadingStatus = "loaded";
  state.deletePromiseToPay.data = action.payload;
};

export const deletePromiseToPayRejected = (
  state: PromiseToPayState,
  action: any
) => {
  state.deletePromiseToPay.loadingStatus = "error";
  state.deletePromiseToPay.error = action.payload || action.error;
};

// Patch PromiseToPay Reducers
export const patchPromiseToPayPending = (state: PromiseToPayState) => {
  state.patchPromiseToPay.loadingStatus = "loading";
  state.patchPromiseToPay.error = undefined;
  state.patchPromiseToPay.data = undefined;
};

export const patchPromiseToPayFulfilled = (
  state: PromiseToPayState,
  action: PayloadAction<
    Types.ApiResponse<Types.PromiseToPayTypes.PatchPromiseToPayResponse>
  >
) => {
  state.patchPromiseToPay.loadingStatus = "loaded";
  state.patchPromiseToPay.data = action.payload;
};

export const patchPromiseToPayRejected = (
  state: PromiseToPayState,
  action: any
) => {
  state.patchPromiseToPay.loadingStatus = "error";
  state.patchPromiseToPay.error = action.payload || action.error;
};

// Get PromiseToPay By PromiseID Reducers
export const getPromiseToPayByPromiseIDPending = (state: PromiseToPayState) => {
  state.getPromiseToPayByPromiseID.loadingStatus = "loading";
  state.getPromiseToPayByPromiseID.error = undefined;
  state.getPromiseToPayByPromiseID.data = undefined;
};

export const getPromiseToPayByPromiseIDFulfilled = (
  state: PromiseToPayState,
  action: PayloadAction<
    Types.ApiResponse<Types.PromiseToPayTypes.PromiseToPayResponse>
  >
) => {
  state.getPromiseToPayByPromiseID.loadingStatus = "loaded";
  state.getPromiseToPayByPromiseID.data = action.payload;
};

export const getPromiseToPayByPromiseIDRejected = (
  state: PromiseToPayState,
  action: any
) => {
  state.getPromiseToPayByPromiseID.loadingStatus = "error";
  state.getPromiseToPayByPromiseID.error = action.payload || action.error;
};
