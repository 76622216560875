import {
  CreditReportingGetResponse,
  CreditReportingPatchResponse,
  Payload,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

// Get Credit Reporting
export const apiGetCreditReporting = async (
  loanID: string
): Promise<ApiResponse<CreditReportingGetResponse>> => {
  try {
    const { data, status } = await baseUrl.get<CreditReportingGetResponse>(
      `${loanID}/credit-reporting`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Patch Credit Reporting
export const apiPatchCreditReporting = async (
  loanID: string,
  payload: Payload
): Promise<ApiResponse<CreditReportingPatchResponse>> => {
  try {
    const { data, status } = await baseUrl.patch<CreditReportingPatchResponse>(
      `loans/${loanID}/credit-reporting`,
      payload
    );
    return { response: data, status, success: status === 200 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
