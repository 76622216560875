import {
  EditReqData,
  ReqAddPaymentData,
  ReqData,
  ReqEditPaymentData,
  ResponsePaymentDetails,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetPaymentMethodsByLoanId = async ({
  id,
}: ReqData): Promise<ApiResponse<ResponsePaymentDetails[]>> => {
  try {
    const { data, status } = await baseUrl.get<ResponsePaymentDetails[]>(
      `loans/${id}/loan-payment-methods`
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiAddPaymentMethodForLoan = async ({
  reqData,
  reqBody,
}: ReqAddPaymentData): Promise<ApiResponse<ResponsePaymentDetails>> => {
  try {
    const { data, status } = await baseUrl.post<ResponsePaymentDetails>(
      `loans/${reqData.id}/loan-payment-methods`,
      reqBody
    );
    return {
      response: data,
      status,
      success: true,
    };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiEditPaymentMethodForLoan = async ({
  reqData,
  reqBody,
}: ReqEditPaymentData): Promise<ApiResponse<any>> => {
  try {
    const { data, status } = await baseUrl.patch<ApiResponse>(
      `loans/${reqData.id}/loan-repayment-methods/${reqData.associationId}`,
      reqBody
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiDeletePaymentMethodForLoan = async ({
  id,
  associationId,
}: EditReqData): Promise<ApiResponse<any>> => {
  try {
    const { data, status } = await baseUrl.delete<ApiResponse>(
      `loans/${id}/loan-repayment-methods/${associationId}`
    );
    return {
      response: data,
      status,
      success: true,
    };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiAutoPayReasons = async (): Promise<ApiResponse<string[]>> => {
  try {
    const { data, status } = await baseUrl.get<string[]>(
      `loans/autopay-off-reasons`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
