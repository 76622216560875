import { createAsyncThunk } from "@reduxjs/toolkit";

import { CommunicationsActivityAPI, Types } from "../../../../services";

// Get Communication Notes details
export const getCommunicationsActivity = createAsyncThunk(
  `communicationNotes/getCommunicationNotes`,
  async ({ loanID }: { loanID: string }, { rejectWithValue }) => {
    const response =
      await CommunicationsActivityAPI.apiGetCommunicaitonsActivity({
        loanID,
      });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Post Communication Notes details
export const postCommunicationNotes = createAsyncThunk(
  `communicationNotes/postCommunicationNotes`,
  async (
    {
      loanID,
      payload,
    }: {
      loanID: string;
      payload: Types.CommunicationsActivityTypes.PostNotePayload;
    },
    { rejectWithValue }
  ) => {
    const response = await CommunicationsActivityAPI.apiPostNote({
      loanID,
      payload,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Get Comminucation Note by ID
export const getCommunicationNoteById = createAsyncThunk(
  `communicationNotes/getCommunicationNoteById`,
  async (
    {
      loanID,
      noteID,
    }: {
      loanID: string;
      noteID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await CommunicationsActivityAPI.apiGetNoteById({
      loanID,
      noteID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Patch Communication Notes details
export const patchCommunicationNotes = createAsyncThunk(
  `communicationNotes/patchCommunicationNotes`,
  async (
    {
      loanID,
      noteID,
      payload,
    }: {
      loanID: string;
      noteID: string;
      payload: Types.CommunicationsActivityTypes.PatchNotePayload;
    },
    { rejectWithValue }
  ) => {
    const response = await CommunicationsActivityAPI.apiPatchNote({
      loanID,
      noteID,
      payload,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Delete Communication Notes details
export const deleteCommunicationNotes = createAsyncThunk(
  `communicationNotes/deleteCommunicationNotes`,
  async (
    {
      loanID,
      noteID,
    }: {
      loanID: string;
      noteID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await CommunicationsActivityAPI.apiDeleteNote({
      loanID,
      noteID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
