import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { UCCLiensState } from "../../../../utils/types";

export const pending = (state: UCCLiensState) => {
  state.isLoading = true;
  state.data = null;
  state.isError = false;
  state.isSuccess = false;
};

export const fulfilled = (
  state: UCCLiensState,
  action: PayloadAction<
    Types.ApiResponse<Types.UCCLiensTypes.UCCLiensResponse>
  >
) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = action.payload.success;
  state.data = action.payload.response;
};

export const rejected = (state: UCCLiensState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
};
