import { createAsyncThunk } from "@reduxjs/toolkit";

import { LoanAdjustmentAPI } from "../../../../services";
import {
  LoanAdjustmentReqData,
  ReqHeader,
} from "../../../../services/modules/loan-adjustments-transactions/types";

export const postLoanAdjustments = createAsyncThunk(
  `transactionsPosting/postLoanAdjustments`,
  async (
    {
      reqHeader,
      reqBody,
    }: { reqHeader: ReqHeader; reqBody: LoanAdjustmentReqData },
    { rejectWithValue }
  ) => {
    const response = await LoanAdjustmentAPI.apiAddLoanAdjustments(
      reqHeader,
      reqBody
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
