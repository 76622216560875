import { createAsyncThunk } from "@reduxjs/toolkit";

import { BillsPdfAPI } from "../../../../services";

export const getBillsPdf = createAsyncThunk(
  `billsPdf/get`,
  async (
    billID:number,
    { rejectWithValue }
  ) => {
    const response = await BillsPdfAPI.apiGetBillsPdf({billID});
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
