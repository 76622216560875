import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getData,
  postData,
  deletData,
  patchData,
} from "../../services/generalService";
import { DocumentsObj } from "../../utils/types";

const initialState: DocumentsObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getRightPartyDetails = createAsyncThunk(
  "getRightPartyDetails",
  async (url: string, thunkAPI) => {
    try {
      const data = await getData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const postRightPartyDetails = createAsyncThunk(
  "postRightPartyDetails",
  async (pData: { url: string; data: any }, thunkAPI) => {
    try {
      const data = await postData(pData.url, pData.data);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const deleteRightPartyDetails = createAsyncThunk(
  "deleteRightPartyDetails",
  async (url: string, thunkAPI) => {
    try {
      const data = await deletData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const updateRightPartyDetails = createAsyncThunk(
  "updateRightPartyDetails",
  async (pData: { url: string; data: any }, thunkAPI) => {
    try {
      const data = await patchData(pData.url, pData.data);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

const rightPartySlice = createSlice({
  name: "rightPartyDetails",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRightPartyDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRightPartyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getRightPartyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      })

      .addCase(postRightPartyDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postRightPartyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(postRightPartyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      })
      .addCase(updateRightPartyDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRightPartyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        //state.data = action?.payload;
      })
      .addCase(updateRightPartyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      })
      .addCase(deleteRightPartyDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRightPartyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(deleteRightPartyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default rightPartySlice.reducer;
