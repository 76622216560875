import { createAsyncThunk } from "@reduxjs/toolkit";

import { PaymentModesAPI } from "../../../../services";

export const getPaymentModes = createAsyncThunk(
  `paymentModes/get`,
  async (_, { rejectWithValue }) => {
    const response = await PaymentModesAPI.apiGetPaymentModes();
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
