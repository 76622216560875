import { createAsyncThunk } from "@reduxjs/toolkit";

import { LoanModificationsAPI, Types } from "../../../../services";

// Get LoanModificationsLookup
export const getLoanModificationsLookup = createAsyncThunk(
  `loanModifications/getLookup`,
  async (_, { rejectWithValue }) => {
    const response = await LoanModificationsAPI.apiGetLoanModificationLookup();
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Get Loan modifications details
export const getLoanModifications = createAsyncThunk(
  `loanModifications/getLoanModifications`,
  async (loanId: string, { rejectWithValue }) => {
    const response = await LoanModificationsAPI.apiGetLoanModifications(loanId);
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Post Loan modifications details
export const postLoanModifications = createAsyncThunk(
  `loanModifications/postLoanModifications`,
  async (
    {
      loanId,
      payload,
    }: {
      loanId: string;
      payload: Types.LoanModificationsTypes.LoanModificationPostPayload;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiPostLoanModifications(
      loanId,
      payload
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Patch Loan modifications details
export const patchLoanModifications = createAsyncThunk(
  `loanModifications/patchLoanModifications`,
  async (
    {
      loanId,
      loanModificationId,
      payload,
    }: {
      loanId: string;
      loanModificationId: string;
      payload:
        | Types.LoanModificationsTypes.LoanModificationPatchPayload
        | Types.LoanModificationsTypes.PatchLoanModificationPayload
        | Types.LoanModificationsTypes.CancelLoanModificationPayload
        | Types.LoanModificationsTypes.PatchActivateModificationPayload;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiPatchLoanModifications(
      loanId,
      loanModificationId,
      payload
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
// Patch Loan modifications details
export const deleteLoanModifications = createAsyncThunk(
  `loanModifications/deleteLoanModifications`,
  async (
    {
      loanId,
      loanModificationId,
    }: {
      loanId: string;
      loanModificationId: string;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiDeleteLoanModifications(
      loanId,
      loanModificationId
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Get deferral amount
export const getDeferralAmount = createAsyncThunk(
  `loanModifications/getDeferralAmount`,
  async (
    {
      loanID,
      selectedModificationsType,
    }: { loanID: string; selectedModificationsType: string },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiGetDeferralAmount(
      loanID,
      selectedModificationsType
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Get Recovery Schedule Item details
export const getRecoveryScheduleItems = createAsyncThunk(
  `loanModifications/getRecoveryScheduleItems`,
  async (
    {
      loanId,
      recoveryScheduleId,
    }: { loanId: string; recoveryScheduleId: string },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiGetRecoveryScheduleItems(
      loanId,
      recoveryScheduleId
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Post Recovery Schedule Item details
export const postRecoveryScheduleItems = createAsyncThunk(
  `loanModifications/postRecoveryScheduleItems`,
  async (
    {
      loanId,
      recoveryScheduleId,
      payload,
    }: {
      loanId: string;
      recoveryScheduleId: string;
      payload: Types.LoanModificationsTypes.RecoveryScheduleItemsPayload[];
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiPostRecoveryScheduleItems(
      loanId,
      recoveryScheduleId,
      payload
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Post Recovery Schedule Item details
export const postActiveRecoveryScheduleItems = createAsyncThunk(
  `loanModifications/postActiveRecoveryScheduleItems`,
  async (
    {
      loanId,
      recoveryScheduleId,
    }: {
      loanId: string;
      recoveryScheduleId: string;
    },
    { rejectWithValue }
  ) => {
    const response =
      await LoanModificationsAPI.apiPostActiveRecoveryScheduleItems(
        loanId,
        recoveryScheduleId
      );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Sdocs - Post Loan modifications Loan agreement
export const postLoanAgreement = createAsyncThunk(
  `loanModifications/postLoanModificationsLoanAgreement`,
  async (
    {
      loanID,
      loanModificationID,
      isPreview = false,
    }: {
      loanID: string;
      loanModificationID: string | number;
      isPreview?: boolean;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiPostLoanAgreement({
      loanID,
      loanModificationID,
      isPreview,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Post Activate Loan modifications
export const postActivateLoanModifications = createAsyncThunk(
  `loanModifications/postActivateLoanModifications`,
  async (
    {
      loanID,
      recoveryScheduleId,
    }: {
      loanID: string;
      recoveryScheduleId: string;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiPostActivateLoanModification(
      {
        loanID,
        recoveryScheduleId,
      }
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Get: Loan Modification Summary
export const getLoanModificationSummary = createAsyncThunk(
  `loanModifications/getLoanModificationSummary`,
  async (
    {
      loanID,
    }: {
      loanID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiGetLoanModificationSummary({
      loanID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// delete Active Loan modification
export const deleteActiveLoanModification = createAsyncThunk(
  `loanModifications/deleteActiveLoanModification`,
  async (
    {
      loanID,
      loanModificationID,
    }: {
      loanID: string;
      loanModificationID: string | number;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiDeleteActiveLoanModification(
      {
        loanID,
        loanModificationID,
      }
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Get: Estimate Payment Amount
export const getEstimatedPaymentAmount = createAsyncThunk(
  `loanModifications/getEstimatedPaymentAmount`,
  async (
    {
      loanID,
      frequencyType,
    }: {
      loanID: string;
      frequencyType: string;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiGetEstimatedPaymentAmount({
      loanID,
      frequencyType,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// Post SCRA details
export const postScraDetails = createAsyncThunk(
  `loanModifications/postScraDetails`,
  async (
    {
      loanID,
      payload,
    }: {
      loanID: string;
      payload: Types.LoanModificationsTypes.SCRADetails;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiPostScraDetails({
      loanID,
      payload,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);


// Get SCRA details
export const getScraDetails = createAsyncThunk(
  `loanModifications/getScraDetails`,
  async (
    {
      loanID,
    }: {
      loanID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanModificationsAPI.apiGetScraDetails({
      loanID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

//Get scraModificationInterestRate
export const getScraModificationInterestRate = createAsyncThunk(
  `loanModifications/getScraModificationInterestRate`,
  async (_, { rejectWithValue }) => {
    const response = await LoanModificationsAPI.apiScraInterestRate();
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);