// create payment modes selector
import { createSelector } from "@reduxjs/toolkit";

// Payment Modes store key and state types, RootState
import { PaymentModesState } from "../../../../utils/types";
import { RootState } from "../../../store";
import { PAYMENT_MODES_KEY } from "../PaymentModes.slice";

// Get payment modes state
export const getPaymentModesState = (state: RootState): PaymentModesState =>
  state[PAYMENT_MODES_KEY];

// create payment modes selector
export const selectPaymentModes = createSelector(
  getPaymentModesState,
  (paymentModesState) => {
    // return payment modes as {label,value}[] else empty []
    if (paymentModesState.data && paymentModesState.data.length > 0) {
      return paymentModesState.data.map((paymentMode) => ({
        value: paymentMode.id,
        label: paymentMode.name,
      }));
    }
    return [];
  }
);

// create payment modes loading selector
export const selectPaymentModesLoading = createSelector(
  getPaymentModesState,
  (paymentModesState) => paymentModesState.isLoading
);
