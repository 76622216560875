import { PaymentModesResponse } from "./types";
import { baseUrl } from "../../base-api";
import { apiError, Types } from "../../tools";

// create export apiGetPaymentModes async arrow function

export const apiGetPaymentModes = async (): Promise<
  Types.ApiResponse<PaymentModesResponse[]>
> => {
  try {
    const { data, status } = await baseUrl.get<PaymentModesResponse[]>(
      "loans/payment-modes"
    );
    return { response: data, status, success: true };
  } catch (error) {
    return apiError.handleSubModuleError(error);
  }
};
