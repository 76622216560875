import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { PaymentPlanPaymentState } from "../../../../utils/types";

// Get PaymentPlanPayment

export const getPaymentPlanPaymentPending = (
  state: PaymentPlanPaymentState
) => {
  state.isLoading = true;
  state.data = null;
  state.isError = false;
  state.isSuccess = false;
};

export const getPaymentPlanPaymentFulfilled = (
  state: PaymentPlanPaymentState,
  action: PayloadAction<
    Types.ApiResponse<
      Types.PaymentPlanPaymentTypes.GetPaymentPlanPaymentResponse[]
    >
  >
) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = action.payload.success;
  state.data = action.payload.response;
};

export const getPaymentPlanPaymentRejected = (
  state: PaymentPlanPaymentState
) => {
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
};

//Post Payment Plan Payment
export const postPaymentPlanPaymentPending = (
  state: PaymentPlanPaymentState
) => {
  state.isLoading = true;
};
export const postPaymentPlanPaymentFulfilled = (
  state: PaymentPlanPaymentState,
  action: PayloadAction<
    Types.ApiResponse<Types.PaymentPlanPaymentTypes.PostPaymentPlanPaymentResponse>
  >
) => {
  state.isLoading = false;
  state.isSuccess = action.payload.success;
  // state.data = action.payload.response;
};

export const postPaymentPlanPaymentRejected = (
  state: PaymentPlanPaymentState
) => {
  state.isLoading = false;
  state.isSuccess = false;
};
