import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { patchData } from "../../services/generalService";

type CurrentValueResponse = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};
const initialState: CurrentValueResponse = {
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const updateLoanCurrentValues = createAsyncThunk(
  "loans/updateCurrentValues",
  async (postData: {
    loanID: string;
    data: any
  }) => {
    const response = await patchData(`loans/${postData.loanID}`, postData.data);
    return response;
  }
);

const updateCurrentValueSlice = createSlice({
  name: "accrualDateDetails",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder

      .addCase(updateLoanCurrentValues.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLoanCurrentValues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(updateLoanCurrentValues.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      });
  },
});
export default updateCurrentValueSlice.reducer;
