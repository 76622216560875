import { createAsyncThunk } from "@reduxjs/toolkit";

import { UCCLiensAPI  } from "../../../../services";

export const getUCCLiens = createAsyncThunk(
  "uccLiens/get",
  async ({ loanID }: { loanID: string }, { rejectWithValue }) => {
    const response = await UCCLiensAPI.apiGetUCCLiens({ loanID });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
