import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getData } from "../../services/generalService";
import { PaymentDetailsObj } from "../../utils/types";

const initialState: PaymentDetailsObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getPaymentData = createAsyncThunk(
  "loans/getPaymentDetails",
  async (url: string, thunkAPI) => {
    try {
      const data = await getData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

const paymentDetailsSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPaymentData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload[0];
      })
      .addCase(getPaymentData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default paymentDetailsSlice.reducer;
