import { createAsyncThunk } from "@reduxjs/toolkit";

import { SearchResultAPI } from "../../../../services";


// Get SCRA details
export const getsearchResults = createAsyncThunk(
    `searchResults/getsearchResults`,
    async (
      {
        searchParam,
      }: {
        searchParam: string;
      },
      { rejectWithValue }
    ) => {
      const response = await SearchResultAPI.apiGetSearchResults({
        searchParam,
      });
      if (!response.success) throw rejectWithValue(response);
      return response;
    }
  );