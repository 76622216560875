import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import styles from "./errors.module.scss";
import ErrorIcon from "../../assets/images/ErrorIcon.svg";

export interface Props {}

const ErrorPage: React.FC<Props> = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={styles["error-container"]}>
            <img
              className={styles["lp-error-icon"]}
              src={ErrorIcon}
              alt="Error"
            />
          </div>
          <h1 className={`text-center mt-3 display-2 ${styles["text-red"]}`}>
            Exception Occurred
          </h1>
          {/* <h6 className="text-center">
            <span className="me-3">Error code:</span>
            <strong className={`${styles["text-red"]}`}>403 forbidden</strong>
          </h6> */}
          <p className={`text-center mt-3`}>
            Something didn't go right but let's try again.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
