import { createSlice } from "@reduxjs/toolkit";

import { getPaymentSpreads } from "./actions/paymentSpreads.thunk";
import * as reducers from "./reducers/paymentSpreads.reducers";
import { PaymentSpreads } from "../../../utils/types";

export const initialState: PaymentSpreads = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PAYMENT_SPREADS_KEY = "paymentSpread";

export const paymentSpreadsSlice = createSlice({
  name: PAYMENT_SPREADS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentSpreads.pending, reducers.pending)
      .addCase(getPaymentSpreads.fulfilled, reducers.fulfilled)
      .addCase(getPaymentSpreads.rejected, reducers.rejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const paymentSpreadsReducer = paymentSpreadsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const paymentSpreadsSliceActions = paymentSpreadsSlice.actions;
