import {
  PostPaymentPlanResponse,
  GetPaymentPlanResponse,
  ReqBody,
  ReqHeader,
  PatchPaymentPlanResponse,
  ReqBodyPatch,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetPaymentPlan = async (
  reqHeader: ReqHeader
): Promise<ApiResponse<GetPaymentPlanResponse[]>> => {
  const { loanID, type } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.get<GetPaymentPlanResponse[]>(
      `loans/${loanID}/${type}`
    );
    return { response: data, status, success: true };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiPostPaymentPlan = async (
  reqHeader: ReqHeader,
  reqBody: ReqBody
): Promise<ApiResponse<PostPaymentPlanResponse>> => {
  const { loanID, type } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.post<PostPaymentPlanResponse>(
      `loans/${loanID}/${type}`,
      reqBody
    );
    return { response: data, status, success: true };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiPatchPaymentPlan = async (
  reqHeader: ReqHeader,
  reqBody: ReqBodyPatch
): Promise<ApiResponse<PatchPaymentPlanResponse>> => {
  const { loanID, type, id } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.patch<PatchPaymentPlanResponse>(
      `loans/${loanID}/${type}/${id}`,
      reqBody
    );
    return { response: data, status, success: true };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiDeletePaymentPlan = async (
  reqHeader: ReqHeader
): Promise<ApiResponse<PostPaymentPlanResponse>> => {
  const { loanID, type, id } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.delete<PostPaymentPlanResponse>(
      `loans/${loanID}/${type}/${id}`
    );
    return { response: data, status, success: true };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};
