import { GetPayoffQuoteResponse, PayoffQuotePayload, PostPayoffQuoteResponse } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";


export const apiPostPayoffQuote = async ({
    loanID,
  payload,
}: {
    loanID: string;
  payload: PayoffQuotePayload;
}): Promise<ApiResponse<PostPayoffQuoteResponse>> => {
  try {
    const { data, status } = await baseUrl.post<PostPayoffQuoteResponse>(
      `loans/${loanID}/pay-off-quotes`,
      payload
    );
    return { response: data, status, success: status === 201 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiGetPayoffQuote = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse<GetPayoffQuoteResponse>> => {
  try {
    const { data, status } = await baseUrl.get<GetPayoffQuoteResponse>(
      `loans/${loanID}/pay-off-quotes`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
