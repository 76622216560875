import { createSlice } from "@reduxjs/toolkit";

export interface SidebarState {
  isBackToRepayment: boolean;
}

const initialState: SidebarState = {
  isBackToRepayment: false,
};

export const backToRepaymentSlice = createSlice({
  name: "Modification",
  initialState,
  reducers: {
    setIsBackToRepayment: (state, action) => {
      state.isBackToRepayment = action.payload;
    },
  },
});

export const { setIsBackToRepayment } = backToRepaymentSlice.actions;

export default backToRepaymentSlice.reducer;
