import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { patchData } from "../../services/generalService";

type ConsentResponse = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};
const initialState: ConsentResponse = {
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const postChargeOffDetails = createAsyncThunk(
    "loans/postChargeOffDetails",
    async (formData: { url: string; postsData: {} }, thunkAPI) => {
      const { url, postsData } = formData;  
        const data = await patchData(url, postsData);
        if(!data?.success) throw thunkAPI.rejectWithValue(data)
        return data;      
    }
  );

const postChargeOffDetailsSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

      .addCase(postChargeOffDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postChargeOffDetails.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(postChargeOffDetails.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      });
  },
});
export default postChargeOffDetailsSlice.reducer;
