import { createAsyncThunk } from "@reduxjs/toolkit";

import { PromiseToPayAPI, Types } from "../../../../services";

//Get PromiseToPay
export const getPromiseToPay = createAsyncThunk(
  `promiseToPay/get`,
  async ({ loanID }: { loanID: string }, { rejectWithValue }) => {
    const response = await PromiseToPayAPI.apiGetPromiseToPay({ loanID });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

//Post PromiseToPay
export const postPromiseToPay = createAsyncThunk(
  `promiseToPay/post`,
  async (
    {
      loanID,
      reqBody,
    }: { loanID: string; reqBody: Types.PromiseToPayTypes.ReqBodyPromiseToPay },
    { rejectWithValue }
  ) => {
    const response = await PromiseToPayAPI.apiPostPromiseToPay({
      loanID,
      reqBody,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

//Delete PromiseToPay
export const deletePromiseToPay = createAsyncThunk(
  `promiseToPay/delete`,
  async (
    { loanID, promiseID }: { loanID: string; promiseID: string },
    { rejectWithValue }
  ) => {
    const response = await PromiseToPayAPI.apiDeletePromiseToPay({
      loanID,
      promiseID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

//Patch/Update PromiseToPay
export const patchPromiseToPay = createAsyncThunk(
  `promiseToPay/patch`,
  async (
    {
      loanID,
      promiseID,
      reqBody,
    }: {
      loanID: string;
      promiseID: string;
      reqBody: Types.PromiseToPayTypes.ReqBodyPromiseToPay;
    },
    { rejectWithValue }
  ) => {
    const response = await PromiseToPayAPI.apiPatchPromiseToPay({
      loanID,
      promiseID,
      reqBody,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

//Get Single PromiseToPay By PromiseID
export const getPromiseToPayByPromiseID = createAsyncThunk(
  `promiseToPay/getByPromiseID`,
  async (
    {
      loanID,
      promiseID,
    }: {
      loanID: string;
      promiseID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await PromiseToPayAPI.apiGetPromiseToPayByPromiseID({
      loanID,
      promiseID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
