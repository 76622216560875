import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../store';
import { RemoteConfigKeys } from '../../../../utils/remoteConfig';

const remoteConfigSelector = (state: RootState) => state.shared.remoteConfig;

export const selectRemoteConfig = createDraftSafeSelector(remoteConfigSelector, (state) => state.config);
export const selectRemoteConfigStatus = createDraftSafeSelector(remoteConfigSelector, (state) => state.status);

export const selectMactoolBanner = createSelector(
	selectRemoteConfig,
	(remoteConfig) => remoteConfig[RemoteConfigKeys.LSP_MACTOOL_BANNER],
);

export const selectIsRemoteConfigLoading = createDraftSafeSelector(
	remoteConfigSelector,
	(state) => !state.status || state.status === 'pending',
);

