import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { CommunicationsState } from "../../../../utils/types";

// Patch Communications References Reducers
export const patchCommunicationReferencesPending = (
  state: CommunicationsState
) => {
  state.patchCommunicationPreferences.loadingStatus = "loading";
  state.patchCommunicationPreferences.error = undefined;
  state.patchCommunicationPreferences.data = undefined;
};

export const patchCommunicationReferencesFulfilled = (
  state: CommunicationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.CommunicationPreferencesTypes.PatchCommunicationPreferencesResponse>
  >
) => {
  state.patchCommunicationPreferences.loadingStatus = "loaded";
  state.patchCommunicationPreferences.data = action.payload;
};

export const patchCommunicationReferencesRejected = (
  state: CommunicationsState,
  action: any
) => {
  state.patchCommunicationPreferences.loadingStatus = "error";
  state.patchCommunicationPreferences.error = action.payload || action.error;
};
