import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import styles from "./errors.module.scss";
import AccessDeniedIcon from "../../assets/images/AccessDeniedIcon.svg";

export interface Props {}

const AccessDeined: React.FC<Props> = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={styles["error-container"]}>
            <img
              className={styles["lp-error-icon"]}
              src={AccessDeniedIcon}
              alt="403"
            />
          </div>
          <h1 className={`text-center mt-3 display-2 ${styles["text-red"]}`}>
            Access Denied
          </h1>
          <h6 className="text-center">
            <span className="me-3">Error code:</span>
            <strong className={`${styles["text-red"]}`}>403 forbidden</strong>
          </h6>
          <p className={`text-center mt-3`}>
            You don't have permissions to access this resource.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AccessDeined;
