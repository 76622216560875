import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { BillsTransactionsState } from "../../../../utils/types";

// Bills Get Reducers //
export const billsGetPending = (state: BillsTransactionsState) => {
  state.billsGetDetails.loadingStatus = "loading";
  state.billsGetDetails.data = undefined;
  state.billsGetDetails.error = undefined;
};

export const billsGetFulfilled = (
  state: BillsTransactionsState,
  action: PayloadAction<
    Types.ApiResponse<Types.BillsPostingTypes.BillsGetResponse[]>
  >
) => {
  state.billsGetDetails.loadingStatus = "loaded";
  state.billsGetDetails.data = action.payload;
};

export const billsGetRejected = (
  state: BillsTransactionsState,
  action: any
) => {
  state.billsGetDetails.loadingStatus = "error";
  state.billsGetDetails.error = action.payload || action.error;
};

// Bills Post Reducers //
export const billsPostPending = (state: BillsTransactionsState) => {
  state.billsPostDetails.loadingStatus = "loading";
  state.billsPostDetails.data = undefined;
  state.billsPostDetails.error = undefined;
};

export const billsPostFulfilled = (
  state: BillsTransactionsState,
  action: PayloadAction<
    Types.ApiResponse<Types.BillsPostingTypes.BillsPostResponse>
  >
) => {
  state.billsPostDetails.loadingStatus = "loaded";
  state.billsPostDetails.data = action.payload;
};

export const billsPostRejected = (
  state: BillsTransactionsState,
  action: any
) => {
  state.billsPostDetails.loadingStatus = "error";
  state.billsPostDetails.error = action.payload || action.error;
};
