import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { PaymentMethodsState } from "../../../../utils/types";

export const pending = (state: PaymentMethodsState) => {
  state.isLoading = true;
  state.data = null;
  state.isError = false;
  state.isSuccess = false;
  state.isAddPaymentMethodStatus = "not loaded";
  state.isDeletePaymentMethodStatus = "not loaded";
  state.isEditPaymentMethodStatus = "not loaded"
};

export const fulfilled = (
  state: PaymentMethodsState,
  action: PayloadAction<
    Types.ApiResponse<Types.PaymentMethod.ResponsePaymentDetails[]>
  >
) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = action.payload.success;
  state.data = action.payload.response;
};

export const rejected = (state: PaymentMethodsState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
};

//add new payment methods by loan id
export const postPaymentMethodByLoanIdPending = (
  state: PaymentMethodsState
) => {
  state.isAddPaymentMethodStatus = "loading";
};
export const postPaymentMethodByLoanIdFulfilled = (
  state: PaymentMethodsState
) => {
  state.isAddPaymentMethodStatus = "loaded";
};

export const postPaymentMethodByLoanIdRejected = (
  state: PaymentMethodsState
) => {
  state.isAddPaymentMethodStatus = "error";
};

// function that doesn't change the state while performing async

export const deletePaymentMethodPending = (state: PaymentMethodsState) => {
  state.isDeletePaymentMethodStatus = "loading";
};
export const deletePaymentMethodFulfilled = (state: PaymentMethodsState) => {
  state.isDeletePaymentMethodStatus = "loaded";
};

export const deletePaymentMethodRejected = (state: PaymentMethodsState) => {
  state.isDeletePaymentMethodStatus = "error";
};

export const patchPaymentMethodPending = (state: PaymentMethodsState) => {
  state.isEditPaymentMethodStatus = "loading";
};
export const patchPaymentMethodFulfilled = (state: PaymentMethodsState) => {
  state.isEditPaymentMethodStatus = "loaded";
};

export const patchPaymentMethodRejected = (state: PaymentMethodsState) => {
  state.isEditPaymentMethodStatus = "error";
};

//Auto Pay Cancellation list
export const autoPayReasonsPending = (state: PaymentMethodsState) => {
state.getAutoPayReasons = undefined
state.getAutoPayReasonsStatus = "loading"

};

export const autoPayReasonsFulfilled = (state: PaymentMethodsState, action: any) => {
state.getAutoPayReasons = action.payload.response
state.getAutoPayReasonsStatus = "loaded"
};

export const autoPayReasonsRejected = (state: PaymentMethodsState, action: any) => {
  state.getAutoPayReasons = undefined
  state.getAutoPayReasonsStatus = "error"
  };



