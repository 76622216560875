import {
  ActiveRecoveryScheduleItemsPostResponse,
  CancelLoanModificationPayload,
  DeferralAmountResponse,
  LoanModificationDeleteResponse,
  LoanModificationLookupResponse,
  LoanModificationPatchPayload,
  LoanModificationPostPayload,
  LoanModificationPostResponse,
  LoanModificationSummaryResponse,
  LoanModificationsResponse,
  PatchActivateModificationPayload,
  PatchLoanModificationPayload,
  RecoveryScheduleItemsPayload,
  RecoveryScheduleItemsPostResponse,
  RecoveryScheduleItemsResponse,
  SCRADetails,
  SCRAResponse,
  SCRAInterestRate,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

// modificationlookup api
export const apiGetLoanModificationLookup = async (): Promise<
  ApiResponse<LoanModificationLookupResponse[]>
> => {
  try {
    const { data, status } = await baseUrl.get<
      LoanModificationLookupResponse[]
    >(`loans/loan-modification-lookup`);
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Get deferral amount api, required loanId and parameter modificationsType
export const apiGetDeferralAmount = async (
  loanID: string,
  selectedModificationsType: string
): Promise<ApiResponse<DeferralAmountResponse>> => {
  try {
    const { data, status } = await baseUrl.get<DeferralAmountResponse>(
      `loans/${loanID}/modification-good-faith-amount?modificationType=${selectedModificationsType}`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// get loan modifications details
export const apiGetLoanModifications = async (
  loanId: string
): Promise<ApiResponse<LoanModificationsResponse[]>> => {
  try {
    const { data, status } = await baseUrl.get<LoanModificationsResponse[]>(
      `loans/${loanId}/modifications`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// post loan modifications details
export const apiPostLoanModifications = async (
  loanId: string,
  payload: LoanModificationPostPayload
): Promise<ApiResponse<LoanModificationPostResponse>> => {
  try {
    const { data, status } = await baseUrl.post<LoanModificationPostResponse>(
      `loans/${loanId}/modifications`,
      payload
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// patch loan modifications details
export const apiPatchLoanModifications = async (
  loanId: string,
  loanModificationId: string,
  payload?:
    | LoanModificationPatchPayload
    | PatchLoanModificationPayload
    | CancelLoanModificationPayload
    | PatchActivateModificationPayload
): Promise<ApiResponse<LoanModificationPostResponse>> => {
  try {
    const { data, status } = await baseUrl.patch<LoanModificationPostResponse>(
      `loans/${loanId}/modifications/${loanModificationId}`,
      payload
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Delete loan modifications details
export const apiDeleteLoanModifications = async (
  loanId: string,
  loanModificationId: string
): Promise<ApiResponse<LoanModificationDeleteResponse>> => {
  try {
    const { data, status } =
      await baseUrl.delete<LoanModificationDeleteResponse>(
        `loans/${loanId}/modifications/${loanModificationId}`
      );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// get RecoverySchedule Items
export const apiGetRecoveryScheduleItems = async (
  loanId: string,
  recoveryScheduleId: string
): Promise<ApiResponse<RecoveryScheduleItemsResponse[]>> => {
  try {
    const { data, status } = await baseUrl.get<RecoveryScheduleItemsResponse[]>(
      `loans/${loanId}/recovery-schedules/${recoveryScheduleId}/recovery-schedule-items`
    );

    return { response: data, status, success: Array.isArray(data) };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// post RecoverySchedule Items
export const apiPostRecoveryScheduleItems = async (
  loanId: string,
  recoveryScheduleId: string,
  payload: RecoveryScheduleItemsPayload[]
): Promise<ApiResponse<RecoveryScheduleItemsPostResponse>> => {
  try {
    const { data, status } =
      await baseUrl.post<RecoveryScheduleItemsPostResponse>(
        `loans/${loanId}/recovery-schedules/${recoveryScheduleId}/recovery-schedule-items`,
        payload
      );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// post activate RecoverySchedule details
export const apiPostActiveRecoveryScheduleItems = async (
  loanId: string,
  recoveryScheduleId: string
): Promise<ApiResponse<ActiveRecoveryScheduleItemsPostResponse>> => {
  try {
    const { data, status } =
      await baseUrl.post<ActiveRecoveryScheduleItemsPostResponse>(
        `loans/${loanId}/recovery-schedules/${recoveryScheduleId}/active`
      );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Post Sdocs: generate loan modification agreement document
export const apiPostLoanAgreement = async ({
  loanID,
  loanModificationID,
  isPreview,
}: {
  loanID: string;
  loanModificationID: string | number;
  isPreview?: boolean;
}): Promise<ApiResponse<{ id: string }>> => {
  try {
    let URL = `loans/${loanID}/modifications/${loanModificationID}/loan-agreements`;
    if (isPreview) {
      URL = URL + `?isPreview=true`;
    }
    const { data, status } = await baseUrl.post<{ id: string }>(URL);
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Post: Activate Loan Modification
export const apiPostActivateLoanModification = async ({
  loanID,
  recoveryScheduleId,
}: {
  loanID: string;
  recoveryScheduleId: string | number;
}): Promise<ApiResponse> => {
  try {
    const { data, status } = await baseUrl.post(
      `loans/${loanID}/recovery-schedules/${recoveryScheduleId}/active`
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Get: Loan Modification Summary
export const apiGetLoanModificationSummary = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse<LoanModificationSummaryResponse>> => {
  try {
    const { data, status } = await baseUrl.get<LoanModificationSummaryResponse>(
      `loans/${loanID}/loan-modifications-summary`
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// delete active laon modification
export const apiDeleteActiveLoanModification = async ({
  loanID,
  loanModificationID,
}: {
  loanID: string;
  loanModificationID: string | number;
}): Promise<ApiResponse> => {
  try {
    const { data, status } = await baseUrl.delete(
      `loans/${loanID}/modifications/${loanModificationID}`
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiGetEstimatedPaymentAmount = async ({
  loanID,
  frequencyType,
}: {
  loanID: string;
  frequencyType: string;
}): Promise<ApiResponse> => {
  try {
    const { data, status } = await baseUrl.get(
      `loans/${loanID}/estimated-payment-amount?paymentFrequency=${frequencyType}`
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiPostScraDetails = async ({
  loanID,
  payload,
}: {
  loanID: string;
  payload: SCRADetails
}): Promise<ApiResponse<SCRAResponse>> => {
  try {
    const { data, status } = await baseUrl.put(
      `loans/${loanID}/scra-notification`,
      payload
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiGetScraDetails = async ({
  loanID,
}: {
  loanID: string;
  
}): Promise<ApiResponse<SCRAResponse>> => {
  try {
    const { data, status } = await baseUrl.get(
      `loans/${loanID}/scra-notification`,
    );
    return { response: data, status, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiScraInterestRate = async (): Promise<ApiResponse<SCRAInterestRate[]>> => {
  try {
    const { data, status } = await baseUrl.get(
      `loans/product-configuration`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
