// create payment methods selector
import { createSelector } from "@reduxjs/toolkit";

import { PaymentMethodsState } from "../../../../utils/types";
import { RootState } from "../../../store";
import { PAYMENT_METHOD_KEY } from "../paymentMethod.slice";

// Get payment methods state
export const getPaymentMethodsState = (
  rootState: RootState
): PaymentMethodsState => rootState[PAYMENT_METHOD_KEY];

export const selectIsAutopayEnabled = createSelector(
  getPaymentMethodsState,
  (paymentMethodsState) => {
    const paymentMethods = paymentMethodsState?.data;
    const isAutopayEnabled = paymentMethods?.some(
      (paymentMethod) => paymentMethod.isAutopay
    );
    return isAutopayEnabled;
  }
);
