import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { CreditReportingState } from "../../../../utils/types";

// Get Credit Reporting Reducers
export const creditReportingGetDetailsPending = (
  state: CreditReportingState
) => {
  state.creditReportingGetDetails.loadingStatus = "loading";
  state.creditReportingGetDetails.error = undefined;
  state.creditReportingGetDetails.data = undefined;
};

export const creditReportingGetDetailsFulfilled = (
  state: CreditReportingState,
  action: PayloadAction<
    Types.ApiResponse<Types.CreditReportingTypes.CreditReportingGetResponse>
  >
) => {
  state.creditReportingGetDetails.loadingStatus = "loaded";
  state.creditReportingGetDetails.data = action.payload;
};

export const creditReportingGetDetailsRejected = (
  state: CreditReportingState,
  action: any
) => {
  state.creditReportingGetDetails.loadingStatus = "error";
  state.creditReportingGetDetails.error = action.payload || action.error;
};

// Patch creditReporting
export const creditReportingPatchDetailsPending = (
  state: CreditReportingState
) => {
  state.creditReportingPatchDetails.loadingStatus = "loading";
  state.creditReportingPatchDetails.error = undefined;
  state.creditReportingPatchDetails.data = undefined;
};

export const creditReportingPatchDetailsFulfilled = (
  state: CreditReportingState,
  action: PayloadAction<
    Types.ApiResponse<Types.CreditReportingTypes.CreditReportingPatchResponse>
  >
) => {
  state.creditReportingPatchDetails.loadingStatus = "loaded";
  state.creditReportingPatchDetails.data = action.payload;
};

export const creditReportingPatchDetailsRejected = (
  state: CreditReportingState,
  action: any
) => {
  state.creditReportingPatchDetails.loadingStatus = "error";
  state.creditReportingPatchDetails.error = action.payload || action.error;
};
