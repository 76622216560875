import { DisbursalAccounts } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const getDisbursalAccounts = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse<DisbursalAccounts[]>> => {
  try {
    const { data, status } = await baseUrl.get<DisbursalAccounts[]>(
      `loans/${loanID}/disbursal-accounts`
    );
    return {
      response: data,
      status,
      success: status === 200 || !!Object.keys(data).length,
    };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
