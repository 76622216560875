import { createSlice } from "@reduxjs/toolkit";

import {
  postBillsTransaction,
  getBillsTransaction,
} from "./actions/billTransactions.thunk";
import * as reducers from "./reducers/billTransaction.reducers";
import { BillsTransactionsState } from "../../../utils/types";

export const initialState: BillsTransactionsState = {
  billsPostDetails: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
  billsGetDetails: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
};

export const BILLS_TRANSACTIONS_KEY = "billsTransactions";

export const billsTransactionsSlice = createSlice({
  name: BILLS_TRANSACTIONS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillsTransaction.pending, reducers.billsGetPending)
      .addCase(getBillsTransaction.fulfilled, reducers.billsGetFulfilled)
      .addCase(getBillsTransaction.rejected, reducers.billsGetRejected)
      .addCase(postBillsTransaction.pending, reducers.billsPostPending)
      .addCase(postBillsTransaction.fulfilled, reducers.billsPostFulfilled)
      .addCase(postBillsTransaction.rejected, reducers.billsPostRejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const billsTransactionsReducer = billsTransactionsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const billsTransactionsSliceActions = billsTransactionsSlice.actions;
