import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { SearchResultsState } from "../../../../utils/types";


export const getSearchResultsPending = (state: SearchResultsState) => {
  state.getSearchResults.loadingStatus = "loading";
  state.getSearchResults.data = undefined;
  state.getSearchResults.error = undefined;
};

export const getSearchResultsFulfilled = (
  state: SearchResultsState,
  action: PayloadAction<
    Types.ApiResponse<Types.SearchResultTypes.SearchResults[]>
  >
) => {
  state.getSearchResults.loadingStatus = "loaded";
  state.getSearchResults.data = action.payload;
};

export const getSearchResultsRejected = (
  state: SearchResultsState,
  action: any
) => {
  state.getSearchResults.loadingStatus = "error";
  state.getSearchResults.error = action.payload || action.error;
};
