import { createAsyncThunk } from "@reduxjs/toolkit";

import { PaymentSpreadsAPI } from "../../../../services";

export const getPaymentSpreads = createAsyncThunk(
  `paymentSpreads/get`,
  async (_, { rejectWithValue }) => {
    const response = await PaymentSpreadsAPI.apiGetPaymentSpreads();
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
