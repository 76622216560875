import { createSlice } from "@reduxjs/toolkit";

import {
  getLoanDetails,
  patchLoanDetailsStatus,
  getLatestPrimeRate
} from "./actions/loanDetails.thunk";
import * as reducers from "./reducers/loanDetails.reducers";
import { LoandDetailsState } from "../../../utils/types";

const initialState: LoandDetailsState = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
  patchLoanDetailsStatus: "not loaded",
  updatedPrimeRate: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
};

export const LOAN_DETAILS_KEY = "loanDetails";

const loanDetailsSlice = createSlice({
  name: LOAN_DETAILS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoanDetails.pending, reducers.pending)
      .addCase(getLoanDetails.fulfilled, reducers.fulfilled)
      .addCase(getLoanDetails.rejected, reducers.rejected)
      .addCase(
        patchLoanDetailsStatus.pending,
        reducers.patchLoanDetailsStatusPending
      )
      .addCase(
        patchLoanDetailsStatus.fulfilled,
        reducers.patchLoanDetailsStatusFulfilled
      )
      .addCase(
        patchLoanDetailsStatus.rejected,
        reducers.patchLoanDetailsStatusRejected
      )
      .addCase(getLatestPrimeRate.pending, reducers.updatedPrimeRatePending)
      .addCase(getLatestPrimeRate.fulfilled, reducers.updatedPrimeRateFulfilled)
      .addCase(getLatestPrimeRate.rejected, reducers.updatedPrimeRateRejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const loanDetailsReducer = loanDetailsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const loanDetailsSliceActions = loanDetailsSlice.actions;
