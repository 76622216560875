import { createSlice } from "@reduxjs/toolkit";

import {
  getCreditReporting,
  patchCreditReporting,
} from "./actions/creditReporting.thunk";
import * as reducers from "./reducers/creditReporting.reducers";
import { CreditReportingState } from "../../../utils/types";

export const initialState: CreditReportingState = {
  creditReportingGetDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  creditReportingPatchDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
};

export const CREDIT_REPORTING_KEY = "creditReporting";

export const creditReportingSlice = createSlice({
  name: CREDIT_REPORTING_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCreditReporting.pending,
        reducers.creditReportingGetDetailsPending
      )
      .addCase(
        getCreditReporting.fulfilled,
        reducers.creditReportingGetDetailsFulfilled
      )
      .addCase(
        getCreditReporting.rejected,
        reducers.creditReportingGetDetailsRejected
      )
      .addCase(
        patchCreditReporting.pending,
        reducers.creditReportingPatchDetailsPending
      )
      .addCase(
        patchCreditReporting.fulfilled,
        reducers.creditReportingPatchDetailsFulfilled
      )
      .addCase(
        patchCreditReporting.rejected,
        reducers.creditReportingPatchDetailsRejected
      );
  },
});

// export reducer for store configuration.
export const creditReportingReducer = creditReportingSlice.reducer;

// export user reducer action creators
export const creditReportingSliceActions = creditReportingSlice.actions;
