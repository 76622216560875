import { BillsPostPayload, BillsPostResponse, BillsGetResponse } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiPostBills = async ({
  loanID,
  payload,
}: {
  loanID: string;
  payload: BillsPostPayload;
}): Promise<ApiResponse<BillsPostResponse>> => {
  try {
    const { data, status } = await baseUrl.post<BillsPostResponse>(
      `loans/${loanID}/bills`,
      payload
    );
    return { response: data, status, success: status === 201 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiGetBills = async ({
  loanID,
  isLateChargeApplied,
}: {
  loanID: string;
  isLateChargeApplied: boolean | string;
}): Promise<ApiResponse<BillsGetResponse[]>> => {
  try {
    const { data, status } = await baseUrl.get<BillsGetResponse[]>(
      `loans/${loanID}/bills?isLateChargeApplied=${isLateChargeApplied}`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
