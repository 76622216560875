import { createAsyncThunk } from "@reduxjs/toolkit";

import { LoanDetailsAPI } from "../../../../services";

export const getLoanDetails = createAsyncThunk(
  "loanDetails/get",
  async ({ externalID }: { externalID: string }, { rejectWithValue }) => {
    const response = await LoanDetailsAPI.apiGetLoanDetails({ externalID });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// get updated prime rate for loan
export const getLatestPrimeRate = createAsyncThunk(
  "latestPrimeRate/get",
  async ({ url }: { url: string }, { rejectWithValue }) => {
    const response = await LoanDetailsAPI.apiGetLatestPrimeRate({ url });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// patch loan details status
export const patchLoanDetailsStatus = createAsyncThunk(
  "loanDetails/patch",
  async (
    {
      externalID,
      status,
      statusCategory,
    }: { externalID: string; status: string; statusCategory: string },
    { rejectWithValue }
  ) => {
    const response = await LoanDetailsAPI.apiPatchLoanDetailsStatus({
      externalID,
      status,
      statusCategory,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// patch loan details scrub
export const patchLoanDetailsScrub = createAsyncThunk(
  "loanDetails/patch",
  async (
    {
      scrubResult,
      lastScrubDate,
      isPendingRecall,
      recallDate,
      loanID,
    }: {
      scrubResult?: string | null;
      lastScrubDate: Date | null | string;
      isPendingRecall?: boolean | string;
      recallDate: Date | null | string;
      loanID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await LoanDetailsAPI.apiPatchLoanDetailsScrub({
      scrubResult,
      lastScrubDate,
      isPendingRecall,
      recallDate,
      loanID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// patch loan details isSCRAApplicable
export const patchLoanDetailsIsSCRAApplicable = createAsyncThunk(
  "loanDetails/patch/isSCRAApplicable",
  async (
    {
      externalID,
      isSCRAApplicable,
    }: { externalID: string; isSCRAApplicable: boolean },
    { rejectWithValue }
  ) => {
    const response = await LoanDetailsAPI.apiPatchLoanDetailsIsSCRAApplicable({
      externalID,
      isSCRAApplicable,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

