// create repaymentStartDate selector
import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

// Loan Details store key and state types, RootState
import { Options } from "../../../../components/shared/selectInput";
import { LoandDetailsState } from "../../../../utils/types";
import { RootState } from "../../../store";
import { LOAN_DETAILS_KEY } from "../loanDetails.slice";

// Get loan details state
export const getLoanDetailsState = (rootState: RootState): LoandDetailsState =>
  rootState[LOAN_DETAILS_KEY];

// select loan details repaymentStartDate string ISO date format like "YYYY-MM-DD" and convert it into moment date object .toDate();
export const selectRepaymentStartDate = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.paymentStartDate) {
      const isValidDate = moment(
        loanDetailsState.data.paymentStartDate,
        moment.ISO_8601,
        true
      ).isValid();
      if (isValidDate) {
        return moment(loanDetailsState.data.paymentStartDate).toDate();
      } else return null;
    }
  }
);

// select maturity date
export const selectMaturityDate = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.maturityDate) {
      const isValidDate = moment(
        loanDetailsState.data.maturityDate,
        moment.ISO_8601,
        true
      ).isValid();
      if (isValidDate) {
        return moment(loanDetailsState.data.maturityDate).toDate();
      } else return null;
    }
  }
);

// select next payment date
export const selectNextPaymentDate = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.nextDueDate) {
      const isValidDate = moment(
        loanDetailsState.data.nextDueDate,
        moment.ISO_8601,
        true
      ).isValid();
      if (isValidDate) {
        return moment(loanDetailsState.data.nextDueDate).toDate();
      } else return null;
    }
  }
);

// select loan status and category default value
export const selectLoanStatus = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    let status: Options | null = null;
    let statusCategory: Options | null = null;
    if (loanDetailsState?.data?.status) {
      status = {
        value: loanDetailsState.data.status,
        label: loanDetailsState.data.status,
      };
    }
    if (loanDetailsState?.data?.statusCategory) {
      statusCategory = {
        value: loanDetailsState.data.statusCategory,
        label: loanDetailsState.data.statusCategory,
      };
    }
    return { status, statusCategory };
  }
);

// select estimated payment amount
export const selectEstimatedPaymentAmount = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.paymentAmount) {
      return loanDetailsState.data.paymentAmount;
    }
  }
);

// select loanID
export const selectLoanID = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.id) {
      return loanDetailsState.data.id;
    }
  }
);

// select product name
export const selectProductName = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.productName) {
      return loanDetailsState.data.productName;
    }
  }
);

// select payment frequency
export const selectPaymentFrequency = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.paymentFrequency) {
      return loanDetailsState.data.paymentFrequency;
    }
  }
);

// select remainingPromotionMonths
export const selectRemainingPromotionMonths = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.remainingPromotionMonths) {
      return loanDetailsState.data.remainingPromotionMonths;
    }
  }
);

// select minimumPayment from promotion in loanDetails
export const selectMinimumPayment = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.promotion?.minimumPayment) {
      return loanDetailsState.data.promotion.minimumPayment;
    }
  }
);

// select noOfBills remaining
export const selectNoOfBillsRemaining = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.data?.numberOfBillsRemaining) {
      return loanDetailsState.data.numberOfBillsRemaining;
    }
  }
);

// select 
export const selectUpdatedPrimeRate = createSelector(
  getLoanDetailsState,
  (loanDetailsState) => {
    if (loanDetailsState?.updatedPrimeRate?.data) {
      return loanDetailsState?.updatedPrimeRate?.data?.response;
    }
  }
);
