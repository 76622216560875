import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { RightPartyContactState } from "../../../../utils/types";

// Right Party Contact Get Reducers //
export const rightPartyGetPending = (state: RightPartyContactState) => {
  state.rightPartyContactGetResponse.loadingStatus = "loading";
  state.rightPartyContactGetResponse.data = undefined;
  state.rightPartyContactGetResponse.error = undefined;
};

export const rightPartyGetFulfilled = (
  state: RightPartyContactState,
  action: PayloadAction<
    Types.ApiResponse<Types.RightPartyContactTypes.RightPartyContactGetPostResponse[]>
  >
) => {
  state.rightPartyContactGetResponse.loadingStatus = "loaded";
  state.rightPartyContactGetResponse.data = action.payload;
};

export const rightPartyGetRejected = (
  state: RightPartyContactState,
  action: any
) => {
  state.rightPartyContactGetResponse.loadingStatus = "error";
  state.rightPartyContactGetResponse.error = action.payload || action.error;
};

// Right Party Contact Post Reducers //
export const rightPartyPostPending = (state: RightPartyContactState) => {
  state.rightPartyContactPostResponse.loadingStatus = "loading";
  state.rightPartyContactPostResponse.data = undefined;
  state.rightPartyContactPostResponse.error = undefined;
};

export const rightPartyPostFulfilled = (
  state: RightPartyContactState,
  action: PayloadAction<
    Types.ApiResponse<Types.RightPartyContactTypes.RightPartyContactGetPostResponse>
  >
) => {
  state.rightPartyContactPostResponse.loadingStatus = "loaded";
  state.rightPartyContactPostResponse.data = action.payload;
};

export const rightPartyPostRejected = (
  state: RightPartyContactState,
  action: any
) => {
  state.rightPartyContactPostResponse.loadingStatus = "error";
  state.rightPartyContactPostResponse.error = action.payload || action.error;
};
