import { createActionTypes } from '../../../../utils/helpers';

export default createActionTypes('shared', [
	'NET_INFO.SET',
	'NAVIGATION_HISTORY.SET',
	'BIOMETRIC_AUTH.SET',
	'BIOMETRIC_AUTH.IS_SUPPORTED',
	'MODAL_SHOWN.SET',
	'BIOMETRIC_AUTH_PROMPT.SET',
	'FORCE_UPDATE.SET',
]);

export type AppUpdateInfo = {
	shouldAppUpdate: boolean;
	isSoftUpdate?: boolean;
	latestVersion?: string;
};