import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { PayoffQuoteState } from "../../../../utils/types";


export const payoffQuoteGetPending = (state: PayoffQuoteState) => {
  state.PayoffQuoteGetResponse.loadingStatus = "loading";
  state.PayoffQuoteGetResponse.data = undefined;
  state.PayoffQuoteGetResponse.error = undefined;
};

export const payoffQuoteGetFulfilled = (
  state: PayoffQuoteState,
  action: PayloadAction<
    Types.ApiResponse<Types.PayoffQuoteTypes.GetPayoffQuoteResponse>
  >
) => {
  state.PayoffQuoteGetResponse.loadingStatus = "loaded";
  state.PayoffQuoteGetResponse.data = action.payload;
};

export const payoffQuoteGetRejected = (
  state: PayoffQuoteState,
  action: any
) => {
  state.PayoffQuoteGetResponse.loadingStatus = "error";
  state.PayoffQuoteGetResponse.error = action.payload || action.error;
};


export const payoffQuotePostPending = (state: PayoffQuoteState) => {
  state.PayoffQuotePostResponse.loadingStatus = "loading";
  state.PayoffQuotePostResponse.data = undefined;
  state.PayoffQuotePostResponse.error = undefined;
};

export const payoffQuotePostFulfilled = (
  state: PayoffQuoteState,
  action: PayloadAction<
    Types.ApiResponse<Types.PayoffQuoteTypes.PostPayoffQuoteResponse>
  >
) => {
  state.PayoffQuotePostResponse.loadingStatus = "loaded";
  state.PayoffQuotePostResponse.data = action.payload;
};

export const payoffQuotePostRejected = (
  state: PayoffQuoteState,
  action: any
) => {
  state.PayoffQuotePostResponse.loadingStatus = "error";
  state.PayoffQuotePostResponse.error = action.payload || action.error;
};
