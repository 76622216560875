import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getDevData } from "../../services/generalService";
import { VigilantDataObject } from "../../utils/types";

const initialState: VigilantDataObject = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getVigilantData = createAsyncThunk(
  "loans/getVigilantData",
  async (
    { loanID }: { loanID: string },
    thunkAPI
  ) => {
    try {
      const data = await getDevData(`loans/${loanID}/scra-report`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const vigilantDataSlice = createSlice({
  name: "vigilantData",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVigilantData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVigilantData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getVigilantData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default vigilantDataSlice.reducer;
