import { createSlice } from "@reduxjs/toolkit";

import { getUserRoles } from "./actions/userRoles.thunk";
import * as reducers from "./reducers/userRoles.reducer";
import { UserRolesState } from "../../../utils/types";

export const initialState: UserRolesState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const USER_ROLES_KEY = "userRoles";

export const userRolesSlice = createSlice({
  name: USER_ROLES_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRoles.pending, reducers.pending)
      .addCase(getUserRoles.fulfilled, reducers.fulfilled)
      .addCase(getUserRoles.rejected, reducers.rejected);
  },
});

/**
 * Export reducer for store configuration
 */
export const userRolesReducer = userRolesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const userRolesSliceActions = userRolesSlice.actions;
