import { createAsyncThunk } from "@reduxjs/toolkit";

import { PaymentPlanPaymentAPI, Types } from "../../../../services";

export const getPaymentPlanPayment = createAsyncThunk(
  `paymentPlanPayment/get`,
  async (
    {
      reqHeader,
    }: {
      reqHeader: Types.PaymentPlanPaymentTypes.ReqHeaderPayment;
    },
    { rejectWithValue }
  ) => {
    const response = await PaymentPlanPaymentAPI.apiGetPaymentPlanPaymentByID(
      reqHeader
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const postPaymentPlanPayment = createAsyncThunk(
  `paymentPlanPayment/post`,
  async (
    {
      reqHeader,
      reqBody,
    }: {
      reqHeader: Types.PaymentPlanPaymentTypes.ReqHeaderPayment;
      reqBody: Types.PaymentPlanPaymentTypes.ReqBodyPayment;
    },
    { rejectWithValue }
  ) => {
    const response = await PaymentPlanPaymentAPI.apiPostPaymentPlanPayment(
      reqHeader,
      reqBody
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
