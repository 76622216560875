import axios from "axios";

import { API_BASE_URL, API_MOCK_URL } from "../tools/constants";

// Development URL
export const baseUrl = axios.create({
  baseURL: `${API_BASE_URL}`,
});

// Mock URL -- remove in future
export const mockUrl = axios.create({
  baseURL: `${API_MOCK_URL}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer 123`,
  },
});

//Mock URL interceptors
mockUrl.interceptors.response.use(
  function (response) {
    if (response.status === 204) {
      return { ...response, data: "Successful update" };
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
