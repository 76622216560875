import { createReducer } from '@reduxjs/toolkit';

import { AppRemoteConfig, remoteConfigDefault } from '../../../utils/remoteConfig';
import { thunks } from './actions';

export type RequestLoadingStatus = 'idle' | 'pending';

export type RemoteConfigState = {
	status: RequestLoadingStatus | undefined | 'failed';
	config: AppRemoteConfig;
};
export const SHARED_KEY = "shared";

const initialState: RemoteConfigState = {
	status: undefined,
	config: remoteConfigDefault,
};

const remoteConfigReducer = createReducer(initialState, (builder) => {
	builder.addCase(thunks.getRemoteConfig.pending, (state) => {
		state.status = 'pending';
	});
	builder.addCase(thunks.getRemoteConfig.fulfilled, (state, action) => {
		const { payload } = action;
		if (payload.success && payload.response && payload.response.config) {
			state.config = payload.response.config;
			state.status = 'idle';
		} else {
			state.status = 'failed';
		}
	});
	builder.addCase(thunks.getRemoteConfig.rejected, (state) => {
		state.status = 'failed';
	});
});

export default remoteConfigReducer;
