import { createAsyncThunk } from "@reduxjs/toolkit";

import { CreditReportingAPI, Types } from "../../../../services";

// getCreditReporting
export const getCreditReporting = createAsyncThunk(
  `creditReporting/get`,
  async (loanID: string, { rejectWithValue }) => {
    const response = await CreditReportingAPI.apiGetCreditReporting(loanID);
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

// patcgreditReporting
export const patchCreditReporting = createAsyncThunk(
  `creditReporting/patch`,
  async (
    {
      loanID,
      payload,
    }: { loanID: string; payload: Types.CreditReportingTypes.Payload },
    { rejectWithValue }
  ) => {
    const response = await CreditReportingAPI.apiPatchCreditReporting(
      loanID,
      payload
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
