import { createSlice } from "@reduxjs/toolkit";

import { getPayoffQuote, postPayoffQuote } from "./actions/payoffQuote.thunk";
import * as reducers from "./reducers/payoffQuote.reducers";
import { PayoffQuoteState } from "../../../utils/types";

export const initialState: PayoffQuoteState = {
    PayoffQuoteGetResponse: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
  PayoffQuotePostResponse: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
};

export const PAYOFF_QUOTE_KEY = "payoffQuote";

export const payoffQuoteSlice = createSlice({
  name: PAYOFF_QUOTE_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayoffQuote.pending, reducers.payoffQuoteGetPending)
      .addCase(getPayoffQuote.fulfilled, reducers.payoffQuoteGetFulfilled)
      .addCase(getPayoffQuote.rejected, reducers.payoffQuoteGetRejected)
      .addCase(postPayoffQuote.pending, reducers.payoffQuotePostPending)
      .addCase(postPayoffQuote.fulfilled, reducers.payoffQuotePostFulfilled)
      .addCase(postPayoffQuote.rejected, reducers.payoffQuotePostRejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const payoffQuoteReducer = payoffQuoteSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const payoffQuoteSliceActions = payoffQuoteSlice.actions;
