import { createAsyncThunk } from "@reduxjs/toolkit";

import { PaymentMethodAPI } from "../../../../services";
import {
  EditReqData,
  ReqAddPaymentData,
  ReqData,
  ReqEditPaymentData,
} from "../../../../services/modules/payment-method-services/types";

export const getPaymentMethodsByLoanId = createAsyncThunk(
  `paymentMethod/getByLoanId`,
  async ({ id }: ReqData, { rejectWithValue }) => {
    const response = await PaymentMethodAPI.apiGetPaymentMethodsByLoanId({
      id,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const postPaymentMethodByLoanId = createAsyncThunk(
  "paymentMethod/postByLoanId",
  async (
    { reqData, reqBody }: ReqAddPaymentData,
    { dispatch, rejectWithValue }
  ) => {
    const response = await PaymentMethodAPI.apiAddPaymentMethodForLoan({
      reqData,
      reqBody,
    });
    if (!response.success) throw rejectWithValue(response);
    dispatch(getPaymentMethodsByLoanId({ id: reqData.id }));
    return response;
  }
);

export const patchPaymentMethodByAssociationId = createAsyncThunk(
  "paymentMethod/patchByAssociationId",
  async (
    { reqData, reqBody }: ReqEditPaymentData,
    { dispatch, rejectWithValue }
  ) => {
    const response = await PaymentMethodAPI.apiEditPaymentMethodForLoan({
      reqData,
      reqBody,
    });
    if (!response.success) throw rejectWithValue(response);
    dispatch(getPaymentMethodsByLoanId({ id: reqData.id }));
    return response;
  }
);

export const deletePaymentMethodByAssociationId = createAsyncThunk(
  "paymentMethod/deleteByAssociationId",
  async ({ id, associationId }: EditReqData, { dispatch, rejectWithValue }) => {
    const response = await PaymentMethodAPI.apiDeletePaymentMethodForLoan({
      id,
      associationId,
    });
    if (!response.success) throw rejectWithValue(response);
    dispatch(getPaymentMethodsByLoanId({ id }));
    return response;
  }
);

export const getAutoPayReasons = createAsyncThunk(
  `paymentMethod/autoPayReasons`,
async (
  {
    loanID,
  }: {
    loanID: string;
  },
  { rejectWithValue }
) => {
  const response = await PaymentMethodAPI.apiAutoPayReasons();
  if (!response.success) throw rejectWithValue(response);
  return response;
}
);
