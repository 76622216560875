import { initializeApp } from 'firebase/app';
import { getRemoteConfig, RemoteConfig } from 'firebase/remote-config';
import { FIREBASE_WEB_CONFIG } from '../services/tools/constants';

export enum RemoteConfigKeys {
	LSP_MACTOOL_BANNER = 'lsp_mactool_banner',
}

export const remoteConfigPFMDefaultState = {
	linked_accounts_enabled: false,
	transactions_enabled: false,
	spending_enabled: false,
	income_enabled: false,
	debts_enabled: false,
	market_enabled: false,
	insights_cards_enabled: false,
};

export const remoteConfigDefault = {
	[RemoteConfigKeys.LSP_MACTOOL_BANNER]: '',
};

export type AppRemoteConfig = typeof remoteConfigDefault;

const firebaseConfig: any = FIREBASE_WEB_CONFIG;
let missingFirebaseConfig = false;

Object.keys(firebaseConfig).forEach((key: any) => {
	if (!firebaseConfig[key]) missingFirebaseConfig = true;
});

if (missingFirebaseConfig) console.warn('Missing firebseWebConfig config');

let remoteConfig: RemoteConfig | undefined;

if (!missingFirebaseConfig) {
	// Initialize Firebase
	initializeApp(firebaseConfig);
	remoteConfig = getRemoteConfig();
	remoteConfig.settings.minimumFetchIntervalMillis = 15000;
	remoteConfig.defaultConfig = remoteConfigDefault;
}

export { remoteConfig };
