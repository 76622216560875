import { createAsyncThunk } from "@reduxjs/toolkit";

import { DisbursalAccountsAPI } from "../../../../services";

export const getDisbursalAccounts = createAsyncThunk(
  `disbursalAccounts/get`,
  async (
    {
      loanID,
    }: {
      loanID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await DisbursalAccountsAPI.getDisbursalAccounts({
      loanID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
