import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./errors.module.scss";
import notFoundImage from "../../assets/images/ico-404.svg";

export interface Props {}

const NotFound: React.FC<Props> = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={styles["error-container"]}>
            <img
              className={styles["lp-error-icon"]}
              src={notFoundImage}
              alt="404"
            />
          </div>
          <h1 className={`text-center mt-3 display-2 ${styles["text-red"]}`}>
            Page Not Found
          </h1>
          <h6 className="text-center">
            <span className="me-3">Error code:</span>
            <strong className={`${styles["text-red"]}`}>404</strong>
          </h6>
          <p className={`text-center ${styles["text-red"]} mt-3`}>
            Record Not Found.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
