import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { BILLS_PDF_KEY, billsPdfReducer } from "./modules/bills-pdf";
import {
  BILLS_TRANSACTIONS_KEY,
  billsTransactionsReducer,
} from "./modules/bills-transactions";
import {
  bulkUpdatePostResponseReducer,
  BULK_DATA_UPDATE,
} from "./modules/bulkUpdate";
import {
  CHARGE_TRANSACTIONS_KEY,
  chargeTransactionsReducer,
} from "./modules/charge-transactions";
import {
  COMMUNICATION_PERFRENCES_KEY,
  communicationPrefrencesReducer,
} from "./modules/communications";
import {
  COMMUNICATIONS_ACTIVITY_KEY,
  communicationsActivityReducer,
} from "./modules/communications-activity";
import {
  CREDIT_REPORTING_KEY,
  creditReportingReducer,
} from "./modules/credit-reporting";
import {
  DISBURSAL_ACCOUNTS_KEY,
  disbursalAccountsReducer,
} from "./modules/disbursal-accounts";
import {
  TRANSACTIONS_POSTING_KEY,
  transactionsPostingReducer,
} from "./modules/loan-adjustment";
import { LOAN_DETAILS_KEY, loanDetailsReducer } from "./modules/loan-details";
import {
  LOAN_FEES_TYPES_KEY,
  loanFeesTypesReducer,
} from "./modules/loan-fees-types";
import {
  LOAN_MODIFICATIONS_KEY,
  loanModificationsReducer,
} from "./modules/loan-modifications";
import { payoffQuoteReducer, PAYOFF_QUOTE_KEY } from "./modules/pay-off-quote";
import {
  PAYMENT_METHOD_KEY,
  paymentMethodReducer,
} from "./modules/payment-method";
import {
  PAYMENT_MODES_KEY,
  paymentModesReducer,
} from "./modules/payment-modes";
import { PAYMENT_PLAN_KEY, paymentPlanReducer } from "./modules/payment-plan";
import {
  PAYMENT_PLAN_PAYMENT_KEY,
  paymentPlanPaymentReducer,
} from "./modules/payment-plan-payments";
import {
  PAYMENT_SPREADS_KEY,
  paymentSpreadsReducer,
} from "./modules/payment-spreads";
import {
  PROMISE_TO_PAY_KEY,
  promiseToPayReducer,
} from "./modules/promise-to-pay";
import {
  PROMO_DETAILS_KEY,
  promoDetailsReducer,
} from "./modules/promo-details";
import {
  recoveryScheduleReducer,
  RECOVERYSCHEDULE__ITEMS_KEY,
} from "./modules/recovery-schedule";
import {
  rightPartyContactReducer,
  RIGHT_PARTY__CONTACT_KEY,
} from "./modules/right-party-contact";
import { searchResultsReducer, SEARCH_RESULTS_KEY } from "./modules/search-loans";
import { UCC_LIENS_KEY, uccLiensReducer } from "./modules/ucc-liens";
import { USER_ROLES_KEY, userRolesReducer } from "./modules/user-roles";
import {
  borrowerDetailsSlice,
  repaymentDetailsSlice,
  paymentDetailsSlice,
  originalTermsDetailsSlice,
  feesDetailsSlice,
  promoDetailsSlice,
  relatedLoansSlice,
  sideBarSlice,
  backToRepaymentSlice,
  accountDetailsSlice,
  accountAchSlice,
  accountCardsSlice,
  loanDetailsHistorySlice,
  documentsSlice,
  bannersDetailsSlice,
  loanScheduleDetailsSlice,
  updateCurrentValueSlice,
  productConfigSlice,
  transactionConfigSlice,
  transactionFeesSetConfigSlice,
  allDisbursalTransactionsSlice,
  productConfigDetailsSlice,
  feesSetByIDSlice,
  delinquencySetSlice,
  billsSlice,
  paymentTransactionSlice,
  postPaymentTransactionSlice,
  paymentReversalTransactionSlice,
  postPaymentReversalTransactionSlice,
  reasonCodeListSlice,
  adjustmentReversalSlice,
  loanAdjustmentSlice,
  postChargeReversalTransactionSlice,
  postAdjustmentReversalTransactionSlice,
  postBillReversalTransactionSlice,
  postDisbursalReversalTransactionSlice,
  postNotesSlice,
  chargeReversalGetSlice,
  billsReversalSlice,
  disbursalReversalSlice,
  postConsentSlice,
  vigilantDataSlice,
  postChargeOffDetailsSlice,
  postPaymentDateSlice,
  documentUploadSlice,
  rightPartySlice,
  rightPartyLookupSlice,
  communicationSlice,
  paymentRevTransactionSlice,
  uccLiensDetailsSlice,
  billsPdfDetailsSlice,
} from "./reducers";
import chargeGetSlice from "./reducers/chargeGetSlice";
import loanDuesSlice from "./reducers/loanDuesSlice";
import partnerDetailsSlice from "./reducers/partnerDetailsSlice";
import sharedReducer from "./reducers/remote-config";
import { SHARED_KEY } from "./reducers/remote-config/remoteConfig.reducer";

const store = configureStore({
  reducer: {
    [USER_ROLES_KEY]: userRolesReducer,
    [LOAN_DETAILS_KEY]: loanDetailsReducer,
    [PAYMENT_METHOD_KEY]: paymentMethodReducer,
    [TRANSACTIONS_POSTING_KEY]: transactionsPostingReducer,
    [PAYMENT_SPREADS_KEY]: paymentSpreadsReducer,
    [CHARGE_TRANSACTIONS_KEY]: chargeTransactionsReducer,
    [BILLS_TRANSACTIONS_KEY]: billsTransactionsReducer,
    [LOAN_FEES_TYPES_KEY]: loanFeesTypesReducer,
    [DISBURSAL_ACCOUNTS_KEY]: disbursalAccountsReducer,
    [PAYMENT_MODES_KEY]: paymentModesReducer,
    [PROMISE_TO_PAY_KEY]: promiseToPayReducer,
    [PAYMENT_PLAN_KEY]: paymentPlanReducer,
    [LOAN_MODIFICATIONS_KEY]: loanModificationsReducer,
    [PAYMENT_PLAN_PAYMENT_KEY]: paymentPlanPaymentReducer,
    [RIGHT_PARTY__CONTACT_KEY]: rightPartyContactReducer,
    [CREDIT_REPORTING_KEY]: creditReportingReducer,
    [COMMUNICATION_PERFRENCES_KEY]: communicationPrefrencesReducer,
    [RECOVERYSCHEDULE__ITEMS_KEY]: recoveryScheduleReducer,
    [COMMUNICATIONS_ACTIVITY_KEY]: communicationsActivityReducer,
    [PAYOFF_QUOTE_KEY]: payoffQuoteReducer,
    [PROMO_DETAILS_KEY]: promoDetailsReducer,
    [UCC_LIENS_KEY]: uccLiensReducer,
    [BULK_DATA_UPDATE]: bulkUpdatePostResponseReducer,
    [SEARCH_RESULTS_KEY]: searchResultsReducer,
    [BILLS_PDF_KEY]: billsPdfReducer,
    [SHARED_KEY]: sharedReducer,
    billsPdfDetails: billsPdfDetailsSlice,
    borrowerDetails: borrowerDetailsSlice,
    repaymentDetails: repaymentDetailsSlice,
    paymentDetails: paymentDetailsSlice,
    originalTermsDetails: originalTermsDetailsSlice,
    feesDetails: feesDetailsSlice,
    promosDetails: promoDetailsSlice,
    relatedLoans: relatedLoansSlice,
    sidebarDetails: sideBarSlice,
    isBackToRepayment: backToRepaymentSlice,
    accountDetails: accountDetailsSlice,
    accountAch: accountAchSlice,
    accountCards: accountCardsSlice,
    loanDetailsHistory: loanDetailsHistorySlice,
    documents: documentsSlice,
    bannerDetails: bannersDetailsSlice,
    loanScheduleDetails: loanScheduleDetailsSlice,
    accrualDateDetails: updateCurrentValueSlice,
    partnerDetails: partnerDetailsSlice,
    productConfigData: productConfigSlice,
    transactionConfigData: transactionConfigSlice,
    transactionFeesSetConfigData: transactionFeesSetConfigSlice,
    allDisbursalTransactions: allDisbursalTransactionsSlice,
    productConfigDetailsData: productConfigDetailsSlice,
    feesSetByIDData: feesSetByIDSlice,
    delinquencySetByIDData: delinquencySetSlice,
    paymentTransaction: paymentTransactionSlice,
    loanDues: loanDuesSlice,
    postPaymentTransaction: postPaymentTransactionSlice,
    chargeDetails: chargeGetSlice,
    allBills: billsSlice,
    paymentReversal: paymentReversalTransactionSlice,
    postPaymentReversalTransaction: postPaymentReversalTransactionSlice,
    reasonCodeList: reasonCodeListSlice,
    adjustmentReversal: adjustmentReversalSlice,
    loanAdjustment: loanAdjustmentSlice,
    postChargeReversal: postChargeReversalTransactionSlice,
    postAdjustmentReversal: postAdjustmentReversalTransactionSlice,
    postBillReversal: postBillReversalTransactionSlice,
    postDisbursalReversal: postDisbursalReversalTransactionSlice,
    postNotes: postNotesSlice,
    chargeReversalData: chargeReversalGetSlice,
    billsReversalData: billsReversalSlice,
    disbursalReversalData: disbursalReversalSlice,
    postConsent: postConsentSlice,
    getVigilantData: vigilantDataSlice,
    postChargeOffDetailsData: postChargeOffDetailsSlice,
    postPaymentDateData: postPaymentDateSlice,
    uploadDocument: documentUploadSlice,
    rightPartyDetails: rightPartySlice,
    rightpartyLookup: rightPartyLookupSlice,
    communications: communicationSlice,
    paymentRev: paymentRevTransactionSlice,
    uccLiensData: uccLiensDetailsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
