import { fetchAndActivate, getAll } from 'firebase/remote-config';
import { AppRemoteConfig, RemoteConfigKeys, remoteConfig } from '../../../utils/remoteConfig';


export const fetchRemoteConfig = async () => {
	if (!remoteConfig) return { success: false };
	try {
		await fetchAndActivate(remoteConfig);
		const parameters = getAll(remoteConfig);
		console.log('parametros',parameters)
		const config = {
			[RemoteConfigKeys.LSP_MACTOOL_BANNER]: parameters[RemoteConfigKeys.LSP_MACTOOL_BANNER].asString(),
		} as AppRemoteConfig;
		return {
			success: true,
			response: {
				config,
			},
		};
	} catch (error) {
		console.error(error);
		return { success: false as const, error };
	}
};
