import { PatchCommunicationPreferencesResponse, Payload } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const communicationPreferences = async (
  loanID: string,
  payload: Payload
): Promise<ApiResponse<PatchCommunicationPreferencesResponse>> => {
  try {
    const { data, status } =
      await baseUrl.patch<PatchCommunicationPreferencesResponse>(
        `loans/${loanID}/communication-preferences`,
        payload
      );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};
