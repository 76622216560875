import moment from 'moment-timezone';
import * as roundHalfEven from 'round-half-even';
import set from 'lodash/set';

import { PLACE_HOLDER } from '../components/shared/constants';
import { ActionType } from './types';

export const tz = "America/New_York";

//*************Date & Time Format Start*********/
export const DateFormat = (date: string | undefined | null | Date) => {
  if (!date || date === undefined || date === null) {
    return PLACE_HOLDER;
  }

  const dateValue = moment(date, moment.ISO_8601, true);
  if (dateValue.isValid() && date) {
    return moment(date).tz(tz).format("MM/DD/YYYY");
  } else {
    return PLACE_HOLDER;
  }
};

export const DateSortFormat = (date: string | undefined | null | Date) => {
  if (!date || date === undefined || date === null) {
    return PLACE_HOLDER;
  }

  if (date) {
    return moment(date).format("YYYY/MM/DD");
  } else {
    return PLACE_HOLDER;
  }
};

export const DateUTCFormat = (date: string | undefined | null | Date) => {
  if (!date || date === undefined || date === null) {
    return PLACE_HOLDER;
  }

  const dateValue = moment(date, moment.ISO_8601, true);
  if (dateValue.isValid() && date) {
    return moment(date).utc().format("MM/DD/YYYY");
  } else {
    return PLACE_HOLDER;
  }
};

export const DateSortUTCFormat = (date: string | undefined | null | Date) => {
  if (!date || date === undefined || date === null) {
    return PLACE_HOLDER;
  }

  if (date) {
    return moment(date).utc().format("YYYY/MM/DD");
  } else {
    return PLACE_HOLDER;
  }
};


// convert utc to est date format for form operations
export const convertUTCToEST = (date: Date | string | undefined | null) => {
  if (!date || date === undefined || date === null) {
    return null;
  }

  const dateValue = moment(date, moment.ISO_8601, true);
  if (dateValue.isValid() && date) {
    return moment(date).tz(tz).format("MM/DD/YYYY");
  } else {
    return null;
  }
};

//TimeFormat//
export const TimeFormat = (date: string) => {
  if (!date || date === undefined) {
    return PLACE_HOLDER;
  }
  return moment(date).tz(tz).format("h:mm A");
};

export const DateTimeFormat = (date: string) => {
  if (!date || date === undefined) {
    return PLACE_HOLDER;
  }
  return moment(date).tz(tz).format("MM/DD/YYYY, h:mm:ss");
};

export const DateTimeFormatA = (date: string) => {
  const dateValue = moment(date, moment.ISO_8601, true);
  if (dateValue.isValid() && date) {
    return moment(date).tz(tz).format("MM/DD/YYYY h:mm:ss A");
  } else {
    return PLACE_HOLDER;
  }
};

//Date Time with Unit
export const DateTimeUnitFormat = (date: string) => {
  const dateValue = moment(date, moment.ISO_8601, true);
  if (dateValue.isValid() && date) {
    return moment(date).tz(tz).format("MM/DD/YYYY h:mm A");
  } else {
    return PLACE_HOLDER;
  }
};

//*************Date & Time Format End*********/
export const requestBodyDateFormat = (
  date: Date | string | undefined | null
): any => {
  if (!date) {
    return "";
  }
  // get the current local Date time
  const currentTime = moment();
  const targetDate = moment(date);
  targetDate
    .set({
      hour: currentTime.get("hour"),
      minute: currentTime.get("minute"),
      second: currentTime.get("second"),
      milliseconds: currentTime.get("milliseconds"),
    })
    .tz(tz);
  const convertToUTC = moment(targetDate).utc();
  return convertToUTC.format();
};

export const DateNoConversion = (date: string | undefined | null | Date) => {
  if (!date || date === undefined || date === null) {
    return PLACE_HOLDER;
  }
  const dateValue = moment(date, moment.ISO_8601, true);
  if (dateValue.isValid() && date) {
    return moment(date).format("MM/DD/YYYY");
  } else {
    return PLACE_HOLDER;
  }
};

export const CurrencyFormat = (amount: number | undefined | string) => {
  if (typeof amount === "undefined" || amount == null) {
    return PLACE_HOLDER;
  }
  let dollars = +amount;
  dollars = roundHalfEven(dollars, 2);
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export function displayDecimalWithoutRoundingOff(number: number | string, decimalDigits: number) {
  if (!number || isNaN(Number(number))) {
    return 0;
  }

  const strNumber = number.toString();
  const decimalIndex = strNumber.indexOf('.');

  if (decimalIndex !== -1) {
    return strNumber.slice(0, decimalIndex + decimalDigits + 1);
  }

  return Number(strNumber);
}

export const LocaleStringFormat = (amount: number | undefined | string) => {
  if (typeof amount === "undefined" || amount == null) {
    return PLACE_HOLDER;
  }
  let dollars = +amount;
  dollars = roundHalfEven(dollars, 2);
  return dollars
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace("$", "");
};

export const NumberValidFormat = (amount: number | undefined | string) => {
  if (typeof amount === "undefined" || amount == null) {
    return -1;
  }
  return amount;
};

export const PercentageFormat = (value: string | number | undefined) => {
  if (typeof value === "undefined" || value == null) {
    return PLACE_HOLDER;
  }
  const parsedValue = typeof value === "string" ? Number(value) : value;
  return parsedValue.toFixed(2) + "%";
};

export const PTIPercentageFormat = (amount: number | undefined) => {
  if (typeof amount === "undefined" || amount == null) {
    return PLACE_HOLDER;
  }
  return (amount * 100).toFixed(2) + "%";
};

//two decimal number format
export const TwoDecimalFormat = (value: string | number | undefined) => {
  if (typeof value === "undefined" || value == null) {
    return PLACE_HOLDER;
  }
  const parsedValue = typeof value === "string" ? Number(value) : value;
  return parsedValue.toFixed(2);
};

// remove % format from string or number and convert to number eg: 10.00% to 10.00, 10% to 10, 10.0% to 10.00, 10.01% to 10.01, 01.01% to 1.01

export const removePercentageFormat = (
  value: string | number | undefined | null
) => {
  if (value === "" || typeof value === "undefined" || value === null) {
    return null;
  }
  const parsedValue =
    typeof value === "string" ? Number(value.replace(/%/g, "")) : value;
  return parsedValue;
};

export const formatPhoneNumber = (phoneNumberString: string | undefined) => {
  let arrPhone,
    i,
    final,
    arr = [];
  if (phoneNumberString !== undefined) {
    if (phoneNumberString?.indexOf(",") > -1) {
      arrPhone = phoneNumberString.split(",");
      for (i = 0; i < arrPhone.length; i++) {
        let cleaned = ("" + arrPhone[i]).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          arr.push("(" + match[1] + ") " + match[2] + "-" + match[3]);
        }
      }
      final = arr.toString();
    } else {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        final = "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
    }
    return final;
  }
  return PLACE_HOLDER;
};

export const hidePhoneNumber = (phoneNumber: string | undefined) => {
  let arrPhone,
    i,
    arr = [],
    prefix,
    suffix,
    nbStars,
    phone,
    formatted,
    formattedPhone;
  const prefixLength = 5;
  const suffixLength = 3;
  if (phoneNumber) {
    if (phoneNumber?.indexOf(",") > -1) {
      arrPhone = phoneNumber.split(",");
      for (i = 0; i < arrPhone.length; i++) {
        phone = formatPhoneNumber(arrPhone[i]);
        prefix = phone?.substring(0, prefixLength);
        suffix = phone?.slice(-suffixLength);
        nbStars =
          phone!?.length && phone?.length - (prefixLength + suffixLength);
        formatted =
          phone && prefix && suffix && nbStars
            ? prefix + "*".repeat(nbStars!) + suffix
            : "";
        arr.push(formatted);
      }
      formattedPhone = arr.toString();
    } else {
      phone = formatPhoneNumber(phoneNumber);
      prefix = phone?.substring(0, prefixLength);
      suffix = phone?.slice(-suffixLength);
      nbStars = phone!?.length && phone?.length - (prefixLength + suffixLength);

      formattedPhone = prefix + "*".repeat(nbStars!) + suffix;
    }
    return formattedPhone;
  }
  return PLACE_HOLDER;
};

export const CamelCase = (string: string) => {
  if (!string) return PLACE_HOLDER;
  const str = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  return str;
};

export const removeMaskNumber = (data: string) => data.replace(/\D+/g, "");

export const removeCurrencyMask = (value: string) => value.replace(/[$,]/g, "");

export const maskPhone = (number: string) => {
  const x =
    number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/) || [];
  return `(${x[1]}) ${x[2]}-${x[3]}`;
};

export const AddDates = (numberOfDaysToAdd: number) => {
  var todaysDate = new Date();
  var result = todaysDate.setDate(todaysDate.getDate() + numberOfDaysToAdd);
  return new Date(result).toDateString();
};

export const showRedDates = (txnDate: string) => {
  let count = 0;
  var startDate = new Date();
  var endDate = new Date(DateFormat(txnDate));
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  let result;
  if (count <= 2) {
    result = true;
  } else {
    result = false;
  }

  return result;
};

// regex to test amount is valid or not
export const isValidAmount = (value: string, isZeroAllowed = false) => {
  const regex = /^(?!0\.00)\d+(?: \d+)*(?:\.\d{1,2})?$/;
  let amountValue: string | number = removeCurrencyMask(value);
  if (regex.test(amountValue)) {
    amountValue = parseFloat(amountValue);
    if (!isZeroAllowed && amountValue <= 0) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

// checking if value not greater then $50,000
export const isValidLimit = (value: string) => {
  const amount = Number(value.replace(/[^0-9.-]+/g, ""));
  if (amount > 50000) {
    return false;
  }
  return true;
};

export const maskedSSN = (ssn: string) => {
  return (
    ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, 9)
  );
};

export const getSumOfNumbers = (
  num1: number | undefined | string,
  num2: number | undefined | string,
  num3: number | undefined | string
) => {
  return Number(num1 || 0) + Number(num2 || 0) + Number(num3 || 0);
};

export const payloadWithoutCurrencyMask = (
  value: string | number | undefined | null
) => {
  if (value === "" || typeof value === "undefined" || value === null) {
    return null;
  }
  const parsedValue =
    typeof value === "string" ? Number(value.replace(/[$,]/g, "")) : value;
  return parsedValue;
};

export const createActionTypes = (base: string, types: string[]) => {
	const res: ActionType = {};
	types.forEach((type) => {
		set(res, type, `${base}/${type.replace('.', '/')}`);
	});
	return res;
};

export const sanitizeMessage = (message: string) => {
  const allowedTags = ["A", "P"]; 

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = message;

  // Iterate over the child nodes
  const childNodes = Array.from(tempDiv.childNodes);

  childNodes.forEach((node) => {
    if (node.nodeType === Node.ELEMENT_NODE && !allowedTags.includes((node as HTMLElement).tagName)) {
      tempDiv.removeChild(node);
    }
  });

  return tempDiv.innerHTML;
};
