import { UCCLiensResponse } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetUCCLiens = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse<UCCLiensResponse
>> => {
  try {
    const { data, status } = await baseUrl.get<UCCLiensResponse>(
      `loans/${loanID}`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

