import {
  ReqHeaderPayment,
  ReqBodyPayment,
  GetPaymentPlanPaymentResponse,
  PostPaymentPlanPaymentResponse,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetPaymentPlanPaymentByID = async (
  reqHeader: ReqHeaderPayment
): Promise<ApiResponse<GetPaymentPlanPaymentResponse[]>> => {
  const { loanID, type, id, payment } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.get<GetPaymentPlanPaymentResponse[]>(
      `loans/${loanID}/${type}/${id}/${payment}`
    );
    return { response: data, status, success: true };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiPostPaymentPlanPayment = async (
  reqHeader: ReqHeaderPayment,
  reqBody: ReqBodyPayment
): Promise<ApiResponse<PostPaymentPlanPaymentResponse>> => {
  const { loanID, type, id, payment } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.post<PostPaymentPlanPaymentResponse>(
      `loans/${loanID}/${type}/${id}/${payment}`,
      reqBody
    );
    return { response: data, status, success: true };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};
