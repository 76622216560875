import { createSlice } from "@reduxjs/toolkit";

import {
  deletePromiseToPay,
  getPromiseToPay,
  postPromiseToPay,
  patchPromiseToPay,
  getPromiseToPayByPromiseID,
} from "./actions/promiseToPay.thunk";
import * as reducers from "./reducers/promiseToPay.reducers";
import { PromiseToPayState } from "../../../utils/types";

export const initialState: PromiseToPayState = {
  getPromiseToPay: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  postPromiseToPay: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  deletePromiseToPay: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  patchPromiseToPay: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  getPromiseToPayByPromiseID: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
};

export const PROMISE_TO_PAY_KEY = "promiseToPay";

export const promiseToPaySlice = createSlice({
  name: PROMISE_TO_PAY_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPromiseToPay.pending, reducers.getPromiseToPayPending)
      .addCase(getPromiseToPay.fulfilled, reducers.getPromiseToPayFulfilled)
      .addCase(getPromiseToPay.rejected, reducers.getPromiseToPayRejected)
      .addCase(postPromiseToPay.pending, reducers.postPromiseToPayPending)
      .addCase(postPromiseToPay.fulfilled, reducers.postPromiseToPayFulfilled)
      .addCase(postPromiseToPay.rejected, reducers.postPromiseToPayRejected)
      .addCase(deletePromiseToPay.pending, reducers.deletePromiseToPayPending)
      .addCase(
        deletePromiseToPay.fulfilled,
        reducers.deletePromiseToPayFulfilled
      )
      .addCase(deletePromiseToPay.rejected, reducers.deletePromiseToPayRejected)
      .addCase(patchPromiseToPay.pending, reducers.patchPromiseToPayPending)
      .addCase(patchPromiseToPay.fulfilled, reducers.patchPromiseToPayFulfilled)
      .addCase(patchPromiseToPay.rejected, reducers.patchPromiseToPayRejected)
      .addCase(
        getPromiseToPayByPromiseID.pending,
        reducers.getPromiseToPayByPromiseIDPending
      )
      .addCase(
        getPromiseToPayByPromiseID.fulfilled,
        reducers.getPromiseToPayByPromiseIDFulfilled
      )
      .addCase(
        getPromiseToPayByPromiseID.rejected,
        reducers.getPromiseToPayByPromiseIDRejected
      );
  },
});

/*
 * Export reducer for store configuration.
 */
export const promiseToPayReducer = promiseToPaySlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const promiseToPaySliceActions = promiseToPaySlice.actions;
