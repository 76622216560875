import { createAsyncThunk } from "@reduxjs/toolkit";

import { LoanChargeAPI } from "../../../../services";
import {
  PostChargeReqData,
  ReqHeader,
} from "../../../../services/modules/loan-charge-transactions/types";

export const postCharge = createAsyncThunk(
  `chargeTransactions/postCharge`,
  async (
    {
      reqHeader,
      reqBody,
    }: { reqHeader: ReqHeader; reqBody: PostChargeReqData },
    { rejectWithValue }
  ) => {
    const response = await LoanChargeAPI.apiAddCharge(reqHeader, reqBody);
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
