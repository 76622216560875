import {
  InputErrorMessages,
  Options,
  PaymentStatus,
  StatusConstants,
} from './types';

/**
 * Transactions Account Constants
 */
/* payment-methods --> user accounts type constant */
// ACH Account and types of accounts
export const ACH_DETAILS = "achDetails"; //"bankDetails"
export const ACH_ACCOUNT = "ACH"; //bank
export const ACH_TYPE_CHECKING = "checking"; //checking
export const ACH_TYPE_SAVINGS = "saving"; //savings

// different card and Types of Card
export const CARD_ACCOUNT = "cardDetails"; //cardDetails
export const CREDIT_CARD = "creditCard"; //creditCard
export const DEBIT_CARD = "debitCard"; //debitCard
export const CARD_TYPE_DEBIT = "debit"; //debit
export const CARD_TYPE_CREDIT = "credit"; //credit
export const DEBIT_CARD_LABEL = "Debit Card"; //cardDetails
export const CREDIT_CARD_LABEL = "Credit Card"; //creditCard
export const NOT_APPLICABLE = "Not Applicable"; //Not appllicable

//Paymemt Plan Constant
export const PAYMENT_PLAN = "Payment Plan"; //Payment Plan
export const COLLECTION_PAYMENT_PLAN = "Collection Payment Plan"; //Collection
export const SETTLEMENT_PAYMENT_PLAN = "Settlement Payment Plan"; //Settlement
export const RECOVERY_PAYMENT_PLAN = "Recovery Plan"; //Recovery
export const MONTHLY = "Monthly";
export const SEMI_MONTHLY = "Semi-Monthly";
export const TWENTY_EIGHT_DAYS = "28 Days";
export const BI_WEEKLY = "Bi-Weekly";
export const PAYMENT_TYPE_AUTOMATIC = "automatic";
export const PAYMENT_TYPE_MANUAL = "manual";

//Transactions Constant Name
export const DISBURSAL = "Disbursal";
export const PAYMENT = "Payment";
export const BILL = "Bill";
export const FEE = "Fee";
export const ADJUSTMENT = "Adjustment";
export const ADJUSTMENT_REVERSAL = "Adjustment Reversal";
export const FEE_REVERSAL = "Fee Reversal";
export const PAYMENT_REVERSAL = "Payment Reversal";
export const BILL_REVERSAL = "Bill Reversal";
export const DISBURSAL_REVERSAL = "Disbursal Reversal";

export const inputErrorMessages: InputErrorMessages = {
  adjustmentAmountValidation: "Amount must be greater than or equal to 0.01",
  adjustmentAmountDecreaseValidation:
    "Current Balances (Principal or Interest or Fee Value) cannot be negative.",
  isActivePaymentPlanMsg: `The Customer is already enrolled in payment plan program - this program needs to be cancelled or the Customer needs to be un-enrolled from existing program to create a new program.`,
  emptyRequired: " ",
  invalidInfo: "Invalid information",
  invalid: "Invalid information, try again",
  required: "Required",
  email: "Invalid email address",
  number: "Invalid number, try again",
  date: "Invalid date",
  maxDate: "Invalid date",
  minlength: "Invalid length, try again",
  maxlength: "Invalid length, try again",
  maxlengthEmpty: " ",
  minlengthEmpty: " ",
  equalTo: "Please enter the same value again.",
  blank: "This field can't be empty.",
  code: "Invalid code, try again",
  phone: "Invalid phone number",
  password: "Invalid password",
  routingNumber: "Invalid routing Number. Please enter again.",
  accountNumber: "Invalid account Number. Please enter again.",
  invalidName: "Enter valid first & last name",
  unsavedWarning: "Unsaved changes will be lost.",
  confirmDelete:
    "Are you sure you want to delete the selected transaction account?",
  confirmPromiseDelete: "Are you sure you want to delete the selected Promise?",
  confirmPaymentPlanDelete:
    "Are you sure you want to delete the selected payment plan?",
  confirmCancel: "Are you sure you want to cancel the selected transaction?",
  confirmPaymentPlanCancel:
    "Are you sure you want to cancel the selected payment plan?",
  confirmNoteDelete: "Are you sure you want to delete the selected note?",
  autopayWarning:
    "You have another account on autopay. Do you want to continue?",
  invalidMonth: "Invalid month",
  invalidYear: "Invalid year",
  invalidExpiryDate: "Invalid expiry date",
  giactFail: "The ACH routing number or account number is invalid",
  giactSuccess: "validation:success",
  invalidAccount: "Invalid Account",
  invalidAmount: "Invalid amount",
  invalidIntrestRate: "Invalid interest rate",
  invalidAmountLimit: "Amount must not be greater than $50,000",
  paymentsWarning:
    "Cannot delete, since there is a payment linked with the payment method",
  confirmDocDelete: "Are you sure you want to delete the document?",
  exactLength: (value) => `must be a ${value} digit`,
  maxLengthCustom: (value) => `Max length should be ${value}`,
  minLengthCustom: (value) => `Min length should be ${value}`,
  minMaxRangeCustom: (min, max) => `min ${min} & max ${max}`,
  minInterestRate: (value) =>
    `Please enter a interest greater than or equal to ${value}%`,
  maxInterestRate: (value) =>
    `Please enter a interest rate less than or equal to ${value}%`,
  maxAmount: (value) => `Please enter a amount less than $${value}`,
  minAmount: (value) => `Please enter a amount greater than $${value}`,
  maxAndEqualToAmount: (value) =>
    `Please enter a amount less than or equal to $${value}`,
  minAndEqualToAmount: (value) =>
    `Please enter a amount greater than or equal to $${value}`,
  defAmountValidation: (value) =>
    `Total deferral amount should be less than or equal to ${value}`,
  settlementAmountMinValidation: (value) =>
    `Settlement amount should be greater than or equal to ${value}`,
  settlementAmountMaxValidation: (value) =>
    `Settlement amount should not be greater than ${value}`,
};

export const userPermissionsConstant = {
  LOAN_CREATE: "LOAN_CREATE", // NA
  LOAN_UPDATE: "LOAN_UPDATE",
  LOAN_MODIFICATION_READ: "LOAN_MODIFICATION_READ",
  LOAN_MODIFICATION_CREATE: "LOAN_MODIFICATION_CREATE",
  LOAN_MODIFICATION_UPDATE: "LOAN_MODIFICATION_UPDATE",
  PAYMENT_METHOD_CREATE: "PAYMENT_METHOD_CREATE",
  PAYMENT_METHOD_UPDATE: "PAYMENT_METHOD_UPDATE",
  PAYMENT_METHOD_DELETE: "PAYMENT_METHOD_DELETE",
  DEBT_SALES_VIEW: "DEBT_SALES_VIEW",
  DEBT_SALES_EDIT: "DEBT_SALES_EDIT",
  DOCUMENT_CREATE: "DOCUMENT_CREATE",
  DOCUMENT_UPDATE: "DOCUMENT_UPDATE",
  DOCUMENT_DELETE: "DOCUMENT_DELETE",
  DOCUMENT_READ: "DOCUMENT_READ",
  TRANSACTION_CREATE: "TRANSACTION_CREATE",
  TRANSACTION_UPDATE: "TRANSACTION_UPDATE",
  TRANSACTION_CREATE_BACK_DATED_DUE_DATE:
    "TRANSACTION_CREATE_BACK_DATED_DUE_DATE",
  TRANSACTION_UPDATE_PMT_TXN_DATE_TIME: "TRANSACTION_UPDATE_PMT_TXN_DATE_TIME",
  REVERSE_TRANSACTION_CREATE: "REVERSE_TRANSACTION_CREATE",
  PROMO_UPDATE: "PROMO_UPDATE",
  CONFIG_READ: "CONFIG_READ",
  CONFIG_UPDATE: "CONFIG_UPDATE",
  PROMISE_TO_PAY_READ: "PROMISE_TO_PAY_READ",
  PROMISE_TO_PAY_CREATE: "PROMISE_TO_PAY_CREATE",
  PROMISE_TO_PAY_UPDATE: "PROMISE_TO_PAY_UPDATE",
  CREDIT_REPORTING_READ: "CREDIT_REPORTING_READ",
  CREDIT_REPORTING_UPDATE: "CREDIT_REPORTING_UPDATE",
  LOAN_LAST_ACCRUAL_DATE_UPDATE: "LOAN_LAST_ACCRUAL_DATE_UPDATE",
  LOAN_START_ACCRUAL_DATE_UPDATE: "LOAN_START_ACCRUAL_DATE_UPDATE",
  TRANSACTION_CREATE_SPREAD_SELECT: "TRANSACTION_CREATE_SPREAD_SELECT",
  CHARGE_OFF_DETAILS_EDIT_FUTURE_DATED_CHARGED_OFF:
    "CHARGE_OFF_DETAILS_EDIT_FUTURE_DATED_CHARGED_OFF",
  CHARGE_OFF_DETAILS_EDIT: "CHARGE_OFF_DETAILS_EDIT",
  SCRA_REPORT_READ: "SCRA_REPORT_READ",
  TESTING: "TESTING", // only for Testing
  SCHEDULE_PAYMENT_TRANSACTION_DATE_UPDATE:
    "SCHEDULE_PAYMENT_TRANSACTION_DATE_UPDATE",
  NOTES_VIEW: "NOTES_VIEW",
  NOTES_CREATE: "NOTES_CREATE",
  NOTES_UPDATE: "NOTES_UPDATE",
  NOTES_DELETE: "NOTES_DELETE",
  READ_ONLY_NO_UPDATE: "READ_ONLY_NO_UPDATE",
};

export const modalConstant = {
  DISCARD_CHANGES: "Discard Changes?",
  CONFIRM_DELETE: "Confirm Delete?",
  CONFIRM_CANCEL: "Confirm Cancel?",
  AUTOPAY_WARNING: "WARNING!",
  WARNING: "WARNING!",
  UNSAVED_CHANGES: "You have unsaved changes. Are you sure you want to leave?",
};

export const paymentStatus: PaymentStatus = {
  CLEARED: "Cleared",
  SCHEDULED: "Scheduled",
};

export const PLACE_HOLDER = "--";
export const CREDIT_CARD_PREFIX = "XXXXXXXXXXXX";
export const PPBA_PREFIX = "PPBA";

export const loanModificationTypesConst = {
  FIRST_PAYMENT_DATE_CHANGE: "First Payment Date Change",
  INTEREST_RATE_REDUCTION: "Loan Modification - Interest Rate Reduction",
  PAYMENT_DATE_CHANGE: "Loan Modification - Payment Date Change",
  PAYMENT_FREQUENCY_CHANGE: "Loan Modification - Payment Frequency Change",
  WOA_RESCHEDULE: "Workout Agreement Modification - Reschedule",
  WOA_RESCHEDULE_PRP: "Workout Agreement Modification - Reschedule w/ PRP",
  TEMPORARY_RELIEF_PAYMENT_PROGRAM: "Temporary Relief Payment Program",
  WOA_VOID_PRP: "Workout Agreement Modification – Void Reschedule w/ PRP",
  PAYMENT_METHOD_CHANGE: "Loan Modification - Payment Method Change",
  POS_RETURN: "Loan Modification - POS Return",
  SAC_PAYMENT_AMOUNT: "Loan Modification - SAC Payment Amount",
  ORIGINATION_ERROR: "Workout Agreement Modification - Origination Error",
  PAYMENT_PLAN: "Workout Agreement Modification - Payment Plan",
  RESCHEDULE_MIN_PAYMENTS:
    "Workout Agreement Modification - Reschedule w/Min. Payments",
  COLLECTION_PAYMENT_PLAN: "Payment Plan - Collection Payment Plan",
  SETTLEMENT_PAYMENT_PLAN: "Payment Plan - Settlement Payment Plan",
  RECOVERY_PLAN: "Payment Plan - Recovery Plan",
  SCRA: "SCRA",
};

export const allowedModificationTypes = [
  loanModificationTypesConst.INTEREST_RATE_REDUCTION,
  loanModificationTypesConst.PAYMENT_DATE_CHANGE,
  loanModificationTypesConst.PAYMENT_FREQUENCY_CHANGE,
  loanModificationTypesConst.WOA_RESCHEDULE,
  loanModificationTypesConst.WOA_RESCHEDULE_PRP,
  loanModificationTypesConst.FIRST_PAYMENT_DATE_CHANGE,
  loanModificationTypesConst.TEMPORARY_RELIEF_PAYMENT_PROGRAM,
  loanModificationTypesConst.SCRA,
  loanModificationTypesConst.SAC_PAYMENT_AMOUNT,
];

export const paymentFrequencyOptions: Options[] = [
  {
    label: "BI-weekly",
    value: "BI-weekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Semi-monthly",
    value: "Semi-monthly",
  },
  {
    label: "28 DAYS",
    value: "28 DAYS",
  },
];

export const frequencyChangeOptions: Options[] = [
  {
    label: "BI-weekly",
    value: "BI-weekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Semi-monthly",
    value: "Semi-monthly",
  },
];

export const toastMessages = {
  success: "Updated Successfully",
  error: "Something went wrong",
  successDelete: "Deleted Successfully",
  successCreate: "Created Successfully",
  documentSent: "Document Sent Successfully",
  modificationCancel: "Modification Canceled Successfully",
  warning: "Please enter valid data",
  modificationActivated: "Modification Activated Successfully",
  isPreview:
    "Document Generated. Please visit document section to view the generated document",
};

export const LINE_OF_CREDIT = "Line Of Credit";

export const statusConstants: StatusConstants = {
  Active: {
    label: "Active",
    values: ["Good Standing", "Past Due"],
  },
  Closed: {
    label: "Closed",
    values: [
      "Obligations Met",
      "Written Off",
      //"Not Disbursed",
      "Settled Off",
      "Sold Off",
      "Merchant - Transferred",
    ],
  },
  Canceled: {
    label: "Canceled",
    values: ["Returned", "Not Disbursed"],
  },
};

export const LOCStatusConstants: StatusConstants = {
  Active: {
    label: "Active",
    values: [
      "Line Open",
      "Line In Use",
      "Line Paused - Non Payment",
      "Line Over Limit",
      "Line Closed - Period Ended",
      "Line Closed - Non Payment",
    ],
  },
  Closed: {
    label: "Closed",
    values: ["Line Closed - Obligations Met", "Asset Sold Off"],
  },
  Canceled: {
    label: "Canceled",
    values: ["Line Cancelled by Customer", "Line Expired"],
  },
};

export const reasonOptions: Options[] = [
  { label: "APR too high", value: "APR too high" },
  { label: "Competitor offer", value: "Competitor offer" },
  { label: "Came into money", value: "Came into money" },
  {
    label: "Other",
    value: "Other",
  },
];

export const paymentTypeOptions: Options[] = [
  { label: "Minimum Amount", value: "Minimum Amount" },
  { label: "Other Amount", value: "Other Amount" },
  { label: "Past Due Payment", value: "Past Due Payments" },
  {
    label: "Promotional Financing Payment",
    value: "Promotional Financing Payment",
  },
  { label: "Payoff Amount", value: "Payoff Amount" },
  { label: "Fees", value: "Fees" },
];

export const applicationModeOptions = [
  {
    label: "Upcoming Payment",
    value: "Upcoming Payment",
  },
  {
    label: "Extra Payment",
    value: "Extra Payment",
  },
];

export const paymentTypeConstant = {
  MINIMUM_AMOUNT: "Minimum Amount",
  OTHER_AMOUNT: "Other Amount",
  PAST_DUE_PAYMENT: "Past Due Payments",
  PROMOTIONAL_FINANCING_PAYMENT: "Promotional Financing Payment",
  PAYOFF_AMOUNT: "Payoff Amount",
  FEES: "Fees",
};

export const returnInitiatedByOptions: Options[] = [
  { label: "Merchant", value: "Merchant" },
  { label: "Virtual Card", value: "Virtual Card" },
];

export const interactionCodeOptions: Options[] = [
  {
    label: "Accounting Correspondence to Collector",
    value: "Accounting Correspondence to Collector",
  },
  {
    label: "Accounting Correspondence to Manager",
    value: "Accounting Correspondence to Manager",
  },
  { label: "ACH Scheduled", value: "ACH Scheduled" },
  { label: "Active Payment Plan", value: "Active Payment Plan" },
  {
    label: "Attorney Confirmed - Customer is not retained",
    value: "Attorney Confirmed - Customer is not retained",
  },
  { label: "Attorney Obtained", value: "Attorney Obtained" },
  { label: "Bad or Wrong Number", value: "Bad or Wrong Number" },
  { label: "Balance Request", value: "Balance Request" },
  { label: "Bank Info Update", value: "Bank Info Update" },
  { label: "Bankruptcy", value: "Bankruptcy" },
  { label: "Bank Verification", value: "Bank Verification" },
  { label: "BK Chapter 11", value: "BK Chapter 11" },
  { label: "BK Chapter 12", value: "BK Chapter 12" },
  { label: "BK Chapter 13", value: "BK Chapter 13" },
  { label: "BK Chapter 7", value: "BK Chapter 7" },
  { label: "Broken Payment Plan", value: "Broken Payment Plan" },
  { label: "Busy Line", value: "Busy Line" },
  { label: "Called - No VM", value: "Called - No VM" },
  { label: "Caller Not Authorized", value: "Caller Not Authorized" },
  {
    label: "Call Inquiry General Information",
    value: "Call Inquiry General Information",
  },
  {
    label: "Call Inquiry Product Information",
    value: "Call Inquiry Product Information",
  },
  { label: "Case Created for QA/QC", value: "Case Created for QA/QC" },
  { label: "Case Created for TQM", value: "Case Created for TQM" },
  { label: "Cease and Desist", value: "Cease and Desist" },
  { label: "Closed Bank Account", value: "Closed Bank Account" },
  { label: "Collection Case Status", value: "Collection Case Status" },
  { label: "Collections Transfer", value: "Collections Transfer" },
  {
    label: "Collector to Accounting Correspondence",
    value: "Collector to Accounting Correspondence",
  },
  {
    label: "Collector to Customer Service Correspondence",
    value: "Collector to Customer Service Correspondence",
  },
  { label: "Complaint Escalation", value: "Complaint Escalation" },
  {
    label: "Contact Information Updated",
    value: "Contact Information Updated",
  },
  { label: "Correspondence", value: "Correspondence" },
  { label: "Correspondence received", value: "Correspondence received" },
  { label: "Correspondence sent", value: "Correspondence sent" },
  {
    label: "Customer Service Correspondence to Collector",
    value: "Customer Service Correspondence to Collector",
  },
  {
    label: "Customer Service Correspondence to Manager",
    value: "Customer Service Correspondence to Manager",
  },
  { label: "Customer Service Transfer", value: "Customer Service Transfer" },
  {
    label: "Debit Card Payment Declined",
    value: "Debit Card Payment Declined",
  },
  {
    label: "Debt Consolidation Company Correspondence",
    value: "Debt Consolidation Company Correspondence",
  },
  { label: "Deceased", value: "Deceased" },
  { label: "Declined Information", value: "Declined Information" },
  { label: "DMC - Active Payment Plan", value: "DMC - Active Payment Plan" },
  { label: "DMC Confirmed", value: "DMC Confirmed" },
  {
    label: "DMC Confirmed - Customer is not retained",
    value: "DMC Confirmed - Customer is not retained",
  },
  { label: "DMC - Counter Offer", value: "DMC - Counter Offer" },
  {
    label: "DMC - Settlement not accepted",
    value: "DMC - Settlement not accepted",
  },
  { label: "Do Not Contact", value: "Do Not Contact" },
  { label: "Email", value: "Email" },
  { label: "Email Customer Interaction", value: "Email Customer Interaction" },
  {
    label: "Email sent to Loan Modifications",
    value: "Email sent to Loan Modifications",
  },
  { label: "Email to Compliance", value: "Email to Compliance" },
  { label: "Email to Customer", value: "Email to Customer" },
  { label: "Email to Manager", value: "Email to Manager" },
  { label: "Employment Verification", value: "Employment Verification" },
  { label: "E-Oscar", value: "E-Oscar" },
  { label: "Escalation case to LMS", value: "Escalation case to LMS" },
  {
    label: "First Payment Default Letter Sent",
    value: "First Payment Default Letter Sent",
  },
  { label: "Follow-Up Call Scheduled", value: "Follow-Up Call Scheduled" },
  { label: "Follow Up on Items", value: "Follow Up on Items" },
  { label: "Giact - Not Verified", value: "Giact - Not Verified" },
  { label: "Giact - Verified", value: "Giact - Verified" },
  { label: "Inbound Call From DMC", value: "Inbound Call From DMC" },
  {
    label: "Inbound Call from Other than Customer",
    value: "Inbound Call from Other than Customer",
  },
  {
    label: "Intent to Accelerate Letter Sent",
    value: "Intent to Accelerate Letter Sent",
  },
  { label: "Interested Accepted Terms", value: "Interested Accepted Terms" },
  { label: "Interested - Thinking", value: "Interested - Thinking" },
  { label: "Internal Communication", value: "Internal Communication" },
  { label: "Internal Correspondence", value: "Internal Correspondence" },
  { label: "Left Message - Third Party", value: "Left Message - Third Party" },
  { label: "Left Message - Voicemail", value: "Left Message - Voicemail" },
  { label: "Left Message with Other", value: "Left Message with Other" },
  { label: "Left Voicemail", value: "Left Voicemail" },
  { label: "LMS Communication", value: "LMS Communication" },
  {
    label: "LMS Escalation – Action in Progress",
    value: "LMS Escalation – Action in Progress",
  },
  {
    label: "LMS Escalation – Escalation Resolved",
    value: "LMS Escalation – Escalation Resolved",
  },
  { label: "LMS Escalation – New Case", value: "LMS Escalation – New Case" },
  { label: "LMS Transfer", value: "LMS Transfer" },
  { label: "Loan Cancellation", value: "Loan Cancellation" },
  { label: "Loan Payoff", value: "Loan Payoff" },
  { label: "Loan Payoff Quote", value: "Loan Payoff Quote" },
  {
    label: "Loan Workout Agreement Received",
    value: "Loan Workout Agreement Received",
  },
  {
    label: "Loan Workout Agreement Saved",
    value: "Loan Workout Agreement Saved",
  },
  {
    label: "Loan Workout Agreement Sent",
    value: "Loan Workout Agreement Sent",
  },
  { label: "Manager Account Review", value: "Manager Account Review" },
  { label: "Manager Approval", value: "Manager Approval" },
  { label: "Manager Email to Collector", value: "Manager Email to Collector" },
  {
    label: "Manager Email to Customer Service Manager",
    value: "Manager Email to Customer Service Manager",
  },
  { label: "Manager Email to Director", value: "Manager Email to Director" },
  { label: "Manager email to IT", value: "Manager email to IT" },
  { label: "Manager email to Legal", value: "Manager email to Legal" },
  {
    label: "Manager email to LMS Manager",
    value: "Manager email to LMS Manager",
  },
  { label: "Manager email to QA/QC", value: "Manager email to QA/QC" },
  { label: "Manager Review", value: "Manager Review" },
  {
    label: "Manager Settlement Approved",
    value: "Manager Settlement Approved",
  },
  {
    label: "Manager to Accounting Correspondence",
    value: "Manager to Accounting Correspondence",
  },
  {
    label: "Manager to Customer Service Correspondence",
    value: "Manager to Customer Service Correspondence",
  },
  { label: "NI - APR Too High", value: "NI - APR Too High" },
  {
    label: "NI - Better Competitor Offer",
    value: "NI - Better Competitor Offer",
  },
  { label: "NI - General", value: "NI - General" },
  { label: "NI - Payment Too High", value: "NI - Payment Too High" },
  { label: "NI - Wants More Money", value: "NI - Wants More Money" },
  { label: "No Answer", value: "No Answer" },
  { label: "No Message Left", value: "No Message Left" },
  { label: "No vml / no message", value: "No vml / no message" },
  { label: "No Voice Mail Option", value: "No Voice Mail Option" },
  { label: "One Time ACH", value: "One Time ACH" },
  { label: "One Time ACH Date Change", value: "One Time ACH Date Change" },
  { label: "Outbound Call", value: "Outbound Call" },
  { label: "Outbound Call - Left VM", value: "Outbound Call - Left VM" },
  { label: "Outbound Call to Attorney", value: "Outbound Call to Attorney" },
  { label: "Outbound Call to Customer", value: "Outbound Call to Customer" },
  { label: "Outbound call to DMC-LVM", value: "Outbound call to DMC-LVM" },
  { label: "Outbound Call to POE", value: "Outbound Call to POE" },
  { label: "Outbound Email Interaction", value: "Outbound Email Interaction" },
  { label: "Outbound Email to Customer", value: "Outbound Email to Customer" },
  { label: "Pacer", value: "Pacer" },
  { label: "Payment - Extra", value: "Payment - Extra" },
  { label: "Payment Method Switch", value: "Payment Method Switch" },
  { label: "Payment Pending", value: "Payment Pending" },
  {
    label: "Payment Program - Exception",
    value: "Payment Program - Exception",
  },
  { label: "Payment Promise", value: "Payment Promise" },
  { label: "Payment Scheduled", value: "Payment Scheduled" },
  { label: "Payment - Scheduled", value: "Payment - Scheduled" },
  { label: "Payment Type Adjustment", value: "Payment Type Adjustment" },
  { label: "Permanent Date Change", value: "Permanent Date Change" },
  {
    label: "Personal Information Update",
    value: "Personal Information Update",
  },
  { label: "Phone Busy", value: "Phone Busy" },
  { label: "Phone Call Disconnected", value: "Phone Call Disconnected" },
  { label: "Phone Disconnected", value: "Phone Disconnected" },
  { label: "Phone Number Invalid", value: "Phone Number Invalid" },
  { label: "POA Received", value: "POA Received" },
  { label: "Reassigned Account", value: "Reassigned Account" },
  {
    label: "Received New Bank Account Info",
    value: "Received New Bank Account Info",
  },
  {
    label: "Received Work Out Agreement",
    value: "Received Work Out Agreement",
  },
  { label: "Refi Transfer", value: "Refi Transfer" },
  {
    label: "Refused To Address - Hang Up",
    value: "Refused To Address - Hang Up",
  },
  {
    label: "Resubmit due to NSF/stop payment",
    value: "Resubmit due to NSF/stop payment",
  },
  { label: "Returned Mail", value: "Returned Mail" },
  { label: "RPC", value: "RPC" },
  {
    label: "RPC - Attorney Communication",
    value: "RPC - Attorney Communication",
  },
  { label: "RPC - Atty", value: "RPC - Atty" },
  { label: "RPC - Authorized Contact", value: "RPC - Authorized Contact" },
  { label: "RPC- Call Back Cases", value: "RPC- Call Back Cases" },
  {
    label: "RPC - Communication with DMC",
    value: "RPC - Communication with DMC",
  },
  {
    label: "RPC - Confirmed Attorney obtained",
    value: "RPC - Confirmed Attorney obtained",
  },
  { label: "RPC - Correspondence", value: "RPC - Correspondence" },
  { label: "RPC - COVID", value: "RPC - COVID" },
  { label: "RPC - CS transfer", value: "RPC - CS transfer" },
  {
    label: "RPC - Customer Claims Fraud",
    value: "RPC - Customer Claims Fraud",
  },
  { label: "RPC - Customer Dispute", value: "RPC - Customer Dispute" },
  {
    label: "RPC - Customer inquiring about account",
    value: "RPC - Customer inquiring about account",
  },
  { label: "RPC - Debit Card Payment", value: "RPC - Debit Card Payment" },
  {
    label: "RPC - Debt Company- no funds available",
    value: "RPC - Debt Company- no funds available",
  },
  { label: "RPC - DMC", value: "RPC - DMC" },
  { label: "RPC - Due Date Change", value: "RPC - Due Date Change" },
  { label: "RPC - Email", value: "RPC - Email" },
  {
    label: "RPC - Email Customer Interaction",
    value: "RPC - Email Customer Interaction",
  },
  { label: "RPC- Email - DMC", value: "RPC- Email - DMC" },
  { label: "RPC - Email - Hardship", value: "RPC - Email - Hardship" },
  {
    label: "RPC - Email - Promise to Pay",
    value: "RPC - Email - Promise to Pay",
  },
  {
    label: "RPC - Email - Unable to Pay",
    value: "RPC - Email - Unable to Pay",
  },
  { label: "RPC - Hardship", value: "RPC - Hardship" },
  {
    label: "RPC - Hardship Communication",
    value: "RPC - Hardship Communication",
  },
  {
    label: "RPC - Inbound Call From Authorized Contact",
    value: "RPC - Inbound Call From Authorized Contact",
  },
  {
    label: "RPC - Inbound Call from Customer",
    value: "RPC - Inbound Call from Customer",
  },
  {
    label: "RPC - Inbound Customer Transfer From CSR",
    value: "RPC - Inbound Customer Transfer From CSR",
  },
  {
    label: "RPC - Inbound Email from Customer",
    value: "RPC - Inbound Email from Customer",
  },
  { label: "RPC- Inbound SMS", value: "RPC- Inbound SMS" },
  { label: "RPC - LMS Cancellation", value: "RPC - LMS Cancellation" },
  {
    label: "RPC - Loan Workout Agreement Requested",
    value: "RPC - Loan Workout Agreement Requested",
  },
  {
    label: "RPC- Member Services Email Cases",
    value: "RPC- Member Services Email Cases",
  },
  { label: "RPC - One Time ACH", value: "RPC - One Time ACH" },
  {
    label: "RPC - Outbound Call to Attorney",
    value: "RPC - Outbound Call to Attorney",
  },
  {
    label: "RPC - Outbound Call to Customer",
    value: "RPC - Outbound Call to Customer",
  },
  {
    label: "RPC - Past Due Payment Transfer From CSR",
    value: "RPC - Past Due Payment Transfer From CSR",
  },
  { label: "RPC - Payment made", value: "RPC - Payment made" },
  {
    label: "RPC - Payment Plan Agreement Confirmation",
    value: "RPC - Payment Plan Agreement Confirmation",
  },
  {
    label: "RPC - Payment Programed offered",
    value: "RPC - Payment Programed offered",
  },
  {
    label: "RPC - Payment Program Offered",
    value: "RPC - Payment Program Offered",
  },
  { label: "RPC - Payment Promise", value: "RPC - Payment Promise" },
  { label: "RPC - Payment Scheduled", value: "RPC - Payment Scheduled" },
  { label: "RPC - Payoff Quote", value: "RPC - Payoff Quote" },
  { label: "RPC - Promise", value: "RPC - Promise" },
  { label: "RPC - Promise to Pay", value: "RPC - Promise to Pay" },
  {
    label: "RPC - Received Hardship Documents",
    value: "RPC - Received Hardship Documents",
  },
  {
    label: "RPC - Received New Bank Docs",
    value: "RPC - Received New Bank Docs",
  },
  {
    label: "RPC - Received Voicemail from Customer",
    value: "RPC - Received Voicemail from Customer",
  },
  { label: "RPC - Recurring ACH", value: "RPC - Recurring ACH" },
  {
    label: "RPC - Recurring Debit Card Payments",
    value: "RPC - Recurring Debit Card Payments",
  },
  { label: "RPC - Refuses to Address", value: "RPC - Refuses to Address" },
  {
    label: "RPC - Re-Submit Scheduled Payment",
    value: "RPC - Re-Submit Scheduled Payment",
  },
  { label: "RPC - Unable to pay", value: "RPC - Unable to pay" },
  {
    label: "RPC - Waiting on Bankruptcy Paperwork",
    value: "RPC - Waiting on Bankruptcy Paperwork",
  },
  {
    label: "RPC - Waiting on Hardship Documents",
    value: "RPC - Waiting on Hardship Documents",
  },
  {
    label: "RPC - Workout Agreement Sent",
    value: "RPC - Workout Agreement Sent",
  },
  {
    label: "RPC - Written Communication",
    value: "RPC - Written Communication",
  },
  { label: "Sales Transfer", value: "Sales Transfer" },
  { label: "SCRA - Approved", value: "SCRA - Approved" },
  { label: "SCRA CONTRACT RECEIVED", value: "SCRA CONTRACT RECEIVED" },
  { label: "SCRA CONTRACT SENT", value: "SCRA CONTRACT SENT" },
  { label: "SCRA - Denied", value: "SCRA - Denied" },
  { label: "SCRA Letter Sent", value: "SCRA Letter Sent" },
  { label: "Set Callback", value: "Set Callback" },
  { label: "Settlement Accepted", value: "Settlement Accepted" },
  {
    label: "Settlement payments on file",
    value: "Settlement payments on file",
  },
  { label: "Settlement Request", value: "Settlement Request" },
  { label: "Skip Trace", value: "Skip Trace" },
  { label: "SMS - RPC", value: "SMS - RPC" },
  { label: "SMS Sent", value: "SMS Sent" },
  {
    label: "Soft Legal Demand Letter Sent",
    value: "Soft Legal Demand Letter Sent",
  },
  { label: "Status Update", value: "Status Update" },
  {
    label: "Support to Collector Communication",
    value: "Support to Collector Communication",
  },
  { label: "System Issue", value: "System Issue" },
  { label: "Ticket Created to Support", value: "Ticket Created to Support" },
  { label: "Transfer", value: "Transfer" },
  { label: "Transfer call to Collector", value: "Transfer call to Collector" },
  { label: "Transfer to Collections", value: "Transfer to Collections" },
  {
    label: "Transfer to Customer Service",
    value: "Transfer to Customer Service",
  },
  {
    label: "Transfer to Funding Specialist",
    value: "Transfer to Funding Specialist",
  },
  { label: "Transfer to Sales", value: "Transfer to Sales" },
  { label: "TT - Third Party", value: "TT - Third Party" },
  {
    label: "Unchecking the Box Due to Excessive NSF/Stop Payments",
    value: "Unchecking the Box Due to Excessive NSF/Stop Payments",
  },
  { label: "Unqualified/Declined", value: "Unqualified/Declined" },
  {
    label: "Verify applicant per Credit report",
    value: "Verify applicant per Credit report",
  },
  { label: "Verify Documents", value: "Verify Documents" },
  { label: "Voice Mailbox Full", value: "Voice Mailbox Full" },
  { label: "Voice Mailbox Not Set up", value: "Voice Mailbox Not Set up" },
  {
    label: "Voicemail - No Message Left",
    value: "Voicemail - No Message Left",
  },
  {
    label: "Workout Agreement- Exception",
    value: "Workout Agreement- Exception",
  },
  { label: "Wrong Number", value: "Wrong Number" },
];

export const adminCureReasonOptions: Options[] = [
  { label: "Customer Cancelled", value: "Customer Cancelled" },
  { label: "Customer Claims Fraud", value: "Customer Claims Fraud" },
  { label: "Language Barrier", value: "Language Barrier" },
  { label: "Not Aware of the Loan", value: "Not Aware of the Loan" },
  { label: "Payment Issues", value: "Payment Issues" },
  { label: "Services not Rendered", value: "Services not Rendered" },
  { label: "Unable To Pay", value: "Unable To Pay" },
  {
    label: "Unaware of Payment Schedule",
    value: "Unaware of Payment Schedule",
  },
  { label: "Unsatisfactory Service", value: "Unsatisfactory Service" },
];

export const communicationActivityTypes = {
  note: "Note",
  call: "Call",
  email: "Email",
};

export const GLTypes: Options[] = [
  { label: "Adjustment", value: "adjustment" },
  { label: "Adjustment Reversal", value: "Adjustment Reversal" },
  { label: "Bill", value: "bill" },
  { label: "Bill Reversal", value: "Bill Reversal" },
  { label: "Disbursal", value: "disbursal" },
  { label: "Disbursal Reversal", value: "Disbursal Reversal", },
  { label: "Fee", value: "fee" },
  { label: "Fee Reversal", value: "Fee Reversal" },
  { label: "Payment", value: "payment" },
  { label: "Payment Reversal", value: "Payment Reversal" },
];

export const GLStatus: Options[] = [
  { label: "Scheduled", value: "scheduled" },
  { label: "Cleared", value: "cleared" },
  { label: "Cleared - Not Active", value: "Cleared - Not Active" },
  { label: "Reversed", value: "reversed" },
  { label: "Resubmitted", value: "resubmitted" },
  { label: "Error", value: "Error" },
];

export const PDF_NOT_AVAILABLE = "PDF not available yet.";
