import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { patchData } from "../../services/generalService";

type ConsentResponse = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};
const initialState: ConsentResponse = {
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const postPaymentDate = createAsyncThunk(
    "loans/postPaymentDate",
    async (formData: { url: string; postsData: {} }, thunkAPI) => {
      const { url, postsData } = formData;  
        const data = await patchData(url, postsData);
        if(!data?.success) throw thunkAPI.rejectWithValue(data)
        return data;      
    }
  );

const postPaymentDateSlice = createSlice({
  name: "payments-date",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

      .addCase(postPaymentDate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPaymentDate.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(postPaymentDate.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
      });
  },
});
export default postPaymentDateSlice.reducer;
