import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  getLoanModificationsLookup,
  getLoanModifications,
  postLoanModifications,
  getDeferralAmount,
  getRecoveryScheduleItems,
  postRecoveryScheduleItems,
  postActiveRecoveryScheduleItems,
  patchLoanModifications,
  deleteLoanModifications,
  postLoanAgreement,
  postActivateLoanModifications,
  getLoanModificationSummary,
  deleteActiveLoanModification,
  getEstimatedPaymentAmount,
  postScraDetails,
  getScraDetails,
  getScraModificationInterestRate,
} from "./actions/loanModifications.thunk";
import * as reducers from "./reducers/loanModifications.reducers";
import { LoanModificationsState } from "../../../utils/types";

export const initialState: LoanModificationsState = {
  modificationsGetLookup: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  loanModificationsGetDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  loanModificationsPostDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  loanModificationsPatchDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  activeLoanModificationsDeleteDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  loanModificationsDeleteDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  deferralAmountGetDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  recoveryScheduleItemsGetDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  recoveryScheduleItemsPostDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  activeRecoveryScheduleItemsPostDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  loanAgreementPostDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  activateLoanAgreementDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  loanModificationGetSummary: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  modificationDetailsByID: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
  estimatedPaymentAmountGetDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  scraDetails: {
    data: undefined,
    error: undefined,
    loadingStatus:"not loaded"
  },
  scraGetDetails: {
    data: undefined,
    error: undefined,
    loadingStatus: "not loaded"
  },
  scraIntRate: {
    data: undefined,
    error: undefined,
    loadingStatus: "not loaded"
  }
};

export const LOAN_MODIFICATIONS_KEY = "loanModifications";

export const loanModificationsSlice = createSlice({
  name: LOAN_MODIFICATIONS_KEY,
  initialState,
  reducers: {
    getDetailsByModificationId: (state, action: PayloadAction<string>) => {
      state.modificationDetailsByID.loadingStatus = "loading";
      const { payload } = action;
      const modifcationById =
        state?.loanModificationsGetDetails?.data?.response?.filter(
          (item) => item.commonName === payload
        );
      if (modifcationById) {
        state.modificationDetailsByID.loadingStatus = "loaded";
        state.modificationDetailsByID.data = modifcationById[0];
      } else {
        state.modificationDetailsByID.loadingStatus = "error";
        state.modificationDetailsByID.error = "No data found";
      }
    },
    clearModificationDetailsById: (state) => {
      state.modificationDetailsByID.data = null;
      state.modificationDetailsByID.loadingStatus = "not loaded";
      state.modificationDetailsByID.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLoanModificationsLookup.pending,
        reducers.getLoanModificationsLookupPending
      )
      .addCase(
        getLoanModificationsLookup.fulfilled,
        reducers.getLoanModificationsLookupFulfilled
      )
      .addCase(
        getLoanModificationsLookup.rejected,
        reducers.getLoanModificationsLookupRejected
      )
      .addCase(
        getLoanModifications.pending,
        reducers.getLoanModificationsPending
      )
      .addCase(
        getLoanModifications.fulfilled,
        reducers.getLoanModificationsFulfilled
      )
      .addCase(
        getLoanModifications.rejected,
        reducers.getLoanModificationsRejected
      )
      .addCase(
        postLoanModifications.pending,
        reducers.postLoanModificationsPending
      )
      .addCase(
        postLoanModifications.fulfilled,
        reducers.postLoanModificationsFulfilled
      )
      .addCase(
        postLoanModifications.rejected,
        reducers.postLoanModificationsRejected
      )
      .addCase(
        patchLoanModifications.pending,
        reducers.patchLoanModificationsPending
      )
      .addCase(
        patchLoanModifications.fulfilled,
        reducers.patchLoanModificationsFulfilled
      )
      .addCase(
        patchLoanModifications.rejected,
        reducers.patchLoanModificationsRejected
      )
      .addCase(
        deleteLoanModifications.pending,
        reducers.deleteLoanModificationsPending
      )
      .addCase(
        deleteLoanModifications.fulfilled,
        reducers.deleteLoanModificationsFulfilled
      )
      .addCase(
        deleteLoanModifications.rejected,
        reducers.deleteLoanModificationsRejected
      )
      .addCase(getDeferralAmount.pending, reducers.getDeferralAmountPending)
      .addCase(getDeferralAmount.fulfilled, reducers.getDeferralAmountFulfilled)
      .addCase(getDeferralAmount.rejected, reducers.getDeferralAmountRejected)
      .addCase(
        getRecoveryScheduleItems.pending,
        reducers.getRecoveryScheduleItemsPending
      )
      .addCase(
        getRecoveryScheduleItems.fulfilled,
        reducers.getRecoveryScheduleItemsFulfilled
      )
      .addCase(
        getRecoveryScheduleItems.rejected,
        reducers.getRecoveryScheduleItemsRejected
      )
      .addCase(
        postRecoveryScheduleItems.pending,
        reducers.postRecoveryScheduleItemsPending
      )
      .addCase(
        postRecoveryScheduleItems.fulfilled,
        reducers.postRecoveryScheduleItemsFulfilled
      )
      .addCase(
        postRecoveryScheduleItems.rejected,
        reducers.postRecoveryScheduleItemsRejected
      )
      .addCase(
        postActiveRecoveryScheduleItems.pending,
        reducers.postActiveRecoveryScheduleItemsPending
      )
      .addCase(
        postActiveRecoveryScheduleItems.fulfilled,
        reducers.postActiveRecoveryScheduleItemsFulfilled
      )
      .addCase(
        postActiveRecoveryScheduleItems.rejected,
        reducers.postActiveRecoveryScheduleItemsRejected
      )
      .addCase(postLoanAgreement.pending, reducers.postLoanAgreementPending)
      .addCase(postLoanAgreement.fulfilled, reducers.postLoanAgreementFulfilled)
      .addCase(postLoanAgreement.rejected, reducers.postLoanAgreementRejected)
      .addCase(
        postActivateLoanModifications.pending,
        reducers.activateLoanAgreementPending
      )
      .addCase(
        postActivateLoanModifications.fulfilled,
        reducers.activateLoanAgreementFulfilled
      )
      .addCase(
        postActivateLoanModifications.rejected,
        reducers.activateLoanAgreementRejected
      )
      .addCase(
        getLoanModificationSummary.pending,
        reducers.getLoanModificationSummaryPending
      )
      .addCase(
        getLoanModificationSummary.fulfilled,
        reducers.getLoanModificationSummaryFulfilled
      )
      .addCase(
        getLoanModificationSummary.rejected,
        reducers.getLoanModificationSummaryRejected
      )
      .addCase(
        deleteActiveLoanModification.pending,
        reducers.deleteActiveModificationPending
      )
      .addCase(
        deleteActiveLoanModification.fulfilled,
        reducers.deleteActiveModificationFulfilled
      )
      .addCase(
        deleteActiveLoanModification.rejected,
        reducers.deleteActiveModificationRejected
      )
      .addCase(
        getEstimatedPaymentAmount.pending,
        reducers.getEstimatedPaymentAmountPending
      )
      .addCase(
        getEstimatedPaymentAmount.fulfilled,
        reducers.getEstimatedPaymentAmountFulfilled
      )
      .addCase(
        getEstimatedPaymentAmount.rejected,
        reducers.getEstimatedPaymentAmountRejected
      )
      .addCase(
        postScraDetails.pending,
        reducers.postScraDetailsPending
      )
      .addCase(
        postScraDetails.fulfilled,
        reducers.postScraDetailsFulfilled
      )
      .addCase(
        postScraDetails.rejected,
        reducers.postScraDetailsRejected
      )
      .addCase(
        getScraDetails.pending,
        reducers.getScraDetailsPending
      )
      .addCase(
        getScraDetails.fulfilled,
        reducers.getScraDetailsFulfilled
      )
      .addCase(
        getScraDetails.rejected,
        reducers.getScraDetailsRejected
      )
      .addCase(
        getScraModificationInterestRate.pending,
        reducers.getScraModificationInterestRatePending
      )
      .addCase(
        getScraModificationInterestRate.fulfilled,
        reducers.getScraModificationInterestRateFulfilled
      )
      .addCase(
        getScraModificationInterestRate.rejected,
        reducers.getScraModificationInterestRateRejected
      )
  },
});

// export reducer for store configuration.
export const loanModificationsReducer = loanModificationsSlice.reducer;

// export user reducer action creators
export const { getDetailsByModificationId, clearModificationDetailsById } =
  loanModificationsSlice.actions;
