import { MsalProvider } from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "./global.scss";
import { msalInstance } from "./auth/authConfig";
import store from "./redux/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <BrowserRouter
        basename={process.env.REACT_APP_HOSTING_SUB_DIRECTORY_PATH}
      >
        <App />
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  </MsalProvider>
);
