import { createSlice } from "@reduxjs/toolkit";

import {
  deletePaymentMethodByAssociationId,
  getPaymentMethodsByLoanId,
  patchPaymentMethodByAssociationId,
  postPaymentMethodByLoanId,
  getAutoPayReasons
} from "./actions/paymentMethod.thunk";
import * as reducers from "./reducers/paymentMethod.reducers";
import { PaymentMethodsState } from "../../../utils/types";

export const initialState: PaymentMethodsState = {
  data: null,
  PaymentMethodByAssociationId: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  isAddPaymentMethodStatus: "not loaded",
  isDeletePaymentMethodStatus: "not loaded",
  isEditPaymentMethodStatus: "not loaded",
  getAutoPayReasons: undefined,
  getAutoPayReasonsStatus: "not loaded"
};

export const PAYMENT_METHOD_KEY = "paymentMethod";

export const paymentMethodSlice = createSlice({
  name: PAYMENT_METHOD_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethodsByLoanId.pending, reducers.pending)
      .addCase(getPaymentMethodsByLoanId.fulfilled, reducers.fulfilled)
      .addCase(getPaymentMethodsByLoanId.rejected, reducers.rejected)
      .addCase(getAutoPayReasons.pending, reducers.autoPayReasonsPending)
      .addCase(getAutoPayReasons.fulfilled, reducers.autoPayReasonsFulfilled)
      .addCase(getAutoPayReasons.rejected, reducers.autoPayReasonsRejected)
      .addCase(postPaymentMethodByLoanId.pending,
        reducers.postPaymentMethodByLoanIdPending
      )
      .addCase(
        postPaymentMethodByLoanId.fulfilled,
        reducers.postPaymentMethodByLoanIdFulfilled
      )
      .addCase(
        postPaymentMethodByLoanId.rejected,
        reducers.postPaymentMethodByLoanIdRejected
      )
      .addCase(
        patchPaymentMethodByAssociationId.pending,
        reducers.patchPaymentMethodPending
      )
      .addCase(
        patchPaymentMethodByAssociationId.fulfilled,
        reducers.patchPaymentMethodFulfilled
      )
      .addCase(
        patchPaymentMethodByAssociationId.rejected,
        reducers.patchPaymentMethodRejected
      )
      .addCase(
        deletePaymentMethodByAssociationId.pending,
        reducers.deletePaymentMethodPending
      )
      .addCase(
        deletePaymentMethodByAssociationId.fulfilled,
        reducers.deletePaymentMethodFulfilled
      )
      .addCase(
        deletePaymentMethodByAssociationId.rejected,
        reducers.deletePaymentMethodRejected
      );
  },
});

/*
 * Export reducer for store configuration.
 */
export const paymentMethodReducer = paymentMethodSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const paymentMethodSliceActions = paymentMethodSlice.actions;
