import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { PaymentPlanState } from "../../../../utils/types";

// Get PaymentPlan
export const getPaymentPlanPending = (state: PaymentPlanState) => {
  state.isLoading = true;
  state.data = null;
  state.isError = false;
  state.isSuccess = false;
};

export const getPaymentPlanFulfilled = (
  state: PaymentPlanState,
  action: PayloadAction<
    Types.ApiResponse<Types.PaymentPlanTypes.GetPaymentPlanResponse[]>
  >
) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = action.payload.success;
  state.data = action.payload.response;
};

export const getPaymentPlanRejected = (state: PaymentPlanState) => {
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
};

//Post Payment Plan
export const postPaymentPlanPending = (state: PaymentPlanState) => {
  state.isLoading = true;
};
export const postPaymentPlanFulfilled = (
  state: PaymentPlanState,
  action: PayloadAction<
    Types.ApiResponse<Types.PaymentPlanTypes.PostPaymentPlanResponse>
  >
) => {
  state.isLoading = false;
  state.isSuccess = action.payload.success;
  // state.data = action.payload.response;
};

export const postPaymentPlanRejected = (state: PaymentPlanState) => {
  state.isLoading = false;
  state.isSuccess = false;
};

//Patch Payment Plan
export const patchPaymentPlanPending = (state: PaymentPlanState) => {
  state.isLoading = true;
};
export const patchPaymentPlanFulfilled = (
  state: PaymentPlanState,
  action: PayloadAction<
    Types.ApiResponse<Types.PaymentPlanTypes.PatchPaymentPlanResponse>
  >
) => {
  state.isLoading = false;
  state.isSuccess = action.payload.success;
  // state.data = action.payload.response;
};

export const patchPaymentPlanRejected = (state: PaymentPlanState) => {
  state.isLoading = false;
  state.isSuccess = false;
};

//Delete Payment Plan
export const deletePaymentPlanPending = (state: PaymentPlanState) => {
  state.isLoading = true;
};
export const deletePaymentPlanFulfilled = (
  state: PaymentPlanState,
  action: PayloadAction<
    Types.ApiResponse<Types.PaymentPlanTypes.DeletePaymentPlanResponse>
  >
) => {
  state.isLoading = false;
  state.isSuccess = action.payload.success;
  // state.data = action.payload.response;
};

export const deletePaymentPlanRejected = (state: PaymentPlanState) => {
  state.isLoading = false;
  state.isSuccess = false;
};
