import { createAsyncThunk } from "@reduxjs/toolkit";

import { PaymentPlanAPI, Types } from "../../../../services";

export const getPaymentPlan = createAsyncThunk(
  `paymentPlan/get`,
  async (
    {
      reqHeader,
    }: {
      reqHeader: Types.PaymentPlanTypes.ReqHeader;
    },
    { rejectWithValue }
  ) => {
    const response = await PaymentPlanAPI.apiGetPaymentPlan(reqHeader);
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const postPaymentPlan = createAsyncThunk(
  `paymentPlan/post`,
  async (
    {
      reqHeader,
      reqBody,
    }: {
      reqHeader: Types.PaymentPlanTypes.ReqHeader;
      reqBody: Types.PaymentPlanTypes.ReqBody;
    },
    { rejectWithValue }
  ) => {
    const response = await PaymentPlanAPI.apiPostPaymentPlan(
      reqHeader,
      reqBody
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const patchPaymentPlan = createAsyncThunk(
  `paymentPlan/patch`,
  async (
    {
      reqHeader,
      reqBody,
    }: {
      reqHeader: Types.PaymentPlanTypes.ReqHeader;
      reqBody: Types.PaymentPlanTypes.ReqBodyPatch;
    },
    { rejectWithValue }
  ) => {
    const response = await PaymentPlanAPI.apiPatchPaymentPlan(
      reqHeader,
      reqBody
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const deletePaymentPlan = createAsyncThunk(
  `paymentPlan/delete`,
  async (
    {
      reqHeader,
    }: {
      reqHeader: Types.PaymentPlanTypes.ReqHeader;
    },
    { rejectWithValue }
  ) => {
    const response = await PaymentPlanAPI.apiDeletePaymentPlan(reqHeader);
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
