import { createSlice } from "@reduxjs/toolkit";

import {
  getRecoverySchedule,
} from "./actions/recoverySchedule.thunk";
import * as reducers from "./reducers/recoverSchedule.reducers";
import { RecoveryScheduleItemsByLoanIdResponse } from "../../../utils/types";

export const initialState: RecoveryScheduleItemsByLoanIdResponse = {
  recoveryScheduleItemsGet: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
};

export const RECOVERYSCHEDULE__ITEMS_KEY = "recoveryScheduleItemsByLoanId";

export const recoveryScheduleSlice = createSlice({
  name: RECOVERYSCHEDULE__ITEMS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecoverySchedule.pending, reducers.recoveryScheduleGetPending)
      .addCase(getRecoverySchedule.fulfilled, reducers.recoveryScheduleGetFulfilled)
      .addCase(getRecoverySchedule.rejected, reducers.recoveryScheduleGetRejected)
  },
});

/*
 * Export reducer for store configuration.
 */
export const recoveryScheduleReducer = recoveryScheduleSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const recoveryScheduleSliceActions = recoveryScheduleSlice.actions;
