import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRoles } from "../../../../services";

export const getUserRoles = createAsyncThunk(
  `userRoles/getRoles`,
  async (_: void, { rejectWithValue }) => {
    const response = await UserRoles.apiGetUserRoles();
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
