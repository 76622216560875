import {
  PostChargeReqData,
  ReqHeader,
  TransactionsPostingResponse,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiAddCharge = async (
  reqHeader: ReqHeader,
  reqBody: PostChargeReqData
): Promise<ApiResponse<TransactionsPostingResponse>> => {
  try {
    const { data, status } = await baseUrl.post<TransactionsPostingResponse>(
      `loans/${reqHeader.id}/charges`,
      reqBody
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
