import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { BillsPdfState } from "../../../../utils/types";

export const pending = (state: BillsPdfState) => {
  state.isLoading = true;
  state.data = null;
};

export const fulfilled = (
  state: BillsPdfState,
  action: PayloadAction<
    Types.ApiResponse<Types.BillsPdfTypes.BillsPdfResponse>
  >
) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = action.payload.success;
  state.data = action.payload.response;
};

export const rejected = (state: BillsPdfState) => {
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
};