import { BatchTransactionsResponse, ReqBody, ReqHeader } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const batchTransactions = async (
  reqHeader: ReqHeader,
  reqBody: ReqBody
): Promise<ApiResponse<BatchTransactionsResponse>> => {
  const { batch, type } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.post<BatchTransactionsResponse>(
      `loans/${batch}/${type}`,
      reqBody
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};
