import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getDevData } from "../../services/generalService";
import { RelatedLoansDetailsObj } from "../../utils/types";

const initialState: RelatedLoansDetailsObj = {
  datas: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getRelatedLoansData = createAsyncThunk(
  "loans/getRelatedLoans",
  async (url: string, thunkAPI) => {
    try {
      const data = await getDevData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const relatedLoansSlice = createSlice({
  name: "relatedLoans",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRelatedLoansData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRelatedLoansData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.datas = action.payload;
      })
      .addCase(getRelatedLoansData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.datas = null;
      });
  },
});

export default relatedLoansSlice.reducer;
