import { createSlice } from "@reduxjs/toolkit";

export interface SidebarState {
  isSubSidebarOpen: boolean;
  sidebarItem?: number;
  secondSidebarItem?: string;
}

const initialState: SidebarState = {
  isSubSidebarOpen: false,
  sidebarItem: 0,
  secondSidebarItem: "",
};

export const sidebarSlice = createSlice({
  name: "Sidebar",
  initialState,
  reducers: {
    //checking second navbar isopen
    setIsSubSidebarOpen: (state, action) => {
      state.isSubSidebarOpen = action.payload;
    },
    //setting the first navbar selected menu
    setSidebarItem: (state, action) => {
      state.sidebarItem = action.payload;
    },
    //setting second sidebar selected menu
    setSecondSidebarItem: (state, action) => {
      state.secondSidebarItem = action.payload;
    },
  },
});

export const { setIsSubSidebarOpen, setSidebarItem, setSecondSidebarItem } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
