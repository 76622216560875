import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import style from "./auth.module.scss";
import AccessDeniedPage from "./components/errors/accessDenied";
import ErrorPage from "./components/errors/error";
import NotFoundPage from "./components/errors/notFound";
import AppLoading from "./components/shared/loader/AppLoading";
import { getLoanDetails } from "./redux/modules/loan-details";
import { getUserRoles } from "./redux/modules/user-roles";
import { getBorrowersData } from "./redux/reducers";
import { useAppDispatch, useAppSelector } from "./redux/store";
const DocumentsView = React.lazy(
  () => import("./components/activityView/documents/documentsScreen")
);
const LoanDetailsView = React.lazy(
  () => import("./components/activityView/loan-history/loanDetailsScreen")
);

const RepaymentMgmt = React.lazy(
  () => import("./components/repayment/repayment")
);

const CallEmailActivity = React.lazy(
  () =>
    import(
      "./components/activityView/call-mail-activity/CallEmailActivityScreen"
    )
);

const NotFound = React.lazy(() => import("./components/errors/notFound"));

const GeneralLedger = React.lazy(
  () => import("./components/accounting/generalLedger/generalLedgerScreen")
);

const Accounts = React.lazy(
  () => import("./components/accounting/accounts/accountsScreen")
);

const LoanScheduleDynamic = React.lazy(
  () => import("./components/accounting/loanSchedule/loanScheduleScreenDynamic")
);

const TransactionFeesSet = React.lazy(
  () =>
    import(
      "./components/configuration/transaction/fee-set/fees.set.configuration"
    )
);

const Adjustment = React.lazy(
  () => import("./components/servicing/adjustment/adjustment")
);
const Disbursal = React.lazy(
  () => import("./components/servicing/disbursal/disbursalScreen")
);

const Charge = React.lazy(
  () => import("./components/servicing/charge/charge.screen")
);

const Payment = React.lazy(
  () => import("./components/servicing/payments/payment.screen")
);

const Collections = React.lazy(
  () =>
    import("./components/servicing/bulkUpdate/collections/collections.screen")
);
const Finance = React.lazy(
  () => import("./components/servicing/bulkUpdate/finance/finance.screen")
);
const VigilantMonitoring = React.lazy(
  () =>
    import("./components/repayment/vigilantMonitoring/vigilantMonitoringScreen")
);

const LoanDetail = React.lazy(() => import("./components/loan/Details"));

const LoanModifications = React.lazy(
  () => import("./components/loan-modifications/LoanModifications")
);

const TransactionSummary = React.lazy(
  () =>
    import(
      "./components/servicing/print-document/transaction-summary/transactionSummary.screen"
    )
);

const AccountStatement = React.lazy(
  () =>
    import(
      "./components/servicing/print-document/account-statement/accountStatement.screen"
    )
);
const PayOffQuote = React.lazy(
  () =>
    import(
      "./components/servicing/print-document/payoff-quote/payOffQuote.screen"
    )
);
const AmortizationSchedule = React.lazy(
  () =>
    import(
      "./components/servicing/print-document/amortization-schedule/amortizationSchedule.screen"
    )
);
const LandingPage = React.lazy(() => import("./components/landingPage"));

const SearchResults = React.lazy(
  () => import("./components/search-result/SearchResultsScreen")
);

const Routers: React.FC = () => {
  const dispatch = useAppDispatch();
  const { externalID, loanID } = sessionStorage || {};
  const userPermissions = useAppSelector((state) => state.userRoles?.data);
  const loanDetails = useAppSelector((state) => state.loanDetails);
  const [isLoanID, setIsLoanID] = React.useState<boolean>(false);
  const { id } = loanDetails?.data || {};
  const { pathname } = useLocation();
  const OPPORTUNITY_URL = `loans/${loanID}/borrowers`;

  useEffect(() => {
    if (id) {
      sessionStorage.setItem("loanID", id);
      setIsLoanID(true);
    }
  }, [id]);

  // add user permission to local storage
  useEffect(() => {
    if (userPermissions && userPermissions.length > 0) {
      localStorage.setItem("userPermission", JSON.stringify(userPermissions));
    }
  }, [userPermissions]);

  useEffect(() => {
    const pathArr = pathname?.split("/") || [];
    const pathIndexForLoans =
      pathArr?.findIndex((item) => item?.toLowerCase() === "loans") + 1;

    const fetchCurrentTerms = async () => {
      await dispatch(
        getLoanDetails({
          externalID: pathArr[pathIndexForLoans || 2] || externalID,
        })
      );
    };
    fetchCurrentTerms();
  }, []);

  useEffect(() => {
    if (isLoanID) {
      dispatch(getBorrowersData(OPPORTUNITY_URL));
    }
  }, [isLoanID]);

  // fetch permissions on initial app load
  useEffect(() => {
    const fetchUserRoles = async () => {
      await dispatch(getUserRoles());
    };
    fetchUserRoles();
  }, [dispatch]);

  if (!isLoanID && !loanDetails.isError) {
    return (
      <div className={style["auth-loader"]}>
        <AppLoading />
      </div>
    );
  }

  if (!loanDetails?.isLoading && loanDetails.isError) {
    const { statusCode } = loanDetails?.errorStatus || {};
    if (statusCode === 404) return <NotFoundPage />;
    if (statusCode === 403) return <AccessDeniedPage />;
    return <ErrorPage />;
  }

  return (
    <Suspense
      fallback={
        <div className={style["auth"]}>
          <FontAwesomeIcon icon={faSpinner} pulse size="6x" />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/loans/" component={LandingPage} />
        {/* please don't add protected route to loan id */}
        <Route exact path="/loans/:externalID" component={LoanDetail} />
        <Route
          exact
          path="/loans/:externalID/activity-view/documents"
          component={DocumentsView}
        />
        <Route
          exact
          path="/loans/:externalID/activity-view/call-email-activity"
          component={CallEmailActivity}
        />
        <Route
          exact
          path="/loans/:externalID/activity-view/loan-details"
          component={LoanDetailsView}
        />
        <Route
          exact
          path="/loans/:externalID/accounting/transaction-accounts"
          component={Accounts}
        />
        <Route
          exact
          path="/loans/:externalID/accounting/general-ledger"
          component={GeneralLedger}
        />

        <Route
          exact
          path="/loans/:externalID/accounting/loan-schedule/"
          component={LoanScheduleDynamic}
        />
        <Route
          exact
          path="/loans/:externalID/repayment-mgmt"
          component={RepaymentMgmt}
        />
        <Route
          exact
          path="/loans/:externalID/configuration/transaction-feeset"
          component={TransactionFeesSet}
        />
        <Route
          exact
          path="/loans/:externalID/servicing/adjustment"
          component={Adjustment}
        />
        <Route
          path="/loans/:externalID/servicing/disbursal"
          component={Disbursal}
        />
        <Route
          exact
          path="/loans/:externalID/servicing/charge"
          component={Charge}
        />
        <Route
          exact
          path="/loans/:externalID/servicing/payment"
          component={Payment}
        />

        <Route
          exact
          path="/loans/:externalID/servicing/collections"
          component={Collections}
        />
        <Route
          exact
          path="/loans/:externalID/servicing/finance"
          component={Finance}
        />
        <Route
          exact
          path="/loans/:externalID/vigilant-monitoring"
          component={VigilantMonitoring}
        />
        <Route
          exact
          path="/loans/:externalID/loan-modifications"
          component={LoanModifications}
        />
        <Route
          exact
          path="/loans/:externalID/loan-modifications/:modificationsID"
          component={LoanModifications}
        />

        <Route
          exact
          path="/loans/:externalID/servicing/print-document/transaction-summary"
          component={TransactionSummary}
        />
        <Route
          exact
          path="/loans/:externalID/servicing/print-document/account-statement"
          component={AccountStatement}
        />
        <Route
          exact
          path="/loans/:externalID/servicing/print-document/payoff-quote"
          component={PayOffQuote}
        />
        <Route
          exact
          path="/loans/:externalID/servicing/print-document/amortization-schedule"
          component={AmortizationSchedule}
        />
        <Route
          exact
          path="/loans/:externalID/search-results"
          component={SearchResults}
        />

        <Route exact path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routers;
