// Api base url
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Api mock url
export const API_MOCK_URL = process.env.REACT_APP_API_MOCK_URL;

// Current environment
export const ENV = process.env.REACT_APP_ENV;

// API Auth key
export const API_AUTH_KEY = process.env.REACT_APP_API_AUTH_KEY;

// Environment
export const DEV_LOCAL = "DEV_LOCAL";
export const DEV = "DEV";
export const PRODUCTION = "PRODUCTION";
export const TESTING = "TESTING";

export const FIREBASE_WEB_CONFIG = {
	apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_WEB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_WEB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_WEB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_WEB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_WEB_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_WEB_MEASUREMENT_ID,
};

