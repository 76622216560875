import { PatchPromoResponse } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiPatchPromo = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse> => {
  try {
    const { status } = await baseUrl.patch<PatchPromoResponse[]>(
      `loans/${loanID}/reinstate-promotion`
    );
    return {
      status,
      success: status === 204,
    };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};
