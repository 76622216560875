import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { BulkDataState } from "../../../../utils/types";

// Bulk Data Update Post Reducers //
export const bulkDataUpdatePending = (state: BulkDataState) => {
  state.bulkUpdatePostResponse.loadingStatus = "loading";
  state.bulkUpdatePostResponse.data = undefined;
  state.bulkUpdatePostResponse.error = undefined;
};

export const bulkDataUpdateFulfilled = (
  state: BulkDataState,
  action: PayloadAction<
    Types.ApiResponse<Types.BulkDataTypes.PostBulkDataResponse>
  >
) => {
  state.bulkUpdatePostResponse.loadingStatus = "loaded";
  state.bulkUpdatePostResponse.data = action.payload;
};

export const bulkDataUpdateRejected = (
  state: BulkDataState,
  action: any
) => {
  state.bulkUpdatePostResponse.loadingStatus = "error";
  state.bulkUpdatePostResponse.error = action.payload || action.error;
};


export const PreSignedURLPending = (state: BulkDataState) => {
  state.bulkUpdatePostResponse.loadingStatus = "loading";
  state.bulkUpdatePostResponse.data = undefined;
  state.bulkUpdatePostResponse.error = undefined;
};

export const PreSignedURLFulfilled = (
  state: BulkDataState,
  action: PayloadAction<
    Types.ApiResponse<Types.BulkDataTypes.PresignedResponse>
  >
) => {
  state.bulkUpdatePostResponse.loadingStatus = "loaded";
  state.preSignedResponse.data = action.payload;
};

export const PreSignedURLRejected = (
  state: BulkDataState,
  action: any
) => {
  state.bulkUpdatePostResponse.loadingStatus = "error";
  state.bulkUpdatePostResponse.error = action.payload || action.error;
};
