import {
  LoanAdjustmentReqData,
  ReqHeader,
  TransactionsPostingResponse,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiAddLoanAdjustments = async (
  reqHeader: ReqHeader,
  reqBody: LoanAdjustmentReqData
): Promise<ApiResponse<TransactionsPostingResponse>> => {
  try {
    const { data, status } = await baseUrl.post<TransactionsPostingResponse>(
      `loans/${reqHeader.id}/loan-adjustments`,
      reqBody
    );
    return {
      response: data,
      status,
      success: !!Object.keys(data).length || status === 201,
    };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
