// create loan modification lookup selector
import { createSelector } from "@reduxjs/toolkit";

import { EditLoanModificationFormValues } from "../../../../components/loan-modifications/modifications-management/formHelpers";
import { allowedModificationTypes } from "../../../../components/shared/constants";
import { convertUTCToEST, CurrencyFormat } from "../../../../utils/helpers";
import { LoanModificationsState } from "../../../../utils/types";
import { RootState } from "../../../store";
import { LOAN_MODIFICATIONS_KEY } from "../loanModifications.slice";

// Get loan modification lookup state
export const getLoanModificationsState = (
  rootState: RootState
): LoanModificationsState => rootState[LOAN_MODIFICATIONS_KEY];

// select loan modification lookup loading status
export const selectModificationsLookupLoadingStatus = createSelector(
  getLoanModificationsState,
  (loanModificationsState) =>
    loanModificationsState.modificationsGetLookup.loadingStatus
);

// export loan modifications call status
export const selectCreateModificationsStatus = createSelector(
  getLoanModificationsState,
  (loanModificationsState) =>
    loanModificationsState.loanModificationsPostDetails.loadingStatus
);

/**
 * modifications Made lookup data and sort it
 * alphabetically
 * @param {LoanModificationsState}
 * @returns {Array<{label: string, value: string}>}
 */
export const selectModificationsMade = createSelector(
  getLoanModificationsState,
  (loanModificationsState) => {
    const lookupData =
      loanModificationsState?.modificationsGetLookup?.data?.response?.[0];
    const modificationsMadeOptions = lookupData?.modificationsMade || [];
    return modificationsMadeOptions.map((madeOptions) => ({
      label: madeOptions,
      value: madeOptions,
    }));
  }
);

/**
 * modifications Type lookup data and sort it
 * alphabetically
 * @param {LoanModificationsState}
 * @returns {Array<{label: string, value: string}>}
 */
export const selectModificationsType = createSelector(
  getLoanModificationsState,
  (loanModificationsState) => {
    const lookupData =
      loanModificationsState?.modificationsGetLookup?.data?.response?.[0];
    const modificationsTypeOptions = lookupData?.modificationType || [];
    return modificationsTypeOptions
      .filter((item) => allowedModificationTypes.includes(item))
      .map((typeOptions) => ({
        label: typeOptions,
        value: typeOptions,
      }));
  }
);

/**
 * modifications Reason lookup data and sort it
 * alphabetically
 * @param {LoanModificationsState}
 * @returns {Array<{label: string, value: string}>}
 */
export const selectModificationsReason = createSelector(
  getLoanModificationsState,
  (loanModificationsState) => {
    const lookupData =
      loanModificationsState?.modificationsGetLookup?.data?.response?.[0];
    const modicationsReasonOptions = lookupData?.modificationReason || [];
    return modicationsReasonOptions.map((reasonOptions) => ({
      label: reasonOptions,
      value: reasonOptions,
    }));
  }
);

// select loan modifications filterdata return all expect modificaiton type === "Payment Plan"
export const selectLoanModificationsFilterData = createSelector(
  getLoanModificationsState,
  (loanModificationsState) => {
    const modificationsData =
      loanModificationsState?.loanModificationsGetDetails?.data?.response;
    if (modificationsData && modificationsData.length > 0) {
      return modificationsData
        .filter((item) => item.modificationType !== "Payment Plan")
        .sort(
          (modificationA, modificationB) =>
            modificationB.loanModificationId - modificationA.loanModificationId
        );
    }
    return [];
  }
);
// select edit-form default data for selected modification id
export const selectLoanModificationsEditFormData = createSelector(
  getLoanModificationsState,
  (loanModificationsState): EditLoanModificationFormValues | null => {
    const modificationsData = loanModificationsState?.modificationDetailsByID;
    if (
      modificationsData?.loadingStatus === "loaded" &&
      modificationsData?.data
    ) {
      const data = modificationsData.data;
      const {
        modificationType,
        loanModificationId,
        modificationsMade,
        modificationReason,
        status,
        details,
        recoveryScheduleId,
        deferralStartDate,
        deferralEndDate,
      } = data;
      const {
        paymentDate,
        interestRate,
        deferralGoodFaithAmount,
        deferralMonths,
        numberOfInstallments,
        repaymentStartDate,
        paymentFrequency,
        dueDate,
        paymentAmount,
        activeDutyDate,
        dmdcVerificationDate,
        methodOfNotification,
        notificationToCustomerDate,
        lastRateChangeDate,
        dateBenefitDenied,
        denialReason,
      } = details || {};

      const createOptionField = (value: string | number | null | undefined) => {
        if (typeof value === "number") {
          value = value.toString();
        }
        return value ? { label: value, value } : null;
      };

      return {
        modificationsID: loanModificationId,
        recoveryScheduleId,
        modificationType: createOptionField(modificationType),
        modificationMade: createOptionField(modificationsMade),
        modificationReason: createOptionField(modificationReason),
        status,
        interestRate: interestRate?.toString() ?? "",
        deferralGoodFaithAmount: CurrencyFormat(deferralGoodFaithAmount),
        deferralMonths: createOptionField(deferralMonths),
        numberOfInstallments,
        paymentFrequency: createOptionField(paymentFrequency),
        paymentDate: paymentDate ? convertUTCToEST(paymentDate) : null,
        dueDate: dueDate ? convertUTCToEST(dueDate) : null,
        repaymentStartDate: repaymentStartDate
          ? convertUTCToEST(repaymentStartDate)
          : null,
        paymentAmount: CurrencyFormat(paymentAmount),
        deferralStartDate: deferralStartDate
          ? convertUTCToEST(deferralStartDate)
          : null,
        deferralEndDate: deferralEndDate
          ? convertUTCToEST(deferralEndDate)
          : null,
        activeDutyDate: activeDutyDate ? convertUTCToEST(activeDutyDate) : null,
        dmdcVerificationDate: dmdcVerificationDate
          ? convertUTCToEST(dmdcVerificationDate)
          : null,
        methodOfNotification: createOptionField(methodOfNotification),
        notificationToCustomerDate: notificationToCustomerDate
          ? convertUTCToEST(notificationToCustomerDate)
          : null,
        lastRateChangeDate: lastRateChangeDate
          ? convertUTCToEST(lastRateChangeDate)
          : null,
        dateBenefitDenied: dateBenefitDenied
          ? convertUTCToEST(dateBenefitDenied)
          : null,
        denialReason: createOptionField(denialReason),
      };
    }
    return null;
  }
);
