import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getconfigData,
  putProductConfigData,
} from "../../../../../services/generalService";
import { ProductConfigDetailsObj } from "../../../../../utils/types";

const initialState: ProductConfigDetailsObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getProductConfigDetailsData = createAsyncThunk(
  "config/getProductConfigDetailsData",
  async (url: string, thunkAPI) => {
    try {
      const data = await getconfigData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const putProductConfigDetails = createAsyncThunk(
  "config/putProductConfigDetailsData",
  async (reqData: any, thunkAPI) => {
    const { url, key, body } = reqData;
    try {
      const data = await putProductConfigData(url, key, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const productConfigDetailsSlice = createSlice({
  name: "productConfig",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductConfigDetailsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductConfigDetailsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getProductConfigDetailsData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      })
      // put //
      .addCase(putProductConfigDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(putProductConfigDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        // state.data = action.payload;
      })
      .addCase(putProductConfigDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default productConfigDetailsSlice.reducer;
