import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { LoanModificationsState } from "../../../../utils/types";

// Get LoanModificationsLookup Reducers
export const getLoanModificationsLookupPending = (
  state: LoanModificationsState
) => {
  state.modificationsGetLookup.loadingStatus = "loading";
  state.modificationsGetLookup.error = undefined;
  state.modificationsGetLookup.data = undefined;
};

export const getLoanModificationsLookupFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<
      Types.LoanModificationsTypes.LoanModificationLookupResponse[]
    >
  >
) => {
  state.modificationsGetLookup.loadingStatus = "loaded";
  state.modificationsGetLookup.data = action.payload;
};

export const getLoanModificationsLookupRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.modificationsGetLookup.loadingStatus = "error";
  state.modificationsGetLookup.error = action.payload || action.error;
};

// Get Loan modifications details Reducers
export const getLoanModificationsPending = (state: LoanModificationsState) => {
  state.loanModificationsGetDetails.loadingStatus = "loading";
  state.loanModificationsGetDetails.error = undefined;
  state.loanModificationsGetDetails.data = undefined;
};

export const getLoanModificationsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationsResponse[]>
  >
) => {
  state.loanModificationsGetDetails.loadingStatus = "loaded";
  state.loanModificationsGetDetails.data = action.payload;
};

export const getLoanModificationsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.loanModificationsGetDetails.loadingStatus = "error";
  state.loanModificationsGetDetails.error = action.payload || action.error;
};

// Post Loan modifications details Reducers
export const postLoanModificationsPending = (state: LoanModificationsState) => {
  state.loanModificationsPostDetails.loadingStatus = "loading";
  state.loanModificationsPostDetails.error = undefined;
  state.loanModificationsPostDetails.data = undefined;
};

export const postLoanModificationsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationPostResponse>
  >
) => {
  state.loanModificationsPostDetails.loadingStatus = "loaded";
  state.loanModificationsPostDetails.data = action.payload;
};

export const postLoanModificationsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.loanModificationsPostDetails.loadingStatus = "error";
  state.loanModificationsPostDetails.error = action.payload || action.error;
};

// Patch Loan modifications details Reducers
export const patchLoanModificationsPending = (
  state: LoanModificationsState
) => {
  state.loanModificationsPatchDetails.loadingStatus = "loading";
  state.loanModificationsPatchDetails.error = undefined;
  state.loanModificationsPatchDetails.data = undefined;
};

export const patchLoanModificationsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationPostResponse>
  >
) => {
  state.loanModificationsPatchDetails.loadingStatus = "loaded";
  state.loanModificationsPatchDetails.data = action.payload;
};

export const patchLoanModificationsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.loanModificationsPatchDetails.loadingStatus = "error";
  state.loanModificationsPatchDetails.error = action.payload || action.error;
};

// Delete Loan modifications details Reducers
export const deleteLoanModificationsPending = (
  state: LoanModificationsState
) => {
  state.loanModificationsPatchDetails.loadingStatus = "loading";
  state.loanModificationsPatchDetails.error = undefined;
  state.loanModificationsPatchDetails.data = undefined;
};

export const deleteLoanModificationsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationDeleteResponse>
  >
) => {
  state.loanModificationsPatchDetails.loadingStatus = "loaded";
  state.loanModificationsPatchDetails.data = action.payload;
};

export const deleteLoanModificationsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.loanModificationsPatchDetails.loadingStatus = "error";
  state.loanModificationsPatchDetails.error = action.payload || action.error;
};

// Get Deferral Amount details Reducers
export const getDeferralAmountPending = (state: LoanModificationsState) => {
  state.deferralAmountGetDetails.loadingStatus = "loading";
  state.deferralAmountGetDetails.error = undefined;
  state.deferralAmountGetDetails.data = undefined;
};

export const getDeferralAmountFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.DeferralAmountResponse>
  >
) => {
  state.deferralAmountGetDetails.loadingStatus = "loaded";
  state.deferralAmountGetDetails.data = action.payload;
};

export const getDeferralAmountRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.deferralAmountGetDetails.loadingStatus = "error";
  state.deferralAmountGetDetails.error = action.payload || action.error;
};

// Get Recovery Schedule Items Reducers
export const getRecoveryScheduleItemsPending = (
  state: LoanModificationsState
) => {
  state.recoveryScheduleItemsGetDetails.loadingStatus = "loading";
  state.recoveryScheduleItemsGetDetails.error = undefined;
  state.recoveryScheduleItemsGetDetails.data = undefined;
};

export const getRecoveryScheduleItemsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<
      Types.LoanModificationsTypes.RecoveryScheduleItemsResponse[]
    >
  >
) => {
  state.recoveryScheduleItemsGetDetails.loadingStatus = "loaded";
  state.recoveryScheduleItemsGetDetails.data = action.payload;
};

export const getRecoveryScheduleItemsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.recoveryScheduleItemsGetDetails.loadingStatus = "error";
  state.recoveryScheduleItemsGetDetails.error = action.payload || action.error;
};

// Post Recovery Schedule Items Reducers
export const postRecoveryScheduleItemsPending = (
  state: LoanModificationsState
) => {
  state.recoveryScheduleItemsPostDetails.loadingStatus = "loading";
  state.recoveryScheduleItemsPostDetails.error = undefined;
  state.recoveryScheduleItemsPostDetails.data = undefined;
};

export const postRecoveryScheduleItemsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.RecoveryScheduleItemsPostResponse>
  >
) => {
  state.recoveryScheduleItemsPostDetails.loadingStatus = "loaded";
  state.recoveryScheduleItemsPostDetails.data = action.payload;
};

export const postRecoveryScheduleItemsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.recoveryScheduleItemsPostDetails.loadingStatus = "error";
  state.recoveryScheduleItemsPostDetails.error = action.payload || action.error;
};

// Post Active Recovery Schedule Items Reducers
export const postActiveRecoveryScheduleItemsPending = (
  state: LoanModificationsState
) => {
  state.activeRecoveryScheduleItemsPostDetails.loadingStatus = "loading";
  state.activeRecoveryScheduleItemsPostDetails.error = undefined;
  state.activeRecoveryScheduleItemsPostDetails.data = undefined;
};

export const postActiveRecoveryScheduleItemsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.RecoveryScheduleItemsPostResponse>
  >
) => {
  state.activeRecoveryScheduleItemsPostDetails.loadingStatus = "loaded";
  state.activeRecoveryScheduleItemsPostDetails.data = action.payload;
};

export const postActiveRecoveryScheduleItemsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.activeRecoveryScheduleItemsPostDetails.loadingStatus = "error";
  state.activeRecoveryScheduleItemsPostDetails.error =
    action.payload || action.error;
};

// Post Loan Modifications Agreement Reducers
export const postLoanAgreementPending = (state: LoanModificationsState) => {
  state.loanAgreementPostDetails.loadingStatus = "loading";
  state.loanAgreementPostDetails.error = undefined;
  state.loanAgreementPostDetails.data = undefined;
};

export const postLoanAgreementFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<Types.ApiResponse<{ id: string }>>
) => {
  state.loanAgreementPostDetails.loadingStatus = "loaded";
  state.loanAgreementPostDetails.data = action.payload;
};

export const postLoanAgreementRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.loanAgreementPostDetails.loadingStatus = "error";
  state.loanAgreementPostDetails.error = action.payload || action.error;
};

// Activate Loan Modifications Agreement Reducers
export const activateLoanAgreementPending = (state: LoanModificationsState) => {
  state.activateLoanAgreementDetails.loadingStatus = "loading";
  state.activateLoanAgreementDetails.error = undefined;
  state.activateLoanAgreementDetails.data = undefined;
};

export const activateLoanAgreementFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<Types.ApiResponse>
) => {
  state.activateLoanAgreementDetails.loadingStatus = "loaded";
  state.activateLoanAgreementDetails.data = action.payload;
};

export const activateLoanAgreementRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.activateLoanAgreementDetails.loadingStatus = "error";
  state.activateLoanAgreementDetails.error = action.payload || action.error;
};

// Get: Loan modification Summary reducer
export const getLoanModificationSummaryPending = (
  state: LoanModificationsState
) => {
  state.loanModificationGetSummary.loadingStatus = "loading";
  state.loanModificationGetSummary.error = undefined;
  state.loanModificationGetSummary.data = undefined;
};

export const getLoanModificationSummaryFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.LoanModificationSummaryResponse>
  >
) => {
  state.loanModificationGetSummary.loadingStatus = "loaded";
  state.loanModificationGetSummary.data = action.payload;
};

export const getLoanModificationSummaryRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.loanModificationGetSummary.loadingStatus = "error";
  state.loanModificationGetSummary.error = action.payload || action.error;
};

// Delete : Active Loan modification
export const deleteActiveModificationPending = (
  state: LoanModificationsState
) => {
  state.activeLoanModificationsDeleteDetails.loadingStatus = "loading";
  state.activeLoanModificationsDeleteDetails.error = undefined;
};

export const deleteActiveModificationFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<Types.ApiResponse>
) => {
  state.activeLoanModificationsDeleteDetails.loadingStatus = "loaded";
  state.activeLoanModificationsDeleteDetails.data = action.payload;
};

export const deleteActiveModificationRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.activeLoanModificationsDeleteDetails.loadingStatus = "error";
  state.activeLoanModificationsDeleteDetails.error =
    action.payload || action.error;
};

// Get Estimate Payment Amount
export const getEstimatedPaymentAmountPending = (
  state: LoanModificationsState
) => {
  state.estimatedPaymentAmountGetDetails.loadingStatus = "loading";
  state.estimatedPaymentAmountGetDetails.error = undefined;
  state.estimatedPaymentAmountGetDetails.data = undefined;
};

export const getEstimatedPaymentAmountFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.EstimatedPaymentAmountResponse>
  >
) => {
  state.estimatedPaymentAmountGetDetails.loadingStatus = "loaded";
  state.estimatedPaymentAmountGetDetails.data = action.payload;
};

export const getEstimatedPaymentAmountRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.estimatedPaymentAmountGetDetails.loadingStatus = "error";
  state.estimatedPaymentAmountGetDetails.error = action.payload || action.error;
};


// Post SCRA details Reducers
export const postScraDetailsPending = (state: LoanModificationsState) => {
  state.scraDetails.loadingStatus = "loading";
  state.scraDetails.error = undefined;
  state.scraDetails.data = undefined;
};

export const postScraDetailsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.SCRADetails>
  >
) => {
  state.scraDetails.loadingStatus = "loaded";
  state.scraDetails.data = action.payload;
};

export const postScraDetailsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.scraDetails.loadingStatus = "error";
  state.scraDetails.error = action.payload || action.error;
};

// Get SCRA details Reducers
export const getScraDetailsPending = (state: LoanModificationsState) => {
  state.scraGetDetails.loadingStatus = "loading";
  state.scraGetDetails.error = undefined;
  state.scraGetDetails.data = undefined;
};

export const getScraDetailsFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.SCRAResponse>
  >
) => {
  state.scraGetDetails.loadingStatus = "loaded";
  state.scraGetDetails.data = action.payload;
};

export const getScraDetailsRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.scraGetDetails.loadingStatus = "error";
  state.scraGetDetails.error = action.payload || action.error;
};

// Get SCRA interest rate Reducers
export const getScraModificationInterestRatePending = (state: LoanModificationsState) => {
  state.scraIntRate.loadingStatus = "loading";
  state.scraIntRate.error = undefined;
  state.scraIntRate.data = undefined;
};

export const getScraModificationInterestRateFulfilled = (
  state: LoanModificationsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanModificationsTypes.SCRAInterestRate[]>
  >
) => {
  state.scraIntRate.loadingStatus = "loaded";
  state.scraIntRate.data = action.payload;
};

export const getScraModificationInterestRateRejected = (
  state: LoanModificationsState,
  action: any
) => {
  state.scraIntRate.loadingStatus = "error";
  state.scraIntRate.error = action.payload || action.error;
};