import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { postData } from "../../services/generalService";
import { CreateNotesResponseObj } from "../../utils/types";

const initialState: CreateNotesResponseObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const postNotes = createAsyncThunk(
  "loans/postNotes",
  async (formData: { url: string; postsData: {} }, thunkAPI) => {
    const { url, postsData } = formData;
    try {
      const data = await postData(url, postsData, true);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

const postNotesSlice = createSlice({
  name: "postNotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(postNotes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default postNotesSlice.reducer;
