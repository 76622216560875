import { createSlice } from "@reduxjs/toolkit";

import { postCharge } from "./actions/chargeTransactions.thunk";
import * as reducers from "./reducers/chargeTransactions.reducers";
import { TransactionPostingState } from "../../../utils/types";

export const initialState: TransactionPostingState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const CHARGE_TRANSACTIONS_KEY = "chargeTransactions";

export const chargeTransactionsSlice = createSlice({
  name: CHARGE_TRANSACTIONS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postCharge.pending, reducers.pending)
      .addCase(postCharge.fulfilled, reducers.fulfilled)
      .addCase(postCharge.rejected, reducers.rejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const chargeTransactionsReducer = chargeTransactionsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const chargeTransactionsSliceActions = chargeTransactionsSlice.actions;
