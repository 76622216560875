import {
  PostNotePayload,
  GetCommunicationActivity,
  PatchNotePayload,
  GetNoteById,
} from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetCommunicaitonsActivity = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse<GetCommunicationActivity[]>> => {
  try {
    const { data, status } = await baseUrl.get<GetCommunicationActivity[]>(
      `loans/${loanID}/communications-activity`
    );

    return {
      response: data,
      status: status,
      success: !!Object.keys(data).length,
    };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiPostNote = async ({
  loanID,
  payload,
}: {
  loanID: string;
  payload: PostNotePayload;
}): Promise<ApiResponse<{ id: string }>> => {
  try {
    const { data, status } = await baseUrl.post<{ id: string }>(
      `loans/${loanID}/notes`,
      payload
    );
    return {
      response: data,
      status,
      success: status === 201 || !!Object.keys(data).length,
    };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiGetNoteById = async ({
  loanID,
  noteID,
}: {
  loanID: string;
  noteID: string;
}): Promise<ApiResponse<GetNoteById>> => {
  try {
    const { data, status } = await baseUrl.get<GetNoteById>(
      `loans/${loanID}/notes/id/${noteID}`
    );
    return {
      response: data,
      status,
      success: !!Object.keys(data).length,
    };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiPatchNote = async ({
  loanID,
  noteID,
  payload,
}: {
  loanID: string;
  noteID: string;
  payload: PatchNotePayload;
}): Promise<ApiResponse> => {
  try {
    const { status } = await baseUrl.patch(
      `loans/${loanID}/notes/${noteID}`,
      payload
    );
    return {
      status,
      success: status === 204,
    };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const apiDeleteNote = async ({
  loanID,
  noteID,
}: {
  loanID: string;
  noteID: string;
}): Promise<ApiResponse> => {
  try {
    const { status } = await baseUrl.delete(`loans/${loanID}/notes/${noteID}`);
    return {
      status,
      success: status === 204,
    };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};
