import { createSlice } from "@reduxjs/toolkit";

import { getDisbursalAccounts } from "./actions/disbursalAccounts.thunk";
import * as reducers from "./reducers/disbursalAccounts.reducers";
import { DisbursalAccountsState } from "../../../utils/types";

export const initialState: DisbursalAccountsState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const DISBURSAL_ACCOUNTS_KEY = "disbursalAccounts";

export const disbursalAccountsSlice = createSlice({
  name: DISBURSAL_ACCOUNTS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDisbursalAccounts.pending, reducers.pending)
      .addCase(getDisbursalAccounts.fulfilled, reducers.fulfilled)
      .addCase(getDisbursalAccounts.rejected, reducers.rejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const disbursalAccountsReducer = disbursalAccountsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const disbursalAccountsSliceActions = disbursalAccountsSlice.actions;
