import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetBillsPdf = async ({
  billID,
}: {
  billID: number;
}): Promise<ApiResponse<BinaryData
>> => {
  try {
    const { data, status } = await baseUrl.get<BinaryData>(
      `loans/bill/${billID}/download`,
      {
        responseType: "arraybuffer",
        responseEncoding: 'binary',
        headers:{
          "Content-Type":"application/pdf",
        }
      }
    );
    return { response: data, status, success: status === 200 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};