import { combineReducers } from 'redux';

import remoteConfigReducer from './remoteConfig.reducer';

const sharedReducer = combineReducers({
	remoteConfig: remoteConfigReducer,
});

export default sharedReducer;

export type SharedState = ReturnType<typeof sharedReducer>;
