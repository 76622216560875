import Spinner from "react-bootstrap/Spinner";

interface Props {}

const AppLoading: React.FC<Props> = (props) => {
  return (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default AppLoading;
