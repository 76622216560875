import { createSlice } from "@reduxjs/toolkit";

import {
  deleteCommunicationNotes,
  getCommunicationsActivity,
  patchCommunicationNotes,
  postCommunicationNotes,
  getCommunicationNoteById,
} from "./actions/communicationsActivity.thunk";
import * as reducers from "./reducers/communicationsActivity.reducers";
import { CommunicationActivityState } from "../../../utils/types";

const initialState: CommunicationActivityState = {
  getDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  postNoteDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  getNoteDetailsById: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  patchNoteDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
  deleteNoteDetails: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
};

export const COMMUNICATIONS_ACTIVITY_KEY = "communicationsActivity";

const communicationsActivitySlice = createSlice({
  name: COMMUNICATIONS_ACTIVITY_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCommunicationsActivity.pending,
        reducers.getCommunicationActivityPending
      )
      .addCase(
        getCommunicationsActivity.fulfilled,
        reducers.getCommunicationActivityFullfilled
      )
      .addCase(
        getCommunicationsActivity.rejected,
        reducers.getCommunicationActivityRejected
      )
      .addCase(
        postCommunicationNotes.pending,
        reducers.postCommunicationNotesPending
      )
      .addCase(
        postCommunicationNotes.fulfilled,
        reducers.postCommunicationNotesFullfilled
      )
      .addCase(
        postCommunicationNotes.rejected,
        reducers.postCommunicationNotesRejected
      )
      .addCase(
        patchCommunicationNotes.pending,
        reducers.patchCommunicationNotesPending
      )
      .addCase(
        patchCommunicationNotes.fulfilled,
        reducers.patchCommunicationNotesFullfilled
      )
      .addCase(
        patchCommunicationNotes.rejected,
        reducers.patchCommunicationNotesRejected
      )
      .addCase(
        deleteCommunicationNotes.pending,
        reducers.deleteCommunicationNotesPending
      )
      .addCase(
        deleteCommunicationNotes.fulfilled,
        reducers.deleteCommunicationNotesFullfilled
      )
      .addCase(
        deleteCommunicationNotes.rejected,
        reducers.deleteCommunicationNotesRejected
      )
      .addCase(
        getCommunicationNoteById.pending,
        reducers.getCommunicationNoteByIdPending
      )
      .addCase(
        getCommunicationNoteById.fulfilled,
        reducers.getCommunicationNoteByIdFullfilled
      )
      .addCase(
        getCommunicationNoteById.rejected,
        reducers.getCommunicationNoteByIdRejected
      );
  },
});

// export reducer for store configuration.
export const communicationsActivityReducer =
  communicationsActivitySlice.reducer;

// export user reducer action creators
export const communicationsActivityAction = communicationsActivitySlice.actions;
