import { EditTransactionsResponse, ReqBody, ReqHeader } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const editTransactions = async (
  reqHeader: ReqHeader,
  reqBody: ReqBody
): Promise<ApiResponse<EditTransactionsResponse>> => {
  const { id, type } = reqHeader || {};
  try {
    const { data, status } = await baseUrl.patch<EditTransactionsResponse>(
      `loans/${id}/${type}`,
      reqBody
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};
