import { createAsyncThunk } from "@reduxjs/toolkit";

import { CommunicationPreferencesAPI, Types } from "../../../../services";

export const patchCommunicationPreferences = createAsyncThunk(
  `communicationPreferences/patch`,
  async (
    {
      loanID,
      payload,
    }: {
      loanID: string;
      payload: Types.CommunicationPreferencesTypes.Payload;
    },
    { rejectWithValue }
  ) => {
    const response = await CommunicationPreferencesAPI.communicationPreferences(
      loanID,
      payload
    );
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
