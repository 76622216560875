import { createSlice } from "@reduxjs/toolkit";

import { patchCommunicationPreferences } from "./actions/communicationPreferences.thunk";
import * as reducers from "./reducers/communicationPreferences.reducers";
import { CommunicationsState } from "../../../utils/types";

export const initialState: CommunicationsState = {
  patchCommunicationPreferences: {
    loadingStatus: "not loaded",
    error: undefined,
    data: undefined,
  },
};

export const COMMUNICATION_PERFRENCES_KEY = "communicationPrefrences";

export const communicationPrefrencesSlice = createSlice({
  name: COMMUNICATION_PERFRENCES_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        patchCommunicationPreferences.pending,
        reducers.patchCommunicationReferencesPending
      )
      .addCase(
        patchCommunicationPreferences.fulfilled,
        reducers.patchCommunicationReferencesFulfilled
      )
      .addCase(
        patchCommunicationPreferences.rejected,
        reducers.patchCommunicationReferencesRejected
      );
  },
});

/*
 * Export reducer for store configuration.
 */
export const communicationPrefrencesReducer =
  communicationPrefrencesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const communicationPrefrencesSliceActions =
  communicationPrefrencesSlice.actions;
