import axios, { AxiosResponse } from "axios";

import { API_BASE_URL, API_AUTH_KEY } from "./tools/constants";
import {
  AddPaymentRequestBody,
  ApiResponse,
  handleSubModuleError,
} from "../utils/types";

// Development URL
export const baseUrl = axios.create({
  baseURL: `${API_BASE_URL}`,
});

//get data from server
export const getData = async (url: string): Promise<any> => {
  const { data }: AxiosResponse<any, any> = await baseUrl.get(url);
  return data;
};

export const postMockData = async (url: string, postdata: any) => {
  try {
    const { data }: AxiosResponse<any, any> = await baseUrl.post(url, postdata);
    return data;
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const getDevData = async (
  url: string,
  serving: boolean = false
): Promise<any> => {
  const { data }: AxiosResponse<any, any> = await baseUrl.get(url);
  return data;
};

//post data
export const postData = async (url: string, postdata: any, dev = false) => {
  try {
    const response: AxiosResponse<any, any> = await baseUrl.post(url, postdata);

    return response?.data;
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const postDataPayments = async (
  url: string,
  postdata: any,
  dev = false
) => {
  try {
    const response: AxiosResponse<any, any> = await baseUrl.post(url, postdata);

    return response;
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

//update or patch data
export const putData = async (url: string, postdata: any) => {
  try {
    const response: AxiosResponse<any, any> = await baseUrl.put(url, postdata);

    return response?.data;
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

//delete data from server
export const deletData = (url: string) => {
  return baseUrl.delete(url);
};

//patch data
export const patchData = async (url: string, postdata: any) => {
  try {
    const response: AxiosResponse<any, any> = await baseUrl.patch(
      url,
      postdata
    );
    return { response, success: true };
  } catch (error: any) {
    return handleSubModuleError(error);
  }
};

export const getPartnerDetails = async (url: string): Promise<any> => {
  const response: AxiosResponse<any, any> = await baseUrl.get(url);
  return response;
};

//product configuration data
export const getconfigData = async (url: string): Promise<any> => {
  const { data }: AxiosResponse<any, any> = await baseUrl.get(url);
  return data;
};

// Product configuration Put //
export const putProductConfigData = async (
  url: string,
  key: string,
  postData: AddPaymentRequestBody
): Promise<ApiResponse> => {
  try {
    const { data, status }: AxiosResponse<any, any> = await baseUrl.put(
      url + "?key=" + API_AUTH_KEY,
      postData
    );
    return { response: data.payload, success: status === 204, status };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
