import { getResponseRecoverySchedule } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools";
import { ApiResponse } from "../../tools/types";



export const apiGetRecoverySchedule = async ({
    loanID,
  }: {
    loanID: string;
  }): Promise<ApiResponse<getResponseRecoverySchedule[]>> => {
    try {
      const { data, status } = await baseUrl.get<getResponseRecoverySchedule[]>(
        `loans/${loanID}/recovery-schedule-items`
      );
      return { response: data, status, success: !!Object.keys(data).length };
    } catch (error) {
      return handleSubModuleError(error);
    }
  };
  