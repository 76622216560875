import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { postDataPayments } from "../../services/generalService";
import { PaymentTransactionObj } from "../../utils/types";

const initialState: PaymentTransactionObj = {
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const postPaymentTransaction = createAsyncThunk(
  "loans/postPaymentTransaction",
  async (formData: { url: string; postsData: {} }, thunkAPI) => {
    const { url, postsData } = formData;
    try {
      const response = await postDataPayments(url, postsData, true);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

const postPaymentTransactionSlice = createSlice({
  name: "postPaymentTransaction",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(postPaymentTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPaymentTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(postPaymentTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default postPaymentTransactionSlice.reducer;
