import { PaymentSpread } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetPaymentSpreads = async (): Promise<
  ApiResponse<PaymentSpread[]>
> => {
  try {
    const { data, status } = await baseUrl.get<PaymentSpread[]>(
      `loans/payment-spreads`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
