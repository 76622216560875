import { createSlice } from "@reduxjs/toolkit";

import {
  getPaymentPlan,
  postPaymentPlan,
  deletePaymentPlan,
  patchPaymentPlan,
} from "./actions/paymentPlan.thunk";
import * as reducers from "./reducers/paymentPlan.reducers";
import { PaymentPlanState } from "../../../utils/types";

export const initialState: PaymentPlanState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const PAYMENT_PLAN_KEY = "paymentPlan";

export const paymentPlanSlice = createSlice({
  name: PAYMENT_PLAN_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentPlan.pending, reducers.getPaymentPlanPending)
      .addCase(getPaymentPlan.fulfilled, reducers.getPaymentPlanFulfilled)
      .addCase(getPaymentPlan.rejected, reducers.getPaymentPlanRejected)
      .addCase(postPaymentPlan.pending, reducers.postPaymentPlanPending)
      .addCase(postPaymentPlan.fulfilled, reducers.postPaymentPlanFulfilled)
      .addCase(postPaymentPlan.rejected, reducers.postPaymentPlanRejected)
      .addCase(patchPaymentPlan.pending, reducers.patchPaymentPlanPending)
      .addCase(patchPaymentPlan.fulfilled, reducers.patchPaymentPlanFulfilled)
      .addCase(patchPaymentPlan.rejected, reducers.patchPaymentPlanRejected)
      .addCase(deletePaymentPlan.pending, reducers.deletePaymentPlanPending)
      .addCase(deletePaymentPlan.fulfilled, reducers.deletePaymentPlanFulfilled)
      .addCase(deletePaymentPlan.rejected, reducers.deletePaymentPlanRejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const paymentPlanReducer = paymentPlanSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const paymentPlanSliceActions = paymentPlanSlice.actions;
