import {
  AuthenticationResult,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import { AxiosError, AxiosRequestConfig } from "axios";
import React, { FC } from "react";
import { useLocation } from "react-router";

import { loginRequest } from "./authConfig";
import { baseUrl as moduleBaseUrl } from "../services";
import { baseUrl } from "../services/generalService";

interface RequestInterceptorProps {
  children: JSX.Element;
}

const RequestInterceptor: FC<RequestInterceptorProps> = ({ children }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { pathname } = useLocation();

  // request interceptor for api calls
  const requestInterceptor = async (config: AxiosRequestConfig) => {
    if (!account) {
      throw Error("No active account! Verify a user has been signed in.");
    }

    // initialize isIdTokenExpired to false and response to null
    let isIdTokenExpired = false;
    let response: AuthenticationResult | null = null;

    // acquire token silently
    try {
      response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        response = await instance.acquireTokenPopup({
          ...loginRequest,
          account,
        });
      }
    }

    // check if idToken is expired or not and force refresh if expired
    const exp = response?.account?.idTokenClaims?.exp;
    if (exp) {
      const now = new Date().getTime() / 1000;
      isIdTokenExpired = exp < now;

      // acquire token silently if isIdTokenExpired is true
      if (isIdTokenExpired) {
        try {
          response = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
            forceRefresh: true,
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            response = await instance.acquireTokenPopup({
              ...loginRequest,
              account,
            });
          }
        }
      }
      if (response && response?.accessToken) {
        //check already stored in localstorage
        let accessToken = sessionStorage.getItem("accessToken");
        if (!accessToken || accessToken === null || accessToken === undefined) {
          sessionStorage.setItem("accessToken", response?.accessToken);
        }
      }
      // for user's - id-token need to be passed on. Note:- Avoid accessToken
      const bearer = `Bearer ${response?.idToken}`;
      if (config.headers) {
        config.headers.Authorization = bearer;
        config.headers["X-Auth-Type"] = "user";
      } else {
        config.headers = { Authorization: bearer, "X-Auth-Type": "user" };
      }
    }

    return config;
  };

  /** Request Interceptors baseURL from General Services
   *  General Services
   *  */
  /* eslint-disable no-param-reassign */
  baseUrl.interceptors.request.use(requestInterceptor);

  /**  Request Interceptors baseURL from Module Services
   * Modules folder
   */
  moduleBaseUrl.interceptors.request.use(requestInterceptor);

  // Response Interceptors baseURL from General Services
  baseUrl.interceptors.response.use(
    function (response) {
      return { ...response, success: true };
    },
    function (error: AxiosError) {
      if (error.response?.status === 401) {
        instance.loginRedirect({ ...loginRequest, state: pathname });
      }
      return Promise.reject(error);
    }
  );

  // Response Interceptors baseURL from Module Services
  moduleBaseUrl.interceptors.response.use(
    function (response) {
      if (response.status === 204 || response.status === 202) {
        return { ...response, data: "Successful update" };
      }
      return response;
    },
    function (error: AxiosError) {
      if (error.response?.status === 401) {
        instance.loginRedirect({ ...loginRequest, state: pathname });
      }

      return Promise.reject(error);
    }
  );

  /* eslint-enable no-param-reassign */

  return <>{children}</>;
};

export default RequestInterceptor;
