import { createSlice } from "@reduxjs/toolkit";

import { postLoanAdjustments } from "./actions/loanAdjustment.thunk";
import * as reducers from "./reducers/loanAdjustment.reducers";
import { TransactionPostingState } from "../../../utils/types";

export const initialState: TransactionPostingState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const TRANSACTIONS_POSTING_KEY = "transactionsPosting";

export const transactionsPostingSlice = createSlice({
  name: TRANSACTIONS_POSTING_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postLoanAdjustments.pending, reducers.pending)
      .addCase(postLoanAdjustments.fulfilled, reducers.fulfilled)
      .addCase(postLoanAdjustments.rejected, reducers.rejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const transactionsPostingReducer = transactionsPostingSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const transactionsPostingSliceActions = transactionsPostingSlice.actions;
