import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getPartnerDetails } from "../../services/generalService";
import { PartnerDetailsObj } from "../../utils/types";

const initialState: PartnerDetailsObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getPartnersData = createAsyncThunk(
  "partners/getPartnerDetails",
  async ({ partnerID }: { partnerID?: string }, thunkAPI) => {
    try {
      const response = await getPartnerDetails(`partner-account/${partnerID}`);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const partnerDetailsSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartnersData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPartnersData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data;
      })
      .addCase(getPartnersData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default partnerDetailsSlice.reducer;
