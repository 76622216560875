import { createSlice } from "@reduxjs/toolkit";

import { BillsPdfState } from "../../utils/types";
import { getBillsPdf } from "../modules/bills-pdf/actions/billsPdf.thunk";

export const initialState: BillsPdfState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};


export const billsPdfSlice = createSlice({
  name: "billsPdf",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillsPdf.pending,(state) => {
        state.isLoading = true;
      })
      .addCase(getBillsPdf.fulfilled,  (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.response;
      })
      .addCase(getBillsPdf.rejected,  (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const billsPdfDetailsSlice = billsPdfSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
