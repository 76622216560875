import { createAsyncThunk } from "@reduxjs/toolkit";

import { BulkDataAPI, Types } from "../../../../services";


export const postBulkData = createAsyncThunk(
    `bulkDataUpdates/bulkDataPost`,
    async (
      {
        loanID,
        payload,
      }: {
        loanID: string;
        payload: Types.BulkDataTypes.PostBulkDataReqBody;
      },
      { rejectWithValue }
    ) => {
      const response = await BulkDataAPI.apiPostBulkUpdate({
        loanID,
        payload,
      });
      if (!response.success) throw rejectWithValue(response);
      return response;
    }
  );


  export const getPreSignedURL = createAsyncThunk(
    `bulkDataUpdates/getPreSignedURL`,
    async (url: string, { rejectWithValue }) => {
      const response = await BulkDataAPI.apiPreSignedURL({
        url,
      });
      if (!response.success) throw rejectWithValue(response);
      return response;
    }
  );