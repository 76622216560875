import { RightPartyContactGetPostResponse, RightPartyContactPostPayload } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiPostRightPartyContact = async ({
  loanID,
  payload,
}: {
  loanID: string;
  payload: RightPartyContactPostPayload;
}): Promise<ApiResponse<RightPartyContactGetPostResponse>> => {
  try {
    const { data, status } = await baseUrl.post<RightPartyContactGetPostResponse>(
      `loans/${loanID}/right-party-contacts`,
      payload
    );
    return { response: data, status, success: status === 201 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

export const apiGetRightPartyContact = async ({
  loanID,
}: {
  loanID: string;
}): Promise<ApiResponse<RightPartyContactGetPostResponse[]>> => {
  try {
    const { data, status } = await baseUrl.get<RightPartyContactGetPostResponse[]>(
      `loans/${loanID}/right-party-contacts`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
