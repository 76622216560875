import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getDevData } from "../../services/generalService";
import { AdjustmentReversalObj } from "../../utils/types";

const initialState: AdjustmentReversalObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getAllAdjustmentReversalData = createAsyncThunk(
  "getAllAdjustmentReversal",
  async (url: string, thunkAPI) => {
    try {
      const data = await getDevData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

const adjustmentReversalSlice = createSlice({
  name: "adjustmentReversal",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdjustmentReversalData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAdjustmentReversalData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getAllAdjustmentReversalData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default adjustmentReversalSlice.reducer;
