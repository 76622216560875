import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getDevData } from "../../services/generalService";
import { ReasonCodeObj } from "../../utils/types";

const initialState: ReasonCodeObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getReasonCodeList = createAsyncThunk(
  "loans/getReasonCodeList",
  async (url: string, thunkAPI) => {
    try {
      const data = await getDevData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const reasonCodeListSlice = createSlice({
  name: "reason-code-list",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReasonCodeList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReasonCodeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getReasonCodeList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default reasonCodeListSlice.reducer;
