import {
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import {
  useMsalAuthentication,
  AuthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import RequestInterceptor from "./auth/RequestInterceptor";
import style from "./auth.module.scss";
import Routers from "./Routers";
import RemoteConfigContainer from "./components/shared/remoteConfig/RemoteConfig.container";
interface Props {}
const App: React.FC<Props> = () => {
  // using useMsalAuthentication hook to automatically sign in user, note: login button not required
  const { login, error } = useMsalAuthentication(InteractionType.Redirect);
  let location = useLocation();
  
  // retrieve progress status of the microsoft login component.
  const { inProgress, instance } = useMsal();

  useEffect(() => {
    // if error while logging re-attempt new login.
    if (error) {
      login(InteractionType.Redirect);
    }
    // if refresh token or access token is expired, re-attempt new login.
    const callbackId = instance.addEventCallback((error: EventMessage) => {
      if (error) {
        if (
          error.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
          error.eventType === EventType.LOGIN_FAILURE
        ) {
          login(InteractionType.Redirect);
        }
      }
    });
    // remove addEventCallback on unmount.
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, error]);

  /**
   * for future use
   * ||inProgress === InteractionStatus.HandleRedirect ||inProgress === InteractionStatus.Startup ||inProgress === InteractionStatus.SsoSilent
   */

  // if inProgress Interactions status of ms auth is login or acquireToken.
  if (
    inProgress === InteractionStatus.Login ||
    inProgress === InteractionStatus.AcquireToken
  ) {
    return (
      <div className={style["auth"]}>
        <FontAwesomeIcon icon={faSpinner} pulse size="6x" />
      </div>
    );
  }

  useEffect(() => {
    reportBugs();
  }, [location]);

  function reportBugs() {
    try {
      let url = window.location.href;
      const curentPage = location?.pathname || "";
      let startIndex = curentPage.indexOf("/loans");
      let textAfterLoans = curentPage.substring(startIndex + "/loans/".length);
      let parts = textAfterLoans.split("/");
      let loanId = parts[0];
      let pageName = parts.slice(1).join("/");
      if (!pageName || pageName === null || pageName === undefined) {
        pageName = "loanDetail";
      }

      let reporter = "";
      //get accessToken
      try {
        const accessToken = sessionStorage.getItem("accessToken");
        let tokenData = parseJwt(accessToken || "");
        if (tokenData) {
          reporter = tokenData?.upn;
        }
      } catch (e) {}
      (window as any).ATL_JQ_PAGE_PROPS = null;

      (window as any).ATL_JQ_PAGE_PROPS = {
        fieldValues: {
          priority: "3",
          environment: "Location : " + url,
          customfield_11351: loanId,
          customfield_11352: pageName,
          customfield_11353: reporter,
        },
      };

      let collector = document.createElement("script");
      collector.type = "text/javascript";
      collector.async = true;
      collector.src =
        "https://lpsupport.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=e62f8122";
      let head = document.getElementsByTagName("head")[0];
      head.appendChild(collector);
    } catch (e: unknown) {}
  }
  function parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  
  return (
    <>
        <AuthenticatedTemplate>
          <RequestInterceptor>
           <RemoteConfigContainer>
            <Routers />
           </RemoteConfigContainer>
          </RequestInterceptor>
        </AuthenticatedTemplate>
    </>
  );
};
export default App;
