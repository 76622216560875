import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { LoandDetailsState } from "../../../../utils/types";

export const pending = (state: LoandDetailsState) => {
  state.isLoading = true;
  state.data = null;
  state.isError = false;
  state.isSuccess = false;
  state.errorStatus = null;
};

export const fulfilled = (
  state: LoandDetailsState,
  action: PayloadAction<
    Types.ApiResponse<Types.LoanDetailsTypes.LoanDetailsResponse>
  >
) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = action.payload.success;
  state.data = action.payload.response;
  state.errorStatus = null;
};

export const rejected = (state: LoandDetailsState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
  state.errorStatus = action.payload;
};

// updated prime rate
export const updatedPrimeRatePending = (
  state: LoandDetailsState
) => {
  state.updatedPrimeRate.loadingStatus = "loading";
  state.updatedPrimeRate.error = undefined;
  state.updatedPrimeRate.data = undefined;
};

export const updatedPrimeRateFulfilled = (
  state: LoandDetailsState,
  action: PayloadAction<
    Types.ApiResponse<
      number[]
    >
  >
) => {
  state.updatedPrimeRate.loadingStatus = "loaded";
  state.updatedPrimeRate.data = action.payload;
};

export const updatedPrimeRateRejected = (
  state: LoandDetailsState,
  action: any
) => {
  state.updatedPrimeRate.loadingStatus = "error";
  state.updatedPrimeRate.error = action.payload || action.error;
};

// patch loan details status
export const patchLoanDetailsStatusPending = (state: LoandDetailsState) => {
  state.patchLoanDetailsStatus = "loading";
};

export const patchLoanDetailsStatusFulfilled = (
  state: LoandDetailsState,
  action: PayloadAction<Types.ApiResponse>
) => {
  state.patchLoanDetailsStatus = "loaded";
};

export const patchLoanDetailsStatusRejected = (state: LoandDetailsState) => {
  state.patchLoanDetailsStatus = "error";
};

// patch loan details SCRA isSCRAApplicable
export const patchLoanDetailsisSCRAApplicablePending = (
  state: LoandDetailsState
) => {
  state.patchLoanDetailsisSCRAApplicable = "loading";
};

export const patchLoanDetailsisSCRAApplicableFulfilled = (
  state: LoandDetailsState,
  action: PayloadAction<Types.ApiResponse>
) => {
  state.patchLoanDetailsisSCRAApplicable = "loaded";
};

export const patchLoanDetailsisSCRAApplicableRejected = (
  state: LoandDetailsState
) => {
  state.patchLoanDetailsisSCRAApplicable = "error";
};
