import { PayloadAction } from "@reduxjs/toolkit";

import { Types } from "../../../../services";
import { CommunicationActivityState } from "../../../../utils/types";

// Get Communication Activity details Reducer
export const getCommunicationActivityPending = (
  state: CommunicationActivityState
) => {
  state.getDetails.loadingStatus = "loading";
  state.getDetails.error = undefined;
  state.getDetails.data = undefined;
};

export const getCommunicationActivityFullfilled = (
  state: CommunicationActivityState,
  action: PayloadAction<
    Types.ApiResponse<
      Types.CommunicationsActivityTypes.GetCommunicationActivity[]
    >
  >
) => {
  state.getDetails.loadingStatus = "loaded";
  state.getDetails.data = action.payload;
};

export const getCommunicationActivityRejected = (
  state: CommunicationActivityState,
  action: any
) => {
  state.getDetails.loadingStatus = "error";
  state.getDetails.error = action.payload || action.error;
};

// Post Communication Notes details Reducer
export const postCommunicationNotesPending = (
  state: CommunicationActivityState
) => {
  state.postNoteDetails.loadingStatus = "loading";
  state.postNoteDetails.error = undefined;
  state.postNoteDetails.data = undefined;
};

export const postCommunicationNotesFullfilled = (
  state: CommunicationActivityState,
  action: PayloadAction<Types.ApiResponse<{ id: string }>>
) => {
  state.postNoteDetails.loadingStatus = "loaded";
  state.postNoteDetails.data = action.payload;
};

export const postCommunicationNotesRejected = (
  state: CommunicationActivityState,
  action: any
) => {
  state.postNoteDetails.loadingStatus = "error";
  state.postNoteDetails.error = action.payload || action.error;
};

// Get Communication Notes details by Id Reducer
export const getCommunicationNoteByIdPending = (
  state: CommunicationActivityState
) => {
  state.getNoteDetailsById.loadingStatus = "loading";
  state.getNoteDetailsById.error = undefined;
  state.getNoteDetailsById.data = undefined;
};

export const getCommunicationNoteByIdFullfilled = (
  state: CommunicationActivityState,
  action: PayloadAction<
    Types.ApiResponse<Types.CommunicationsActivityTypes.GetNoteById>
  >
) => {
  state.getNoteDetailsById.loadingStatus = "loaded";
  state.getNoteDetailsById.data = action.payload;
};

export const getCommunicationNoteByIdRejected = (
  state: CommunicationActivityState,
  action: any
) => {
  state.getNoteDetailsById.loadingStatus = "error";
  state.getNoteDetailsById.error = action.payload || action.error;
};

// Patch Communication Notes details Reducer
export const patchCommunicationNotesPending = (
  state: CommunicationActivityState
) => {
  state.patchNoteDetails.loadingStatus = "loading";
  state.patchNoteDetails.error = undefined;
  state.patchNoteDetails.data = undefined;
};

export const patchCommunicationNotesFullfilled = (
  state: CommunicationActivityState,
  action: PayloadAction<Types.ApiResponse>
) => {
  state.patchNoteDetails.loadingStatus = "loaded";
};

export const patchCommunicationNotesRejected = (
  state: CommunicationActivityState,
  action: any
) => {
  state.patchNoteDetails.loadingStatus = "error";
  state.patchNoteDetails.error = action.payload || action.error;
};

// Delete Communication Notes details Reducer

export const deleteCommunicationNotesPending = (
  state: CommunicationActivityState
) => {
  state.deleteNoteDetails.loadingStatus = "loading";
  state.deleteNoteDetails.error = undefined;
  state.deleteNoteDetails.data = undefined;
};

export const deleteCommunicationNotesFullfilled = (
  state: CommunicationActivityState,
  action: PayloadAction<Types.ApiResponse>
) => {
  state.deleteNoteDetails.loadingStatus = "loaded";
};

export const deleteCommunicationNotesRejected = (
  state: CommunicationActivityState,
  action: any
) => {
  state.deleteNoteDetails.loadingStatus = "error";
  state.deleteNoteDetails.error = action.payload || action.error;
};
