import { createAsyncThunk } from "@reduxjs/toolkit";

import { BillsTransactionsAPI, Types } from "../../../../services";

export const postBillsTransaction = createAsyncThunk(
  `billsTransactions/postBills`,
  async (
    {
      loanID,
      payload,
    }: {
      loanID: string;
      payload: Types.BillsPostingTypes.BillsPostPayload;
    },
    { rejectWithValue }
  ) => {
    const response = await BillsTransactionsAPI.apiPostBills({
      loanID,
      payload,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);

export const getBillsTransaction = createAsyncThunk(
  `billsTransactions/getBills`,
  async (
    {
      loanID,
      isLateChargeApplied,
    }: {
      loanID: string;
      isLateChargeApplied: boolean | string;
    },
    { rejectWithValue }
  ) => {
    const response = await BillsTransactionsAPI.apiGetBills({
      loanID,
      isLateChargeApplied,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
