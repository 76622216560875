import { createSlice } from "@reduxjs/toolkit";

import {getsearchResults } from "./actions/searchLoans.thunk";
import * as reducers from "./reducers/searchLoans.reducers";
import { SearchResultsState } from "../../../utils/types";

export const initialState: SearchResultsState = {
getSearchResults:{
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
}
};

export const SEARCH_RESULTS_KEY = "searchResults";

export const searchResultsSlice = createSlice({
  name: SEARCH_RESULTS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getsearchResults.pending, reducers.getSearchResultsPending)
      .addCase(getsearchResults.fulfilled, reducers.getSearchResultsFulfilled)
      .addCase(getsearchResults.rejected, reducers.getSearchResultsRejected)
  },
});

/*
 * Export reducer for store configuration.
 */
export const searchResultsReducer = searchResultsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const searchResultsSliceActions = searchResultsSlice.actions;
