import { UserRolesResponse } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetUserRoles = async (): Promise<
  ApiResponse<UserRolesResponse>
> => {
  try {
    const { data, status } = await baseUrl.get(`features/`);
    if (status === 200 && data.length < 1) {
      return { response: data, status, success: true };
    } else {
      return { response: data, status, success: !!Object.keys(data).length };
    }
  } catch (error) {
    return handleSubModuleError(error);
  }
};
