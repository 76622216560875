import { createAsyncThunk } from "@reduxjs/toolkit";

import { PromoDetailsAPI } from "../../../../services";

//Patch/Update Promo Details
export const patchPromoDetails = createAsyncThunk(
  `promoDetails/patch`,
  async (
    {
      loanID,
    }: {
      loanID: string;
    },
    { rejectWithValue }
  ) => {
    const response = await PromoDetailsAPI.apiPatchPromo({
      loanID,
    });
    if (!response.success) throw rejectWithValue(response);
    return response;
  }
);
