import { createSlice } from "@reduxjs/toolkit";

import {
  getUCCLiens,
} from "./actions/UCCLiens.thunk";
import * as reducers from "./reducers/UCCLiens.reducers";
import { UCCLiensState } from "../../../utils/types";

const initialState: UCCLiensState = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const UCC_LIENS_KEY = "uccLiens";

const uccLiensSlice = createSlice({
  name: UCC_LIENS_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUCCLiens.pending, reducers.pending)
      .addCase(getUCCLiens.fulfilled, reducers.fulfilled)
      .addCase(getUCCLiens.rejected, reducers.rejected)
  },
});

/*
 * Export reducer for store configuration.
 */
export const uccLiensReducer = uccLiensSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const uccLiensSliceActions = uccLiensSlice.actions;
