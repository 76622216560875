import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getconfigData } from "../../../../services/generalService";
import { ProductConfigObj } from "../../../../utils/types";

const initialState: ProductConfigObj = {
  data: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
};

export const getProductConfigData = createAsyncThunk(
  "config/getProductConfigDataDetails",
  async (url: string, thunkAPI) => {
    try {
      const data = await getconfigData(url);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const productConfigSlice = createSlice({
  name: "productConfig",
  initialState,
  reducers: {
    // synchronous aperations goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductConfigData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductConfigData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getProductConfigData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.data = null;
      });
  },
});

export default productConfigSlice.reducer;
