import { createSlice } from "@reduxjs/toolkit";

import { getBillsPdf } from "./actions/billsPdf.thunk";
import * as reducers from "./reducers/billsPdf.reducers";
import { BillsPdfState } from "../../../utils/types";

export const initialState: BillsPdfState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const BILLS_PDF_KEY = "billsPdf";

export const billsPdfSlice = createSlice({
  name: BILLS_PDF_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBillsPdf.pending, reducers.pending)
      .addCase(getBillsPdf.fulfilled, reducers.fulfilled)
      .addCase(getBillsPdf.rejected, reducers.rejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const billsPdfReducer = billsPdfSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const billsPdfSliceActions = billsPdfSlice.actions;
