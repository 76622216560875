import React, { FC } from "react";

import AppLoading from "../loader/AppLoading";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  selectIsRemoteConfigLoading,
  selectRemoteConfigStatus,
} from "../../../redux/reducers/remote-config/selectors/remoteConfig.selector";
import { getRemoteConfig } from "../../../redux/reducers/remote-config/actions/thunks";
import style from "../../../auth.module.scss";

interface RemoteConfigContainerProps {
  children: JSX.Element;
}

const RemoteConfigContainer: FC<RemoteConfigContainerProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const isRemoteConfigLoading = useAppSelector(selectIsRemoteConfigLoading);
  const remoteConfigStatus = useAppSelector(selectRemoteConfigStatus);

  React.useEffect(() => {
    const fetchRemoteConfig = async () => {
      try {
        await dispatch(getRemoteConfig());
      } catch (error: any) {
        if (error.name !== "ConditionError") {
          console.error(error);
        }
      }
    };
    if (!remoteConfigStatus) {
      fetchRemoteConfig();
    }
  }, [remoteConfigStatus]);

  if (isRemoteConfigLoading)
    return (
      <div className={style["auth-loader"]}>
        <AppLoading />
      </div>
    );
  return <React.Fragment>{children}</React.Fragment>;
};

export default RemoteConfigContainer;
