import { createSlice } from "@reduxjs/toolkit";

import { getLoanFeesTypes } from "./actions/loanFeesTypes.thunk";
import * as reducers from "./reducers/loanFeesTypes.reducers";
import { LoanFeesTypesState } from "../../../utils/types";

export const initialState: LoanFeesTypesState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const LOAN_FEES_TYPES_KEY = "loanFeesTypes";

export const loanFeesTypesSlice = createSlice({
  name: LOAN_FEES_TYPES_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoanFeesTypes.pending, reducers.pending)
      .addCase(getLoanFeesTypes.fulfilled, reducers.fulfilled)
      .addCase(getLoanFeesTypes.rejected, reducers.rejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const loanFeesTypesReducer = loanFeesTypesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const loanFeesTypesSliceActions = loanFeesTypesSlice.actions;
