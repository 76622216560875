import { LoanDetailsResponse } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetLoanDetails = async ({
  externalID,
}: {
  externalID: string;
}): Promise<ApiResponse<LoanDetailsResponse>> => {
  try {
    const { data, status } = await baseUrl.get<LoanDetailsResponse>(
      `loans/${externalID}`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// get updated prime rate
export const apiGetLatestPrimeRate = async ({ url }: {url: string;}): Promise<ApiResponse<any>> => {
  try {
    const data = await baseUrl.get(url);
    return { response: data.data, status: 200, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Patch Loan details
export const apiPatchLoanDetailsStatus = async ({
  externalID,
  status,
  statusCategory,
}: {
  externalID: string;
  status: string;
  statusCategory: string;
}): Promise<ApiResponse> => {
  try {
    const { status: responseStatus } = await baseUrl.patch(
      `loans/${externalID}`,
      {
        status,
        statusCategory,
      }
    );
    return { status: responseStatus, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Patch Loan details
export const apiPatchLoanDetailsScrub = async ({
  scrubResult,
  lastScrubDate,
  isPendingRecall,
  recallDate,
  loanID,
}: {
  scrubResult?: string | null;
  lastScrubDate: Date | null | string;
  isPendingRecall?: boolean | string;
  recallDate: Date | null | string;
  loanID: string
}): Promise<ApiResponse> => {
  try {
    const { status: responseStatus } = await baseUrl.patch(
      `loans/${loanID}`,
      {
        scrubDetails: {
          scrubResult,
          lastScrubDate,
          isPendingRecall,
          recallDate, 
        }
      }
    );
    return { status: responseStatus, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

// Patch Loan details isScra
export const apiPatchLoanDetailsIsSCRAApplicable = async ({
  externalID,
  isSCRAApplicable,
}: {
  externalID: string;
  isSCRAApplicable: boolean;
}): Promise<ApiResponse> => {
  try {
    const { status: responseStatus } = await baseUrl.patch(
      `loans/${externalID}`,
      {
        isSCRAApplicable,
      }
    );
    return { status: responseStatus, success: true };
  } catch (error) {
    return handleSubModuleError(error);
  }
};

