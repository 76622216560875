import { createSlice } from "@reduxjs/toolkit";

import {
  getRightPartyContact,
  postRightPartyContact
} from "./actions/rightParty.thunk";
import * as reducers from "./reducers/rightParty.reducers";
import { RightPartyContactState } from "../../../utils/types";

export const initialState: RightPartyContactState = {
  rightPartyContactGetResponse: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
  rightPartyContactPostResponse: {
    loadingStatus: "not loaded",
    data: undefined,
    error: undefined,
  },
};

export const RIGHT_PARTY__CONTACT_KEY = "rightPartyContact";

export const rightPartyContactSlice = createSlice({
  name: RIGHT_PARTY__CONTACT_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRightPartyContact.pending, reducers.rightPartyGetPending)
      .addCase(getRightPartyContact.fulfilled, reducers.rightPartyGetFulfilled)
      .addCase(getRightPartyContact.rejected, reducers.rightPartyGetRejected)
      .addCase(postRightPartyContact.pending, reducers.rightPartyPostPending)
      .addCase(postRightPartyContact.fulfilled, reducers.rightPartyPostFulfilled)
      .addCase(postRightPartyContact.rejected, reducers.rightPartyPostRejected);
  },
});

/*
 * Export reducer for store configuration.
 */
export const rightPartyContactReducer = rightPartyContactSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 */
export const rightPartyContactSliceActions = rightPartyContactSlice.actions;
