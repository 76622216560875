import { createAsyncThunk } from "@reduxjs/toolkit";

import { RecoveryScheduleAPI } from "../../../../services";


export const getRecoverySchedule = createAsyncThunk(
    `recoverySchedule/recoveryScheduleGet`,
    async (
      {
        loanID,
      }: {
        loanID: string;
      },
      { rejectWithValue }
    ) => {
      const response = await RecoveryScheduleAPI.apiGetRecoverySchedule({
        loanID,
      });
      if (!response.success) throw rejectWithValue(response);
      return response;
    }
  );
  