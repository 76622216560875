import { GetLoanFeesTypesResponse } from "./types";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools/apiError";
import { ApiResponse } from "../../tools/types";

export const apiGetLoanFeesTypes = async (): Promise<
  ApiResponse<GetLoanFeesTypesResponse[]>
> => {
  try {
    const { data, status } = await baseUrl.get<GetLoanFeesTypesResponse[]>(
      `loans/fees`
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
