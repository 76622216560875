import {
  PostBulkDataReqBody,
  PostBulkDataResponse,
  PresignedResponse,
} from "./type";
import { baseUrl } from "../../base-api";
import { handleSubModuleError } from "../../tools";
import { ApiResponse } from "../../tools/types";

export const apiPostBulkUpdate = async ({
  loanID,
  payload,
}: {
  loanID: string;
  payload: PostBulkDataReqBody;
}): Promise<ApiResponse<PostBulkDataResponse>> => {
  try {
    const { data, status } = await baseUrl.post<PostBulkDataResponse>(
      `loans/finance-bulk-import`,
      payload
    );
    return { response: data, status, success: status === 200 };
  } catch (error) {
    return handleSubModuleError(error);
  }
};


export const apiPreSignedURL = async ({
  url,
}: {
  url: string;
}): Promise<ApiResponse<PresignedResponse>> => {
  try {
    const { data, status } = await baseUrl.get<PresignedResponse>(
      url
    );
    return { response: data, status, success: !!Object.keys(data).length };
  } catch (error) {
    return handleSubModuleError(error);
  }
};
